﻿//BOOKING-PANEL

$header-height: 72px;
$mobile-header-height: 60px;

.c-booking-panel {
	background-color: $white;
	border: 0px none;
	box-shadow: 0px 0px 4px rgba($black, 0.08);

	.sticky-sidebar & {
		position: fixed;
		top: calc(100% - 60px);
		z-index: 5;
		left: 0;
		width: 100%;

		&[aria-expanded="true"] {
			top: $mobile-header-height;
			height: calc(100% - $mobile-header-height);
		}

		@include bp("large") {
			position: sticky;
			top: $header-height;
			z-index: 1;
		}
	}

	@include bp("large") {
		border: 1px solid #eee;
	}
}

.c-booking-panel-item-header {
	background-color: $yellow;
	padding: $bsu (($bsu * 1.5) + $tsu);
}

.c-booking-panel-item-arrow-icon {
	color: $secondary-a;
	color: var(--secondary-a);
}

.c-booking-panel-item-heading {
	@include font-base-14b;
	color: $secondary-a;
	color: var(--secondary-a);
	margin-bottom: 0;
	position: relative;
	padding-right: ($bsu * 1.5);
}

.c-booking-panel-item-arrow-icon {
	font-size: 22px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%) rotate(180deg);

	.c-booking-panel[aria-expanded="true"] & {
		transform: translateY(-50%) rotate(0);
	}

	@include bp("large") {
		display: none;
	}
}

.c-booking-panel-items-wrap {
	overflow-y: scroll;
	height: calc(100% - 60px);
	@include thin-scrollbar;

	@include bp("large") {
		overflow: auto;
	}
}

.c-booking-panel-body {
	padding: ($ssu * 1.5);
}

.c-booking-panel__heading {
	@include font-base-18b;
	color: $secondary-a;
	color: var(--secondary-a);
	margin-bottom: $ssu;
}

.c-booking-panel__location {
	@include font-base-12;
}

.c-booking-panel-details {
	@include font-base-14b;
}

.c-booking-panel-detail__person {
	margin-bottom: $tsu;
}

.c-booking-panel-detail__date {
	margin-bottom: 0;
}

.c-booking-panel-detail__night {
	@include font-base-12;
}

.c-booking-panel-detail__edit {
	@include button-reset;
	color: $primary-a;
	color: var(--primary-a);
	margin-left: 10px;
}

.c-booking-panel-offer-header {
	background-color: $blue--light;
	display: flex;
	flex-direction: row;
	padding: $tsu ($ssu * 1.5);
}

.c-booking-panel-offer-heading {
	@include font-base-16b;
	color: $white;
	margin: 0;

	&.left-col {
		width: 60%;
	}

	&.right-col {
		width: 40%;
		text-align: right;
	}
}

.c-booking-panel-offer__item-wrapper {
	&:nth-child(odd) {
		background-color: $grey--lightest;
	}
}

.c-booking-panel-offer__item {
	@include flex-extend;
	padding: ($bsu + $tsu) $bsu;
	flex-direction: row;
	flex-wrap: wrap;
	gap: $ssu 0;

	.error {
		color: $red;
		width: 100%;
	}
}

.c-booking-panel-offer__item-info-left {
	@include font-base-14;
	width: 60%;

	@include bp("medium") {
		@include font-base-16;
	}
}

.c-booking-panel-offer__item-info-right {
	@include font-base-14;
	width: 40%;
	text-align: right;
}

.c-booking-panel-offer__item-price {
	@include font-base-16b;
	margin-bottom: 0;
}

.c-booking-panel-offer__item-deal {
	@include font-base-12;
	color: $primary-a;
	color: var(--primary-a);
	margin-bottom: 0;
}

.c-booking-panel-offer__item-strapline {
	@include font-base-14;
	color: $orange;
	margin-bottom: 15px;
}

.c-booking-panel-offer__add-to-cart {
	@include button("small");
	@include btn-primary;
}

.btn__add-to-cart {
	@include button("small");
	@include btn-primary;
}
