// COMPONENT.HEADER

@use "sass:math";

$header-bp: "large";

$header-height: 72px;

:root {
	--header-height: #{$header-height};
}

.c-header {
	position: absolute;
	z-index: $z-header;
	height: ($header-height - 12px);
	width: 100%;
	max-width: $viewport-max-width;
	background-color: $dark-blue;
	box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	transition: all 0.25s ease-in-out;

	@include bp($header-bp) {
		height: $header-height;
		height: var(--header-height);
	}

	.c-content-type-homepage & {
		background: none;
		box-shadow: none;
	}

	&.megamenu-is-open {
		background-color: $dark-blue--dark;
	}

	&.is-sticky {
		background-color: $dark-blue;
		position: fixed;
		top: 0;
		/*		~ .c-member-navigation {
			position: fixed;
			top: $header-height;
			left: 0;
			right: 0;
			z-index: 1;
		}*/

		&.is-up {
			transform: none;

			&.megamenu-is-open {
				transform: none;
			}

			@include bp($header-bp) {
				transform: translateY(calc(-100% - 6px));
			}
			/*
			~ .c-member-navigation {
				position: relative;
				height: 0;
				top: 0;
				padding: 0;
			}*/
		}
	}
}

.c-header__spacer {
	height: 60px;

	@include bp('large') {
		height: 72px;
	}

	.c-content-type-homepage & {
		height: 0px;
	}
}

.c-header__wrapper {
	@include flex-extend;
	align-items: center;
	justify-content: space-between;
}

.c-header__logo {
	display: inline-block;
	vertical-align: top;
	padding: $bsu 5px;
	text-align: center;
	height: ($header-height - 12px);
	margin-left: -5px;

	@include bp(375px) {
		padding: $ssu + 5px;
	}

	@include bp($header-bp) {
		height: $header-height;
		height: var(--header-height);
		padding: 18px 20px;
	}

	img {
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}

	&:focus {
		@include focus-outline;
	}
}

.c-header__tools {
	@include flex-extend;
	align-items: center;
	justify-content: flex-end;
	order: 3;
	padding-right: $ssu;

	@include bp("medium") {
		padding-right: $bsu;
	}
}

// Secondary Nav
.c-header__secondary-nav {
	@include bp-below($header-bp) {
		display: none;
	}

	@include bp($header-bp) {
		@include list-reset;
		display: flex;
		margin-left: -$bsu;
		margin-right: $bsu;
		margin-top: 6px;
	}

	@include bp("extra-large") {
		margin-top: 10px;
	}
}

.c-header__secondary-nav-item {
	padding-left: $bsu;
}

.c-header__secondary-nav-link {
	@include font-base-12b;
	display: block;
	padding: $ssu 0;
}

// Language
.c-header__language {
	display: none;

	@include bp("medium") {
		display: block;
		padding-right: $bsu;
	}
}

.c-header__language-dropdown {
	@include font-base-16;
	display: block;
	padding: 0.65em;
	border: 1px solid $grey;
}


// Mobile buttons
$mobile-button-size: 60px;
$mobile-button-color: $brand;
$mobile-button--hover-color: darken($brand, 10);
$mobile-button-font-size: 30px;
$mobile-button-padding: 8px;

.c-header__nav-toggle {
	@include button-reset;
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: 0;
	background: $dark-blue;
	transition: all 0.3s ease-in-out;
	cursor: pointer;

	@include bp($header-bp) {
		width: $mobile-button-size + 12;
		height: $mobile-button-size + 12;
	}

	b {
		transition: all 0.3s ease-in-out;
		position: absolute;
		left: 50%;
		top: 30%;
		transform: translate(-50%, -50%);
		display: block;
		width: $mobile-button-font-size;
		height: 3px;
		background: $white;

		&:nth-child(1) {
			margin-top: 0;
		}

		&:nth-child(2) {
			margin-top: 8px;
		}

		&:nth-child(3) {
			margin-top: 16px;
		}
	}

	&[aria-expanded="true"] {
		background: $dark-blue--dark;

		b {
			&:nth-child(1) {
				transform: matrix(-0.86, -0.5, -0.5, 0.86, -15, 0);
				margin-top: 6px;
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: matrix(-0.88, 0.47, 0.47, 0.88, -15, 0);
				margin-top: 6px;
			}
		}
	}

	&:hover,
	&[aria-expanded="true"] {
		b {
			border-color: $mobile-button--hover-color;
		}
	}

	.c-header__mobile-descriptor {
		font-weight: 800;
		font-size: 8px;
		line-height: 16px;
		text-align: center;
		letter-spacing: 0.04em;
		color: $white;
		margin-top: 30px;
	}
}

.c-header__mobile-buttons {
	display: flex;
	flex: 0 0 auto;
	align-items: center;
}

.c-header__nav-button {
	@include button-reset;
	transition: background 0.3s;
	height: 35px;
	width: 35px;
	font-size: 20px;
	color: $white;
	border-radius: 50%;
	margin-right: 5px;
	border: 5px solid transparent;

	@include bp("medium") {
		height: 45px;
		width: 45px;
	}

	.c-header__member-loggedin {
		display: block;
		color: $yellow;
		height: 25px;
		width: 25px;
		padding: 4px;
		border-radius: 50%;
		font-size: 14px;
		background: $blue--light;

		@include bp("medium") {
			font-size: 16px;
			height: 35px;
			width: 35px;
			padding: 7px;
		}
	}

	&:hover {
		background: $dark-blue--dark;
	}

	&[aria-expanded="true"] {
		border: 5px solid $dark-blue--dark;
		background: $blue--light;
		color: $yellow;

		@include bp("medium") {
			border: 5px solid $dark-blue--dark;
		}
	}
}

.c-header__cart {
	position: relative;

	.c-header__cart-items {
		position: absolute;
		height: 16px;
		width: 16px;
		right: -5px;
		top: -5px;
		color: $white;
		background: $orange;
		border-radius: 50%;
		font-size: 10px;
		padding: 2px;

		@include bp("medium") {
			right: 0;
			top: 0;
		}
	}

	&[aria-expanded="true"] {
		.c-header__cart-items {
			right: -5px;
			top: -5px;
		}
	}
}

.c-header__chinese {
	display: none;

	@include bp("medium") {
		display: inline-block;

		span {
			margin: 7px;
			display: block;
		}
	}
}

.c-header__book-now {
	@include button("small");
	@include btn-primary;
	display: none;

	@include bp("small") {
		display: block;
	}

	&.c-header__mobile-button {
		display: block;
		position: fixed;
		bottom: 10px;
		left: 10px;
		right: 10px;
		z-index: 5;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.5s linear, visibility 0s linear 0.5s;

		&.fade-in {
			opacity: 1;
			visibility: visible;
			transition-delay: 0s;
		}

		@include bp("small") {
			display: none;
		}
	}
}

.c-member-page .c-header__book-now {
	display: none;
}

.c-header__log-in {
	@include bp-below($header-bp) {
		display: none;
	}
}
