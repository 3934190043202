// CONTROL.LISTING-VERBOSE

@use "sass:math";

$listing-verbose-bp: "medium";

.c-listing-verbose {
	@include list-reset;

	margin-top: -$bsu;
}

.c-listing-verbose__item {
	width: 100%;
	padding-top: $bsu;
}

.c-listing-verbose__item-link {
	@include ui-border("all");
	@include link-transition(border-color);
	@include radius;

	display: flex;
	align-items: stretch;
	justify-content: space-between;
	@include bp-below("small") {
		flex-direction: column;
	}
	@include bp("small") {
		flex-direction: row;
	}
}

.c-listing-verbose__item-content {
	display: flex;

	@include bp-below("small") {
		padding: $ssu $bsu $bsu;
	}
	@include bp-below($listing-verbose-bp) {
		flex-direction: column;
		flex-grow: 1;

		@include bp("small") {
			padding: $ssu $bsu;
		}
	}
	@include bp($listing-verbose-bp) {
		flex-direction: row;

		width: percentage(math.div(2, 3));
		flex-grow: 0;
		flex-shrink: 0;

		padding: $bsu $bsu;
	}
}

.c-listing-verbose__item-head {
	@include bp($listing-verbose-bp) {
		width: percentage(math.div(1, 2));
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.c-listing-verbose__item-heading {
	@include bp-below($listing-verbose-bp) {
		margin-bottom: $ssu;
	}
	@include bp($listing-verbose-bp) {
		margin-right: $lsu;
	}

	@include font-heading-20;
	color: $body-color;
}

.c-listing-verbose__item-body {
	@include bp($listing-verbose-bp) {
		width: percentage(math.div(1, 2));
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.c-listing-verbose__item-text {
	@include font-body-text;
	color: $body-color;

	margin-bottom: 0;
}

.c-listing-verbose__item-figure {
	@include figure;

	flex-grow: 0;
	flex-shrink: 0;
	@include bp("small") {
		@include bp-below($listing-verbose-bp) {
			width: 40%;
			max-width: 220px;
		}
	}
	@include bp($listing-verbose-bp) {
		width: percentage(math.div(1, 3));
	}

	overflow: hidden;
}

.c-listing-verbose__item-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;

	.c-listing-verbose__item-link:hover &,
	.c-listing-verbose__item-link:focus-visible & {
		transform: scale(1.1);
	}
	@include link-transition(transform);
}
