﻿// CONTROL.IMAGE-CTA

@use "sass:math";

$image-cta-bp: "large";

.c-image-cta {
	@include control;
}

.c-image-cta__layout {
	@include layout-wrapper;

}

.c-image-cta__wrapper {
	position: relative;
	z-index: 1;
}

.c-image-cta__viewport {
	position: relative;
}

.c-image-cta__item {
	width: 100%;
	position: relative;
	z-index: 1;

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		background: linear-gradient(90deg, rgba($dark-blue--light, 0.65) 30.56%, rgba($black, 0) 70.69%);
	}
}

.c-image-cta__figure {
	@include figure;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.c-image-cta__figure--secondary {
	margin-top: -#{$lsu};
	margin-bottom: $lsu;
	max-width: 200px;
	width: 100%;

	@include bp('medium') {
		max-width: 230px;
	}

	.o-fit__image {
		object-fit: contain;
	}
}

.c-image-cta__image {
	padding-bottom: 0 !important;
	height: 100%;
}

.c-image-cta__image--mobile {
	@include bp($image-cta-bp) {
		display: none;
	}
}

.c-image-cta__image--desktop {
	@include bp-below($image-cta-bp) {
		display: none;
	}
}

.c-image-cta__body {
	position: relative;
	z-index: 2;
	color: $white;
	min-height: 480px;
	display: flex;
}

.c-image-cta__body-wrap {
	@include layout-wrapper;
}

.c-image-cta__body-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 120px 0 $bsu 0;
	justify-content: center;

	&.has-image {
		padding-top: $bsu;
	}

	@include bp("medium") {
		width: 70%;
		align-items: flex-start;
		text-align: left;
	}

	@include bp("large") {
		width: 50%;
	}

	.c-image-cta__heading {
		@include font-heading-32;
		margin-bottom: $ssu;
		text-shadow: 0 0 5px $black;

		@include bp("small") {
			margin-bottom: $bsu;
		}

		@include bp("medium") {
			@include font-heading-40;
		}
	}
}

.c-image-cta__text {
	text-shadow: 0 0 5px $black;
}

.c-image-cta__abstract {
	@include font-base-14;
	margin-top: auto;
	text-shadow: 0 0 5px $black;

	a {
		color: $yellow;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

.c-image-cta__btn {
	@include button;
	@include btn-primary;
	margin-top: $bsu;
	margin-bottom: $bsu;

	@include bp("medium") {
		margin-top: $lsu;
		margin-bottom: $lsu;
	}
}
