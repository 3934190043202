// CONTROL.FACTS-FIGURES

@use "sass:math";

$facts-figures-gap: $bsu;

.c-facts-figures {
	padding: $lsu 0;
	background-color: $brand--alt;

	@include bp("large") {
		padding: ($lsu * 1.5) 0;
	}
}

.c-facts-figures__layout {
	@include layout-wrapper;

	.l-page--has-sidebar .l-page__main-controls & {
		@include bp($layout-page-bp) {
			padding: 0 $lsu;
		}
	}
}

.c-facts-figures__heading {
	@include font-heading-24;
	margin-bottom: $lsu;
	color: $white;

	@include bp('medium') {
		@include font-heading-32;
	}
}

.c-facts-figures__item-list {
	@include list-reset;
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: $lsu;
	grid-row-gap: $lsu;

	@include bp('small') {
		grid-template-columns: 1fr 1fr;
	}

	@include bp('medium') {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.c-facts-figures__item {
	@include link-transition("opacity, transform", 0.6s);
	display: flex;
	align-items: stretch;

	@include scroll-appear {
		opacity: 0;
		transform: translateY(100px);
	}
}

.c-facts-figures__pod {
	display: flex;
	width: 100%;
	flex-direction: column;

	@include bp("large") {
	}

	@include link-transition("color, background-color");
}

.c-facts-figures__item-title {
	@include font-heading-40;
	display: block;
	margin-bottom: 0.25em;
	color: $yellow;

	@include bp('medium') {
		@include font-heading-48;
	}

	@include bp('large') {
		@include font-heading-72;
	}
}

.c-facts-figures__item-text {
	@include font-base-16;
	display: block;
	color: $white;

	@include bp('medium') {
		@include font-base-18;
	}
}

.c-facts-figures__item-link {
	@include font-body-text;
	@include font-base-14;
	@include link-transition;
	display: block;
	margin-top: $bsu;
	color: $yellow;

	.c-facts-figures__pod-link:hover &,
	.c-facts-figures__pod-link:focus & {
		transform: translateY(-#{$ssu});
	}
}
