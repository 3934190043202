// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: $white;
$navigation-bp: $header-bp;

.c-navigation {
	display: none;
	width: 100%;
	background-color: $navigation-bg;

	@include bp("extra-large") {
		display: block;
		flex: 1;
		display: flex;
		justify-content: center;
		width: auto;
		background-color: transparent;
	}

	&[aria-expanded="true"] {
		display: block;
		position: absolute;
		top: $header-height;
		top: var(--header-height);
		left: 0;
		height: calc(100vh - #{$header-height});
		height: calc(100vh - var(--header-height));
		overflow: auto;
	}
}

.c-navigation__items {
	@include list-reset;
	padding-top: $tsu;

	@include bp($navigation-bp) {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: stretch;
		padding: 0;
	}
}

.c-navigation__item {
	@include ui-border('bottom');

	@include bp($navigation-bp) {
		position: relative;
		border: 0;
		text-align: center;
		vertical-align: bottom;
		display: flex;
		align-items: stretch;
	}

	&.c-navigation__item--home {
		display: none;
	}
}

.c-navigation__item--secondary {
	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__link {
	@include font-base-16b;
	display: block;
	padding: $ssu 0;
	margin: 0 $hsu;
	color: $white;
	position: relative;
	@include underline-animate;

	&:after {
		bottom: 10px;
		height: 2px;
		background-color: $yellow;
	}

	@include bp($navigation-bp) {
		display: inline-block;
		padding: $bsu 0;
		margin: 0 $bsu;
		white-space: nowrap;

		&:hover {
			&:after {
				bottom: 10px;
				height: 2px;
				background-color: $yellow;
			}
		}
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp($navigation-bp) {
		line-height: 1;
	}
}

.c-navigation__expander {
	position: absolute;
	right: $bsu * 1.5;
	top: 50%;
	transform: translateY(-50%);

	@include bp($navigation-bp) {
		right: 0;
	}

	&::before {
		display: block;

		@include font-icon;

		@include bp-below($navigation-bp) {
			content: $iconf-arrow-right;

			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(90deg);
			}
		}

		@include bp($navigation-bp) {
			content: $iconf-arrow-down;
			font-size: 0.7em;

			.c-navigation__item[aria-expanded="true"] & {
				transform: rotate(-180deg);
			}
		}

		@include link-transition(transform);
		transform-origin: 50% 50%;
	}
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	@include list-reset;
	display: none;
	padding: $ssu 0 $ssu $bsu;
	width: 100%;
	background: $navigation-bg;

	@include bp($navigation-bp) {
		position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);
	}

	[aria-expanded="true"] > &,
	.no-js :hover > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp($navigation-bp) {
		@include ui-border("bottom");
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include font-base-14b;
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&::before {
		display: none;
	}

	@include bp($navigation-bp) {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-navigation__footer {
	background: $grey--lightest;
	padding: $lsu $bsu;

	@include bp($navigation-bp) {
		display: none;
	}
}

.c-navigation__social-list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-navigation__social-item {
	padding-left: $bsu;
	padding-top: $bsu;
}

.c-navigation__social-link {
	@include icon-wrapper;
	@include circle(40px);
	@include link-transition;
	font-size: 20px;
}

// Language container
.c-navigation__language {
	padding: $bsu;

	@include bp('medium') {
		display: none;
	}
}

// Member Navigation

.c-member-navigation__wrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9;
	height: 65px;

	@include bp('medium') {
		position: relative;
		height: auto;
		z-index: 1;
	}

	.c-member-navigation {
		height: 65px;
		padding: 0px;
		transition: all 0.25s ease-in-out;
		background: $white;
		filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.05));

		@include bp('medium') {
			padding: 30px;
			position: relative;
			height: auto;
			background: $dark-blue--dark;
		}

		.c-member-navigation__wrapper {
			text-align: center;
			display: flex;
			justify-content: space-between;

			@include bp('medium') {
				justify-content: center;
			}

			.c-member-navigation__links {
				color: $blue;
				margin: 0 10px;
				padding: 10px;
				height: 65px;
				width: 65px;

				@include bp('medium') {
					margin: 0 30px;
				}

				&.nav-item-active {
					color: $dark-blue;

					&:after {
						display: block;
						bottom: auto;
						top: 0;
						height: 5px;
						transform: none;
						background-color: $yellow;
						border-radius: 0 0 5px 5px;

						@include bp('medium') {
							display: none;
						}
					}
				}

				@include bp('medium') {
					color: $white;
					margin: 0 30px;
					padding: 0px;
					height: auto;
					width: auto;

					&.nav-item-active {
						color: $yellow;
					}
				}

				span {
					display: block;
					font-size: 20px;
					vertical-align: text-bottom;
					font-weight: 300;
					margin-right: 0px;

					@include bp('medium') {
						display: inline-block;
						margin-right: 5px;
					}
				}

				position: relative;
				@include underline-animate;

				&:after {
					display: none;

					@include bp('medium') {
						display: block;
						bottom: -10px;
						height: 2px;
						background-color: $yellow;
					}
				}

				&.c-member-navigation__logout {
					display: none;

					@include bp('medium') {
						display: inline-block;
					}
				}
			}
		}
	}
}

// Slideout Member Navigation

.c-slideout {
	&.c-membernav {
		padding: ($bsu * 1.5) 0;

		&.logged-in {
			padding: ($bsu * 1.5);
		}
	}
}

.c-membernav__name {
	@include ui-border("bottom");
	@include font-base-14;
	padding: ($ssu * 1.5) $ssu;
	border-color: $dark-blue--light;
}

.c-member-navigation__wrapper {
	.c-membernav & {
		position: static;
	}
}

.c-member-navigation__wrapper {
	.c-membernav .c-member-navigation__wrapper .c-member-navigation & {
		@include flex-extend;
		flex-direction: column;
		margin-bottom: $bsu;
		position: static;
		text-align: left;
		justify-content: flex-start;
	}
}

.c-member-navigation__wrapper .c-member-navigation {
	.c-membernav & {
		padding: 0;
		position: relative;
		height: auto;
		background-color: transparent;
	}
}

.c-member-navigation__links {
	.c-membernav .c-member-navigation__wrapper .c-member-navigation .c-member-navigation__wrapper & {
		@include font-base-16b;
		@include ui-border("bottom");
		border-color: $dark-blue--light;
		color: $white;
		padding: ($ssu * 1.5) $ssu;
		display: block;
		position: relative;
		@include underline-animate;
		width: 100%;
		margin: 0;

		&:after {
			bottom: 0;
			height: 2px;
			background-color: $yellow;
		}
	}
}

.c-member-navigation__link-icon {
	.c-membernav .c-member-navigation__wrapper .c-member-navigation .c-member-navigation__wrapper & {
		display: inline-block;
		margin-right: $ssu;
	}
}

.c-membernav__btn {
	@include button("small");
	@include btn-primary-outline;
}

.c-member-auth__layout {
	.c-membernav & {
		padding: 0 ($bsu * 1.5);
	}
}

.c-membernav-content-wrap {
	padding: ($bsu * 1.5);

	&.bg-grey {
		background-color: $grey--lightest;
	}
}

.c-membernav-content-heading {
	color: $secondary-a;
	color: var(--secondary-a);
	@include font-base-18b;
}

.c-membernav-content__quick-link {
	@include font-base-14b;
	display: inline-block;
	margin-bottom: $bsu;
}

.c-membernav-content {
	@include font-base-14;
	margin: $ssu 0;

	p {
		@include font-base-14;
		margin-bottom: $ssu;

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		color: $link;

		&:hover,
		&:focus {
			color: $link-hover;
		}
	}
}

.c-membernav-content__btn-wrap {
	@include flex-extend;
	flex-wrap: wrap;
	gap: $ssu;
	margin-top: $bsu;
}

.c-membernav-content__btn {
	@include button("small");
	@include btn-secondary;
}