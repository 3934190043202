// COMPONENT.BASIC-FILTER

.c-basic-filter--layout {
	@include ui-border('top');
	@include ui-border('bottom');
	padding: $bsu 0 $tsu;
	background: $white;
	margin-bottom: 20px;

	.c-listing--grey & {
		margin-bottom: 0;
	}

	@include bp("large") {
		padding: $bsu 0;
	}
}

.c-basic-filter--layout__members-filter {
	margin-top: 0;
	margin-bottom: 80px;
	position: relative;

	@include bp("medium") {
		margin-bottom: 20px;
	}
}

.c-basic-filter-wrap {
	@include layout-wrapper;
	display: flex;
	flex-direction: row;
	gap: $bsu;
	padding-bottom: 15px;
	align-items: flex-start;
	position: initial;

	@include bp("medium") {
		padding-bottom: 0px;
		position: relative;
	}

	@include bp-below("large") {
		max-width: 100vw;
		overflow-x: auto;
		@include thin-scrollbar("dark");
	}
}

.c-basic-filter {
	@include list-reset;
	display: flex;
	gap: $ssu;
	flex: 1 auto;
	flex-direction: row;

	@include bp("large") {
		flex-wrap: wrap;
	}
}

.c-basic-filter__item {
	display: flex;
	align-items: center;
}

.c-basic-filter__item-filter-icon {
	font-size: 20px;
	color: $secondary-a;
	margin-top: $ssu;
}

.c-basic-filter__button {
	@include button("smallest");
	@include btn-secondary;

	&[aria-current="true"] {
		@include btn-secondary--selected;
	}

	@include bp-below("large") {
		white-space: nowrap;
	}
}

.c-basic-filter__dropdown-wrapper {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: -60px;
	right: 20px;

	@include bp("medium") {
		bottom: auto;
	}

	.c-basic-filter__dropdown {
		display: block;
		background: $grey--lightest;
		color: $grey--dark;
		border: 1px solid $grey;
		padding: 7px 10px;
		margin-left: 10px;

		@include radius;
	}
}
