// OBJECT.CONTROL

@mixin control {
	max-width: 100vw;

	@include bp-below("large") {
		margin-top: $lsu;
		margin-bottom: $lsu;
		margin-top: var(--control-gap);
		margin-bottom: var(--control-gap);
	}

	@include bp("large") {
		margin-top: $hsu;
		margin-bottom: $hsu;
		margin-top: var(--control-gap);
		margin-bottom: var(--control-gap);
	}
}

@mixin control__heading {
	@include font-heading-20;
	color: $secondary-a;
	margin-bottom: 30px;

	@include bp("medium") {
		@include font-heading-32;
		margin-bottom: 40px;
	}

	@include bp("large") {
		@include font-heading-40;
	}
}

@mixin control__figcaption {
	//@include ui-border("bottom");
	@include font-body-text;
	padding: $bsu 0;
}
