﻿.c-login-selector {
	width: 100%;
}

.c-login-selector__item {
	width: 100%;
}

.c-login-selector__item-header {
	width: 100%;
	background: $dark-blue;
	color: $white;
	display: flex;
	justify-content: center;
	padding: 15px;
	margin-bottom: 10px;
	align-items: center;

	@include font-base-18b;
	@include link-transition;
	@include radius;

	&:hover {
		cursor: pointer;
		color: $blue--light;
	}

	.c-login-selector__item-header-dot {
		height: 20px;
		width: 20px;
		border: 2px solid $blue--light;
		border-radius: 50%;
		margin-right: 10px;
	}

	&[aria-expanded="true"] {
		background: $blue--light;

		&:hover {
			cursor: pointer;
			color: $dark-blue;
		}

		.c-login-selector__item-header-dot {
			border-color: $white;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				height: 12px;
				width: 12px;
				background: $white;
				border-radius: 50%;
				margin: 2px;
			}
		}
	}
}

.c-login-selector__item-body {
	width: 100%;
	display: none;
	padding: 20px;

	@include bp("medium") {
		padding: 40px;
	}
}

.c-login-selector__header {
	@include yellow-line-heading;
	@include font-heading-32;
	color: $dark-blue;
}

.c-login-selector__header-small {
	@include yellow-line-heading;
	@include font-heading-24;
	color: $dark-blue;
}

.c-login-selector__forgot-password {
	@include control;
	width: 100%;

	.c-login-selector__item-body &,
	.c-slideout & {
		margin: $bsu 0;
	}

	.c-tabbed-booking__login-form & {
		margin: $bsu 0 0;
	}

	a {
		width: 100%;
		display: block;
		text-align: center;
		color: $blue;
		@include link-transition;

		&:hover {
			color: $dark-blue;
		}
	}
}

.c-login-selector__membership-message {
	display: block;
	margin-top: 20px;

	@include bp("medium") {
		display: flex;
	}

	.c-login-selector__membership-text {
		width: 100%;

		@include bp("medium") {
			width: calc(100% - 260px);
			padding-right: 30px;
		}

		.c-login-selector__membership-text-red {
			@include font-base-18b;
			color: $red;
			margin-bottom: 20px;
		}

		.c-login-selector__membership-text-partial-blue {
			@include font-base-18b;
			margin: 10px 0;

			span {
				color: $blue;
			}
		}
	}

	.c-login-selector__membership-join {
		width: 100%;

		@include bp("medium") {
			width: 260px;
		}

		.c-login-selector__membership-savings {
			@include font-base-18b;
			text-align: center;
			color: $blue;
			margin-bottom: 10px;
		}

		.c-login-selector__membership-button {
			@include button-size("small");
			@include btn-primary;
			display: block;
		}
	}
}

.c-login-selector__copy-container {
	margin-top: 40px;

	a {
		color: $blue;
		@include link-transition;

		&:hover {
			color: $dark-blue;
		}
	}
}

.c-login-selector__blue-line-seperator {
	position: relative;
	z-index: 1;
	overflow: hidden;
	@include font-base-16b;
	text-align: center;
	margin: 60px 0;

	&:before {
		position: absolute;
		top: 54%;
		left: 0;
		overflow: hidden;
		width: 47%;
		height: 1px;
		content: " ";
		background: #67B8E6;
	}

	&:after {
		position: absolute;
		top: 54%;
		right: 0;
		overflow: hidden;
		width: 47%;
		height: 1px;
		content: " ";
		background: #67B8E6;
	}
}

.c-login-selector__club-benefits {
	background: $blue--lightest;
	padding: 40px;
	margin-top: 80px;

	@include bp("medium") {
		padding: 80px;
	}

	.c-login-selector__club-benefits-image {
		margin-top: -120px;
		margin-bottom: 20px;

		img {
			width: 150px;
		}
	}

	.c-login-selector__club-benefits-heading {
		@include yellow-line-heading;
		@include font-heading-32;
		color: $dark-blue;
	}

	.c-login-selector__club-benefits-link {
		@include font-base-14b;
		@include link-transition;
		color: $blue;

		&:hover {
			color: $dark-blue;
		}
	}

	.c-login-selector__club-benefits-listing {
		display: block;
		;
		margin-top: 20px;

		@include bp("medium") {
			display: flex;
			flex-wrap: wrap;
		}

		.c-login-selector__club-benefits-listing-item {
			flex: 50%;
			display: flex;
			align-items: flex-start;
			margin-bottom: 10px;
			padding-right: 10px;

			span {
				display: block;
				height: 25px;
				width: 25px;
				font-size: 25px;
				color: $blue--light;
				margin: 5px 5px 0 0;
				font-weight: 300;
			}

			> div {
				width: calc(100% - 25px);
			}
		}
	}
}

.c-login-selector__join-heading {
	@include yellow-line-heading;
	@include font-heading-32;
	color: $dark-blue;
}

.c-login-selector__join-description {
	margin-bottom: 40px;
}

.c-login-selector__join-subheading {
	@include yellow-line-heading;
	@include font-heading-24;
	color: $dark-blue;
}
