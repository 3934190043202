// CONTROL.JUICER

.c-juicer {
	@include control;
}

.c-juicer__layout {
	@include layout-wrapper;
}

.c-juicer__heading {
	@include yellow-line-heading;
	color: $brand--alt;
}
