// CONTROL.LISTING
.c-listing {
	@include control;

	&.c-deals-listing {
		margin-top: 0;
	}
}

.c-listing__main {

	.c-listing--grey & {
		padding: $bsu *1.5 0 $lsu;
		background: $grey--lightest;
	}
}

.c-listing__layout {
	@include layout-wrapper;
	display: block;
/*
	@include bp("medium") {
		display: flex;
	}*/
}

.c-listing__heading {
	@include control__heading;
	margin: 10px 20px 10px 0;
}

.c-listing__no-results {
	display: block;
	margin-top: $bsu;

	@include font-base-18;
}
