// CONTROL.LISTING-PODS

.c-listing-pods {
	width: 100%;
	margin: $bsu 0;
}

.o-tile__figure {
	.c-listing-pods & {
		@include radius;
		margin-bottom: $bsu;
	}
}

.o-tile__body {
	.c-listing-pods & {
		padding: 0;
		margin-bottom: $bsu;
	}
}

.o-tile__heading {
	.c-listing-pods & {
		@include font-heading-20;
		margin-bottom: $bsu;
		color: $secondary-a;
	}
}

.o-tile__read-more {
	@include font-base-14b;
}