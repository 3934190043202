﻿.c-home-introduction {
	position: relative;

	&:before {
		overflow: hidden;
		position: absolute;
		right: 0;
		content: '';
		height: 400px;
		width: 100%;
		clip-path: circle(60%at 50% 10%);
		background: $green-gradient;
		z-index: -1;

		@include bp("medium") {
			height: 500px;
			width: 1000px;
			clip-path: circle(460px at 850px 0px);
		}
	}
}

.c-home-introduction__wrapper {
	max-width: 1200px;
	padding: $hsu $lsu 0;
	margin: 0 auto;
	display: block;

	@include bp("medium") {
		display: flex;
		padding: $lsu $bsu 0;
	}
}

.c-home-introduction__split {
	width: 100%;

	@include bp("medium") {
		width: 50%;
	}

	.c-home-introduction__eyebrow {
		@include font-base-16b;
		color: $blue;
		text-align: center;

		@include bp("medium") {
			@include font-base-18b;
			text-align: left;
		}
	}

	.c-home-introduction__title {
		@include font-heading-24;
		color: $dark-blue;
		text-align: center;

		@include bp("medium") {
			@include font-heading-48;
			text-align: left;
		}
	}
}

.c-home-introduction__badges {
	display: none;

	@include bp("medium") {
		display: flex;
		justify-content: end;
	}

	.c-home-introduction__badge {
		width: 230px;
		padding: 20px;

		img {
			width: 100%;
		}
	}
}
