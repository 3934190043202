﻿// CONTROL.IMAGE-GALLERY

.c-image-gallery {
	@include control;

	.flickity-prev-next-button {
		top: calc(50% - 29px);
		@include circle(44px);
		@include button("small");
		@include btn-primary;
		padding: 0;

		&.previous {
			left: $bsu;
		}

		&.next {
			right: $bsu;
		}
	}

	.flickity-button-icon {
		fill: $secondary-a;
	}
}

.c-image-gallery__layout {
	@include layout-wrapper;
}

.c-image-gallery__heading {
	@include control__heading;
}

.c-image-gallery__slides {
	@include list-reset;

	&:focus-visible {
		outline: none;
	}
}

.c-image-gallery__slide {
	width: 100%;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}
}

.c-image-gallery__slide-link {
	@include figure;
	@include radius;
}

.c-image-gallery__caption {
	@include control__figcaption;

	@include flex-extend;
	align-items: center;
	justify-content: flex-end;
	opacity: 0;

	.c-image-gallery__slide.is-selected & {
		opacity: 1;
	}
}

.c-image-gallery__caption-text {
	@include font-body-text;
	flex: 1 2 auto;
}

.c-image-gallery__caption-count {
	display: none;

	@include bp("medium") {
		@include font-base-16b;
		display: inline;
		padding-left: $lsu;
		white-space: nowrap;
	}
}
