﻿.c-park-pod {
	width: 320px;
	height: 390px;
	background: $white;
	margin: 0 $ssu $lsu 0;

	@include radius;
	@include box-shadow;
	overflow: visible;
}

.c-park-pod__image {
	width: 100%;
	height: 200px;
	min-height: auto;
	display: block;
	position: relative;
	overflow: hidden;
	border-radius: 4px 4px 0 0;

	img {
		width: 100%;
		height: auto;
	}

	.c-park-pod__region {
		position: absolute;
		left: 10px;
		bottom: 5px;
		width: 35px;
		height: 50px;
		z-index: 1;

		&:before {
			position: absolute;
			left: -30px;
			bottom: -30px;
			content: "";
			height: 90px;
			width: 90px;
			border-radius: 50%;
			background: $yellow;
			z-index: -1;
		}

		&:after {
			position: absolute;
			left: -50px;
			bottom: -50px;
			content: "";
			height: 130px;
			width: 130px;
			border-radius: 50%;
			z-index: -2;
		}

		&.Premium:after {
			background: $premium-opacity;
		}

		&.Classic:after {
			background: $classic-opacity;
		}

		&.Superior:after {
			background: $superior-opacity;
		}
	}
}

.c-park-pod__details {
	height: 150px;
	padding: 20px;
	position: relative;

	.c-park-pod__details-tag {
		@include font-base-12b;
		height: 24px;
		border-radius: 12px;
		width: fit-content;
		line-height: 20px;
		color: $white;
		padding: 0 10px 0 5px;
		position: absolute;
		z-index: 1;
		top: -12px;
		left: 20px;

		&.Premium {
			background: $premium;
		}

		&.Superior {
			background: $superior;
		}

		&.Classic {
			background: $classic;
		}

		& span {
			display: inline-block;
			vertical-align: sub;
			height: 15px;
			width: 15px;
			margin-top: 4px;
			margin-right: 4px;
		}
	}
}

.c-park-pod__details-wrapper {
	display: flex;
	height: 145px;

	.c-park-pod__details-property-name {
		width: 100%;
		margin-top: $bsu;
		margin-right: $bsu;
		position: relative;
	}

	.c-park-pod__details-stats {
		width: 30%;
		margin-top: $bsu;
		position: relative;
	}

	.c-park-pod__details-property-name {
		.c-park-pod__details-name {
			@include font-base-16b;
			color: $dark-blue;
			@include link-transition;

			&:hover {
				color: $blue;
			}
		}

		.c-park-pod__details-location {
			margin-top: $ssu;
			@include font-base-12;
		}
	}

	.c-park-pod__details-stats {
		.c-park-pod__tooltip-wrapper {
			height: 15px;
			width: 15px;
			position: absolute;
			right: 0;
			top: 0;

			.c-park-pod__tooltip {
				height: 12px;
				width: 12px;
				border-radius: 50%;
				background: $dark-blue;
				color: $white;
				float: right;

				span {
					display: block;
					font-size: 10px;
					line-height: 10px;
					margin-left: 5px;
				}

				&:hover {
					cursor: pointer;
					@include box-shadow;
				}
			}
		}

		.c-park-pod__score-wrapper {
			display: flex;
			padding-left: $ssu;

			.c-park-pod__score {
				width: 50px;
				height: 30px;
				@include font-heading-32;
				text-align: right;
				line-height: 32px;
				color: $grey--darkest;
			}

			.c-park-pod__benchmark {
				width: 25px;
				height: 20px;
				@include font-base-16b;
				line-height: 24px;
				color: $grey--darkest;
				padding-top: 10px;
			}
		}

		.c-park-pod__score-text {
			font-weight: 800;
			font-size: 8px;
			line-height: 16px;
			text-align: right;
			letter-spacing: 0.04em;
			color: $grey--darkest;
		}

		.c-park-pod__score-rating-link {
			text-align: right;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

.c-park-pod__details-buttons {
	display: flex;
	justify-content: space-around;
	margin: 0 -5px;

	.c-park-pod__details-book-button {
		@include button("small");
		@include btn-primary;
		flex: 1;
		margin: 0 5px;
	}
}

.c-park-pod__tooltip-wrapper .c-tooltip__body {
	position: fixed;
	width: 300px;
	left: -18px;
	top: 200px;
	padding: 15px;

	&:before {
		display: none;
	}

	.c-tooltip__content > p {
		font-size: 12px;
		line-height: 18px;
	}

	.c-tooltip__close {
		&:hover {
			cursor: pointer;
		}
	}
}