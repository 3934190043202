﻿// COMPONENT.MEMBER-PROFILE

.c-member-profile__form {
	@include control;
	
}

.c-member-profile__form-layout {
	@include layout-wrapper;
}

.c-member-profile__form-heading {
	display: inline-block;
	margin-bottom: $bsu;
	padding: 0;

	@include yellow-line-heading;
	@include font-heading-32;
	color: $secondary-a;
	color: var(--secondary-a);
}


.c-member-profile-form {
	.c-form__submit-btn {
		@include bp("medium") {
			margin-top: $bsu;
		}
	}
}
