﻿// component.join-cta

$benefit-icon-size: 24px;

.c-join-cta {
	position: relative;
	padding: $lsu $bsu;
	color: $white;
	background-color: $brand--alt;
	overflow: hidden;

	@include bp('extra-large') {

		&::before,
		&::after {
			position: absolute;
			border-radius: 100% 0 0 0;
			content: "";
			bottom: 0;
			right: 0;
		}

		&::before {
			width: 153px;
			height: 153px;
			background-color: $yellow;
			z-index: 2;
		}

		&::after {
			width: 290px;
			height: 290px;
			background-color: $blue--light;
			z-index: 1;
		}
	} 
}

.c-join-cta__wrapper {
	@include layout-wrapper;

	@include bp('extra-large') {
		padding-right: 300px;
	}
}

.c-join-cta__heading {
	@include yellow-line-heading;
	@include font-base-18b;
	padding-bottom: $ssu;

	@include bp('medium') {
		@include font-heading-24;
	}

	@include bp('large') {
		@include font-heading-40;
	}
}

.c-join-cta__text {
	@include font-base-14;
	margin-bottom: $bsu;

	@include bp('large') {
		@include font-base-16;
	}
}

.c-join-cta__benefits {

	@include bp('medium') {
		column-count: 2;
		column-gap: $lsu;
	}
}

.c-join-cta__benefit {
	position: relative;
	padding-left: $benefit-icon-size + $ssu;
	margin-bottom: $bsu;

	&:nth-child(n+3) {
		display: none;

		.c-join-cta.is-open & {
			display: block;
		}

		@include bp('large') {
			display: block;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.c-join-cta__benefit-icon {
	position: absolute;
	left: 0;
	top: 2px;
	font-size: $benefit-icon-size;
	color: $brand;
}

.c-join-cta__benefit-text {
	@include font-base-14;
	display: inline-block;

	@include bp('large') {
		@include font-base-16b;
	}
}

.c-join-cta__more {
	@include font-base-14b;
	@include link-transition;
	position: relative;
	display: inline-block;
	padding: 0;
	padding-left: $bsu;
	border: none;
	background: none;
	color: $brand;
	cursor: pointer;

	&:focus,
	&:hover {
		color: $white;
	}

	&::before {
		@include font-icon;
		content: $iconf-plus;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	.c-join-cta.is-open & {
		display: none;
	}

	@include bp('large') {
		display: none;
	}
}
