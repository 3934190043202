﻿// CONTROL.SUBNAV

$selected-accent-width: 5px;

.c-subnav__heading {
	@include font-heading-20;
	display: block;
	margin-bottom: 0.7em;
	color: $body-color;
}

.c-subnav__items {
	@include list-reset;
	@include font-base-18b;
}

.c-subnav__item {
	background-color: rgba($blue--lightest, 0.5);

	&[aria-current]:not([aria-current="false"]) {
		position: relative;

		&::before {
			@include pseudo-element;
			left: 0;
			top: 0;
			width: $selected-accent-width;
			height: calc(100%);
			background-color: $blue--light;
		}

		&:hover,
		&:focus {
			&::before {
				background-color: $secondary-a;
				background-color: var(--secondary-a);
			}
		}
	}
}

.c-subnav__link,
.c-subnav__child-link {
	@include link-transition;
	color: $secondary-a;
	color: var(--secondary-a);

	&:hover,
	&:focus {
		color: $yellow;
		background-color: $secondary-a;
		background-color: var(--secondary-a);
	}
}

.c-subnav__link {
	display: block;
	padding: $ssu $bsu;
	padding-left: $bsu + $selected-accent-width;

	[aria-current]:not([aria-current="false"]) > & {
		background-color: $blue--accent;

		&:hover,
		&:focus {
			color: $yellow;
			background-color: $secondary-a;
			background-color: var(--secondary-a);
		}
	}
}

.c-subnav__children {
	@include list-reset;

	padding: $ssu $bsu;
	padding-left: ($bsu * 1.5) + $selected-accent-width;
}

.c-subnav__child {
	margin-bottom: 0.75em;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-subnav__child-link {
	color: $body-color;
}