﻿// CONTROL.CTA

.c-cta {
	@include control;

	.l-member-page__sidebar & {
		padding-top: 0;
		padding-bottom: 0;
		margin-bottom: 20px;
	}
}

.c-cta__layout {
	@include layout-wrapper;
}

.c-cta--dark {
	.c-cta__body {
		background: $secondary-a;
		box-shadow: none;
	}

	.c-cta__heading {
		color: $yellow;
	}

	.c-cta__text {
		color: $white;
	}
}

.c-cta__body {
	@include radius;
	@include flex-extend;
	position: relative;
	min-height: 115px;
	padding: ($bsu * 1.5);
	text-align: center;
	background-color: $white;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 8px rgba($black, 0.2);

	> *:last-child {
		margin-bottom: 0;
		margin-right: 0;
	}

	@include bp("medium") {
		padding: $bsu;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		text-align: left;

		.l-page__sidebar & {
			flex-direction: column;

			&::before,
			&::after {
				display: none;
			}
		}
	}

	@include bp("large") {
		@include interface-sun('small', 'left');

		.l-page__sidebar & {
			padding: ($bsu * 1.5);
		}
	}

	.l-member-page__sidebar & {
		align-items: flex-start;
	}
}

.c-cta__body-wrapper {
	margin-bottom: $bsu;

	@include bp("medium") {
		flex: 1 1 auto;

		.l-page__main-controls & {
			padding-right: $lsu;
			margin-bottom: 0;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-cta__heading {
	@include font-base-18b;
	margin-bottom: 0.5em;
	color: $secondary-a;

	@include bp("medium") {
		@include font-heading-20;
	}

	@include bp("large") {
		@include font-heading-24;
	}
}

.c-cta__text {
	@include font-body-text;
	margin-bottom: 2em;
	color: $grey--darkest;
}

.c-cta__link {
	@include button("medium");
	@include btn-primary;

	@include bp("medium") {
		white-space: nowrap;
	}

	.l-member-page__sidebar & {
		@include button("small");
	}
}

.c-cta__link-wrap {
	.l-member-page__sidebar & {
		display: flex;
		flex-direction: row;
		gap: $ssu;
		flex-wrap: wrap;
	}
}

.c-cta__link-wrap {
	.c-cta__link {
		&.js-member-mail-button {
			span {
				display: none;
			}

			&.done {
				span {
					display: inline-block;
				}
			}
		}

		&.js-member-card-button {
			span {
				display: none;
			}

			&.done {
				span {
					display: inline-block;
				}
			}
		}
	}

	.c-cta__success {
		display: none;

		&.js-member-mail-message {
			&.show {
				display: block;
			}
		}

		&.js-member-park-message {
			&.show {
				display: block;
			}
		}
	}
}
