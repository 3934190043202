﻿#ParkFinder {
	min-height: 800px;
	scroll-margin-top: 50px;
}

.c-park-finder {
	@include control;
	border-bottom: 1px solid #e2e2e2;
}

.c-park-finder__filter {
	@include ui-border('top');
	@include ui-border('bottom');
	padding: $bsu 0 $tsu;
	background: $white;

	@include bp("medium") {
		padding: $bsu 0;
	}
}

.c-park-finder__filter-wrap {
	@include layout-wrapper;
	display: flex;
	flex-direction: column;
	gap: $bsu;
	padding-bottom: 15px;
	align-items: flex-start;
	position: relative;

	@include bp("medium") {
		flex-direction: column;
		padding-bottom: 0px;
		position: relative;
	}

	@include bp-below("large") {
		max-width: 100vw;
	}

	@include bp(1600px) {
		max-width: 100%;
		flex-direction: row;
	}
}

.c-park-finder__title {
	@include font-heading-24;
	color: $dark-blue;
	margin-right: $lsu;
	margin-bottom: 30px;
	white-space: nowrap;

	@include bp("medium") {
		margin-bottom: 0;
	}
}

.c-park-finder__searchbar-wrapper {
	width: 250px;
}

.c-park-finder__filter-searchbar-container {
	display: block;

	@include bp("medium") {
		@include flex-extend;
		align-items: center;
	}
}

.c-park-finder__filter {
	input[type="text"] {
		@include form-input;
	}
}

.c-park-finder__filters-wrapper {
	width: 100%;

	@include bp("medium") {
		width: auto;
	}
}

.c-park-finder__filters-buttons {
	@include thin-scrollbar("dark");
	display: flex;
	width: 100%;
	overflow: auto;
	white-space: nowrap;

	@include bp("medium") {
		overflow: visible;
	}
}

.c-park-finder__filters {
	display: flex;
	position: relative;
	width: 100%;

	.c-park-finder__filters-button {
		@include button("small");
		@include btn-secondary;
		margin-right: 10px;
		white-space: nowrap;

		&.active {
			@include btn-secondary--selected;
		}

		&[aria-expanded="true"] {
			@include btn-secondary--selected;
		}
	}

	.c-park-finder__filters-dropdown {
		position: absolute;
		left: 0;
		top: 50px;
		width: 100%;
		z-index: 3;
		background: $white;

		@include bp("medium") {
			width: 580px;
		}

		@include radius;
		@include box-shadow;

		&[aria-expanded="false"] {
			display: none;
		}

		&[aria-expanded="true"] {
			display: block;
		}

		.c-park-finder__filters-dropdown-wrapper {
			padding: 20px;

			.c-form__field-group-two-col {
				justify-content: space-between;
			}

			.c-park-finder__filters-dropdown-guests-label {
				width: 50%;
			}

			.c-park-finder__filters-dropdown-close {
				display: flex;
				justify-content: end;
				padding-top: 20px;

				&.nopadding {
					padding-top: 0px;
				}

				.c-park-finder__filters-dropdown-close-button {
					@include button("small");
					@include btn-primary;
				}
			}
		}
	}
}

.c-park-finder__filter-promo {
	display: flex;
	align-items: center;
}

.c-park-finder__filter-promo-label {
	@include font-base-14b;
	margin-right: $ssu;
}

.c-park-finder__filter-promo-input {
	min-width: 150px;
}

.c-park-finder__map-button-wrapper {
	position: absolute;
	right: 15px;
}

.c-park-finder__filters-accommodation-button,
.c-park-finder__filters-interests-button {
	@include button-reset;
	@include button("smallest");
	@include btn-secondary;
	margin: 0 10px 10px 0;

	&.active {
		@include btn-secondary--selected;
	}

	&.firstloadType {
		@include btn-secondary--selected;
	}

	&.firstloadItem {
		@include btn-secondary--selected;
	}
}

.c-park-finder__map-button {
	@include button-reset;
	@include button("small");

	&[aria-expanded="true"] {
		@include btn-secondary--selected;

		.map-close {
			display: inline-block;
		}

		.map-open {
			display: none;
		}
	}

	&[aria-expanded="false"] {
		@include btn-primary;

		.map-open {
			display: inline-block;
		}

		.map-close {
			display: none;
		}
	}
}

.c-park-finder__results-wrapper {
	@include ui-border('bottom');
	display: flex;
	flex-direction: column;

	@include bp(1250px) {
		flex-direction: row-reverse;
	}
}

.c-park-finder__results-map-wrapper {
	height: 0;
	transition: height 0.3s linear;

	&[aria-expanded="true"] {
		height: 500px;
		overflow: hidden;
	}

	@include bp(1250px) {
		flex: 0;
		height: 800px;
		transition: flex 0.5s linear;

		&[aria-expanded="true"] {
			flex: 0.4;
			height: 800px;
		}
	}
}

.c-park-finder__results-listing-wrapper {
	width: 100%;

	@include bp(1250px) {
		flex: 1;
		transition: flex 0.5s linear;

		.c-park-finder__results-map-wrapper[aria-expanded="true"] + & {
			flex: 0.6;
			height: 800px;
		}
	}
}

.c-park-finder__results-slideout {
	width: 100%;
	height: 100%;
	margin-left: 0px;
	position: relative;
}

.c-park-finder__results-listing {
	background: $grey--lightest;
	padding: 40px 20px;
	height: 100%;
	display: grid;
	gap: 2rem 1rem;
	grid-auto-flow: dense;
	grid-template-columns: repeat(auto-fit, 100%);
	justify-content: center;
	overflow: auto;
	overflow-x: hidden;

	@include bp(400px) {
		padding: 40px;
	}

	@include bp("medium") {
		grid-template-columns: repeat(auto-fit, 20rem);
		gap: 2rem;
	}
}

//==========------------    Pod    ------------==========//

.c-park-finder-pod {
	width: 290px;
	height: 380px;
	background: $white;
	margin: 0 auto;

	@include radius;
	@include box-shadow;
	overflow: visible;
	align-items: flex-start;
	background: $white;
	display: flex;
	flex-direction: column;

	@include bp(400px) {
		width: 320px;
	}

	@include bp("medium") {
		margin: $bsu $ssu $bsu 0;
	}
}

.c-park-finder-pod__image {
	width: 100%;
	height: 200px;
	min-height: auto;
	position: relative;
	overflow: hidden;
	border-radius: 4px 4px 0 0;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.c-park-finder-pod__region {
		position: absolute;
		left: 10px;
		bottom: 5px;
		width: 35px;
		height: 50px;
		z-index: 1;

		&:before {
			position: absolute;
			left: -30px;
			bottom: -30px;
			content: "";
			height: 90px;
			width: 90px;
			border-radius: 50%;
			background: $yellow;
			z-index: -1;
		}

		&:after {
			position: absolute;
			left: -50px;
			bottom: -50px;
			content: "";
			height: 130px;
			width: 130px;
			border-radius: 50%;
			z-index: -2;
		}

		&.Premium:after {
			background: $premium-opacity;
		}

		&.Classic:after {
			background: $classic-opacity;
		}

		&.Superior:after {
			background: $superior-opacity;
		}
	}
}

.c-park-finder-pod__details {
	height: 140px;
	width: 100%;
	padding: 20px;
	position: relative;

	.c-park-finder-pod__details-tag {
		@include font-base-12b;
		height: 24px;
		border-radius: 12px;
		width: fit-content;
		line-height: 20px;
		color: $white;
		padding: 0 10px 0 5px;
		position: absolute;
		z-index: 1;
		top: -12px;
		left: 20px;

		&.Premium {
			background: $premium;
		}

		&.Superior {
			background: $superior;
		}

		&.Classic {
			background: $classic;
		}

		& span {
			display: inline-block;
			vertical-align: sub;
			height: 15px;
			width: 15px;
			margin-top: 4px;
			margin-right: 4px;
		}
	}
}

.c-park-finder-pod__details-wrapper {
	display: flex;
	height: 135px;

	.c-park-finder-pod__details-property-name {
		width: 100%;
		margin-top: $bsu;
		margin-right: $bsu;
		position: relative;
	}

	.c-park-finder-pod__details-stats {
		width: 30%;
		margin-top: $bsu;
		position: relative;
	}

	.c-park-finder-pod__details-property-name {
		.c-park-finder-pod__details-name {
			@include font-base-16b;
			color: $dark-blue;
			@include link-transition;

			&:hover {
				color: $blue;
			}
		}

		.c-park-finder-pod__details-location {
			margin-top: $ssu;
			@include font-base-12;
		}
	}

	.c-park-finder-pod__details-stats {
		.c-park-finder-pod__tooltip-wrapper {
			height: 15px;
			width: 15px;
			position: absolute;
			right: 0;
			top: 0;

			.c-park-finder-pod__tooltip {
				height: 12px;
				width: 12px;
				border-radius: 50%;
				background: $dark-blue;
				color: $white;
				float: right;

				span {
					display: block;
					font-size: 10px;
					line-height: 10px;
					margin-left: 5px;
				}

				&:hover {
					cursor: pointer;
					@include box-shadow;
				}
			}
		}

		.c-park-finder-pod__score-wrapper {
			display: flex;
			padding-left: $ssu;

			.c-park-finder-pod__score {
				width: 50px;
				height: 30px;
				@include font-heading-32;
				text-align: right;
				line-height: 32px;
				color: $grey--darkest;
			}

			.c-park-finder-pod__benchmark {
				width: 25px;
				height: 20px;
				@include font-base-16b;
				line-height: 24px;
				color: $grey--darkest;
				padding-top: 10px;
			}
		}

		.c-park-finder-pod__score-text {
			font-weight: 800;
			font-size: 8px;
			line-height: 16px;
			text-align: right;
			letter-spacing: 0.04em;
			color: $grey--darkest;
		}

		.c-park-finder-pod__score-rating-link {
			text-align: right;

			&:hover {
				cursor: pointer;
			}
		}
	}
}

.c-park-finder-pod__details-buttons {
	display: flex;
	justify-content: space-around;
	margin: 0 -10px;

	.c-park-finder-pod__site-button {
		@include button("small");
		@include btn-primary;
		flex: 1;
		margin: 0 5px;
		white-space: nowrap;
		max-width: 50%;
	}
}

.c-park-finder-pod__tooltip-wrapper .c-tooltip__body {
	position: absolute;
	max-width: 300px;
	width: 300px;
	left: -310px;
	top: -41px;
	padding: 15px;

	&:before {
		display: none;
	}

	.c-tooltip__content > p {
		font-size: 12px;
		line-height: 18px;
	}

	.c-tooltip__close {
		&:hover {
			cursor: pointer;
		}
	}
}

//==========------------    Panel    ------------==========//

.c-park-finder-details {
	position: fixed;
	left: 0;
	top: 60px;
	bottom: 0;
	right: 0;
	z-index: 5;
	overflow: scroll;
	overscroll-behavior: contain;

	@include bp("large") {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: auto;
		overscroll-behavior: auto;
	}
}

.c-park-finder-details {
	background: $white;
	padding: 20px;
}

.c-park-finder-details-panel-enter-active,
.c-park-finder-details-panel-leave-active {
	transition: all 0.2s;
	max-height: 230px;
}

.c-park-finder-details-panel-enter,
.c-park-finder-details-panel-leave-to {
	opacity: 0;
	max-height: 0px;
}

.c-park-finder-details__top-bar {
	margin-bottom: 10px;
	margin-right: 20px;

	@include bp(500px) {
		display: flex;
		align-items: center;
		height: 45px;
	}
}

.c-park-finder-details__name-bar {
	@include font-base-14b;
	color: $dark-blue;
	margin-right: 0px;
	margin-bottom: 10px;

	@include bp(500px) {
		margin-right: 20px;
		margin-bottom: 0px;
	}
}

.c-park-finder-details__button-bar {
	display: flex;
}

.c-park-finder-details__site-button {
	@include btn-primary;
	flex: 1;
	margin-right: 10px;
	white-space: nowrap;
	@include button("smallest");

	@include bp("small") {
		@include button("small");
	}

	&.active {
		@include btn-secondary--selected;
	}
}

.c-park-finder-details-panel__close {
	@include button("smallest");
	@include btn-primary;
	position: absolute;
	height: 40px;
	width: 40px;
	padding: 9px 10px;
	font-size: 20px;
	font-weight: bold;
	right: 0px;
	top: 5px;
	color: $dark-blue;

	@include bp("medium") {
		right: 20px;
		top: 20px;
	}

	&:hover {
		cursor: pointer;
	}
}

.c-park-finder-details__results {
	position: relative;
	display: grid;
	gap: 1rem;
	grid-auto-flow: dense;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, 100%);

	@include bp(1000px) {
		grid-template-columns: repeat(auto-fit, 800px);
	}

	@include bp(1250px) {
		grid-template-columns: repeat(auto-fit, 600px);
	}

	@include bp(1420px) {
		grid-template-columns: repeat(auto-fit, 800px);
	}

	@include bp(1768px) {
		grid-template-columns: repeat(auto-fit, 700px);
	}
}

.c-park-finder-details__loader {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $white;
}

//======------    Filter    ------======//

.c-park-finder-details__filters {
	margin-bottom: 20px;
	width: 100%;
	overflow: auto;
	white-space: nowrap;

	@include thin-scrollbar("dark");

	.c-park-finder-details__filter-icon {
		@include font-base-18;
		color: $dark-blue;
		margin-bottom: 10px;
		margin-right: 10px;
	}

	.c-basic-filter__button {
		margin: 0 10px 0px 0;

		&.active {
			@include btn-secondary--selected;
		}

		&.firstload {
			@include btn-secondary--selected;
		}
	}
}

//======------    Offer Pod    ------======//

.c-park-finder-details__pod {
	border: 1px solid $blue--light;
	@include radius;
	width: 100%;
	align-items: flex-start;
	background: #fff;
	display: flex;
	flex-direction: column;

	@include bp(1024) {
		width: 500px;
	}

	@include bp(1768px) {
		width: 700px;
	}
}

.c-park-finder-details__pod-details {
	width: 100%;
	display: flex;
}

.c-park-finder-details__pod-image {
	height: 110px;
	width: 165px;

	@include bp("medium") {
		min-height: 160px;
		width: 250px;
	}

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.c-park-finder-details__pod-details-button {
	@include button("small");
	@include btn-secondary;
	margin: 10px;

	&.mobile {
		display: inline-block;

		@include bp("medium") {
			display: none;
		}
	}

	&.desktop {
		display: none;
		margin: 10px 0 0;

		@include bp("medium") {
			display: inline-block;
		}
	}
}

.c-park-finder-details__pod-name {
	@include font-base-16b;
	margin-bottom: 10px;
	color: $dark-blue;

	@include bp("medium") {
		@include font-base-18b;
	}
}

.c-park-finder-details__attributes {
	display: block;
	margin-bottom: 0;

	@include bp("medium") {
		display: flex;
		margin-bottom: 10px;
	}
}

.c-park-finder-details__category {
	color: $dark-blue;
	margin-right: 10px;

	span:first-child {
		margin-right: 5px;
	}
}

.c-park-finder-details__pod-copy {
	padding: 15px;
	width: calc(100% - 165px);

	@include bp("medium") {
		padding: 17px 20px;
		width: calc(100% - 250px);
	}
}

.c-park-finder-details__pod-offers-bar {
	height: 36px;
	width: 100%;
	padding: 5px 20px;
	background: $blue--light;
	display: flex;
	justify-content: space-between;

	div {
		color: $white;
	}
}

.c-park-finder-details__pod-offers {
	width: 100%;
}

.c-park-finder-details__pod-offer {
	display: flex;
	padding: 20px;

	&:nth-child(odd) {
		background: $grey--lightest;
	}
}

.c-park-finder-details__pod-offer-text {
	width: calc(100% - 150px);
	padding-right: 20px;
}

.c-park-finder-details__pod-offer-name {
	@include font-base-14;
	margin-bottom: 10px;

	@include bp("medium") {
		@include font-base-16;
	}
}

.c-park-finder-details__pod-offer-strapline {
	@include font-base-14;
	color: $orange;
	margin-bottom: 15px;
}

.c-park-finder-details__pod-offer-actions {
	width: 150px;
}

.c-park-finder-details__pod-offer-total {
	@include font-base-18b;
	text-align: right;
}

.c-park-finder-details__pod-offer-members {
	@include font-base-14;
	text-align: right;
	color: $blue;
	margin-bottom: 10px;
}

.c-park-finder-details__pod-offer-button {
	@include button("small");
	@include btn-primary;
	display: block;
}

//======------    Offer Pod    ------======//

.c-parks-map {
	height: 100%;
	width: 100%;

	.gm-style .gm-style-iw-c {
		padding: 0;

		.gm-style-iw-d {
			overflow: hidden !important;
		}

		button.gm-ui-hover-effect {
			top: 0 !important;
			right: 0px !important;
			width: 24px !important;
			height: 24px !important;
			background: $white !important;
			border-radius: 50%;
			padding-left: 2px !important;

			span {
				width: 20px !important;
				height: 20px !important;
				margin: 0px !important;
				background-color: $dark-blue !important;
			}
		}
	}
}

.c-infowindow {
	width: 230px;
	max-height: 270px;
}

.c-infowindow__image-wrapper {
	height: 125px;
	width: 230px;
}

.c-infowindow__image {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.c-infowindow__details {
	height: 175px;
	padding: 20px 15px 20px;
	position: relative;

	.c-infowindow__details-tag {
		@include font-base-12b;
		height: 24px;
		border-radius: 12px;
		width: fit-content;
		line-height: 20px;
		color: $white;
		padding: 0 10px 0 5px;
		position: relative;
		margin-bottom: 5px;

		&.Premium {
			background: $premium;
		}

		&.Superior {
			background: $superior;
		}

		&.Classic {
			background: $classic;
		}

		& span {
			display: inline-block;
			vertical-align: sub;
			height: 15px;
			width: 15px;
			margin-top: 4px;
			margin-right: 4px;
		}

		&.small {
			padding: 0 10px;
		}
	}
}

.c-infowindow__image-wrapper + .c-infowindow__details {
	height: 145px;

	.c-infowindow__details-tag {
		position: absolute;
		z-index: 1;
		top: -12px;
		left: 15px;
	}
}

.c-infowindow__details-copy {
	height: 90px;
}

.c-infowindow__heading {
	@include font-base-14b;
	margin-bottom: 5px;
}

.c-infowindow__address,
.c-infowindow__phone {
	@include font-base-12;
	margin-bottom: 5px;
}

.c-infowindow__link {
	color: $blue;
	display: block;

	@include font-base-16b;
}

//======------    No Results    ------======//

.c-park-finder__empty-wrapper {
	max-width: 400px;
	width: 100%;
	margin: 0 auto;
	padding: 30px;
}

.c-park-finder__empty-image {
	height: 128px;
	width: 128px;
	margin: 0 auto;

	img {
		opacity: .1;
	}
}

.c-park-finder__empty-copy {
	margin-top: 20px;
	@include font-heading-24;
	color: $dark-blue;
	text-align: center;
}
