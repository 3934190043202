// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes


/* sofia-pro  -  https://fonts.adobe.com/fonts/sofia  -  used Anne Creative Cloud account - replace with registrations@terabyte account  */

$base-font-family: "sofia-pro", Arial, Helvetica, sans-serif;
$heading-font-family: "sofia-pro", Arial, Helvetica, sans-serif;
$base-font-size: 16px;
$base-line-height: 24px;

$base-font-size-mobile: 14px;
$base-line-height-mobile: 20px;

$weight--light: 300;
$weight--normal: 400;
$weight--semibold: 600;
$weight--bold: 700;

///////////////////////
// Custom @font-face //
//      example      //
///////////////////////

// Montserrat
//
// URL: https://fonts.google.com/specimen/Montserrat
// Transformed: Generated by Font Squirrel (https://www.fontsquirrel.com) on August 6, 2021
//
// Licence: SIL OPEN FONT LICENSE Version 1.1
// Licence URL: /Content/fonts/montserrat/OFL.txt

// @font-face {
// 	font-family: "Montserrat";
// 	src: url("/content/fonts/montserrat/montserrat-regular.eot");
// 	src: url("/content/fonts/montserrat/montserrat-regular.eot?#iefix") format("embedded-opentype"),
// 		url("/content/fonts/montserrat/montserrat-regular.woff2") format("woff2"),
// 		url("/content/fonts/montserrat/montserrat-regular.woff") format("woff"),
// 		url("/content/fonts/montserrat/montserrat-regular.ttf") format("truetype"),
// 		url("/content/fonts/montserrat/montserrat-regular.svg#montserratregular") format("svg");
// 	font-weight: $weight--normal;
// 	font-style: normal;
// 	font-display: auto;
// }

//////////

@mixin font-body-text {
	font-family: $base-font-family;
	font-size: $base-font-size-mobile;
	font-weight: $weight--normal;
	line-height: $base-line-height-mobile;

	@include bp("medium") {
		font-size: $base-font-size;
		line-height: $base-line-height;
	}
}

///////////////////////////
// Heading font variants //
///////////////////////////

@mixin font-heading-72 {
	font-family: $heading-font-family;
	font-size: 72px;
	font-weight: $weight--bold;
	line-height: 1;
}

@mixin font-heading-48 {
	font-family: $heading-font-family;
	font-size: 48px;
	font-weight: $weight--bold;
	line-height: 58px;
}

@mixin font-heading-40 {
	font-family: $heading-font-family;
	font-size: 40px;
	font-weight: $weight--bold;
	line-height: 48px;
}

@mixin font-heading-32 {
	font-family: $heading-font-family;
	font-size: 32px;
	font-weight: $weight--bold;
	line-height: 38px;
}

@mixin font-heading-24 {
	font-family: $heading-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 29px;
}

@mixin font-heading-20 {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: 26px;
}

////////////////////////
// Base font variants //
////////////////////////

@mixin font-base-18 {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: 28px;
}

@mixin font-base-18b {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 28px;
}

@mixin font-base-16 {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: 24px;
}

@mixin font-base-16b {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: 24px;
}

@mixin font-base-14 {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 20px;
}

@mixin font-base-14b {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 20px;
}

@mixin font-base-12 {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: 16px;
}

@mixin font-base-12b {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: 16px;
}

html {
	font-family: $base-font-family;
	color: $body-color;
	font-size: $base-font-size-mobile;
	font-weight: $weight--normal;
	line-height: $base-line-height-mobile;

	@include bp("medium") {
		font-size: $base-font-size;
		line-height: $base-line-height;
	}
}

p {
	margin-top: 0;
}

p {
	a {
		color: $blue;
		@include link-transition;

		&:hover {
			color: $dark-blue;
		}
	}
}
