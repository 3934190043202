﻿.c-popup {
	.c-modal__body {
		border-radius: 0;
		background-size: contain;
		background-position: right;
		padding: 0;
	}
}

.c-popup__close {
	background-color: $brand;
	color: $body-color;
	border-radius: 50%;
	font-size: $ssu;
	top: $ssu;
	right: $ssu;
}

.c-popup__body-wrap {
	background: $white;
	padding: $lsu;

	@include bp('large') {
		max-width: 400px;
	}

	.c-popup--background & {
		@include bp('large') {
			margin-right: 300px;
			box-shadow: rgba($black, .25) 0 0 15px;
		}
	}
}

.c-popup__body {
	width: 100%;

	&[aria-hidden=true] {
		display: none;
	}
}

.c-popup__logo-img {
	display: block;
	margin-bottom: $bsu;
	max-width: 150px;

	@include bp('large') {
		margin-bottom: $lsu;
	}
}

.c-popup__heading {
	@include bp('large') {
		margin-bottom: ($bsu * 1.5);
	}
}


.c-popup__subheading {
	margin: 0 0 $bsu;

	@include bp('large') {
		margin-bottom: ($bsu * 1.5);
	}
}


.c-popup__text {
	margin: 0 0 $bsu;

	&.o-body-text {
		@include bp('large') {
		}

		a:hover {
			color: $white;
		}
	}
}

.c-popup__cta {
	@include button;
	background-color: $brand;
	border: none;
	padding: ($ssu * 1.5) $bsu !important;
}

.c-popup__promo {
	display: block;
	padding: $ssu $bsu;
	border-radius: 2px;
	text-align: center;
	background: $brand;
}



// Promo popup (with countdown)
.c-popup--promo {
	.c-modal__body {
		border-radius: $radius;
		width: 90%;
		max-width: 500px;
		background-color: $dark-blue !important;
		background-repeat: no-repeat;
		background-size: contain;

		@include bp('large') {
			width: 100%;
			max-width: 800px;
		}
	}

	.c-popup__body-wrap {
		background: $dark-blue;
		color: $white;
		text-align: center;
		max-width: 500px;
		margin-right: 0;

		@include bp('large') {
			width: 500px;
			box-shadow: none;
		}
	}

	.c-popup__close {
		@include button;
		@include btn-primary-outline;
		@include button-icon-size('smallest');
		justify-content: center;
		align-items: center;
	}

	.c-popup__heading {
		@include font-heading-40;
		color: $blue--light;
	}

	.c-popup__cta {
		@include button;
		@include btn-primary;
		@include button-size('large');
		width: 50%;
		font-weight: $weight--bold;
		margin-top: $bsu;
	}

	.countdown {
		display: flex;
		align-items: center;

		.countdown-item {
			flex: 1 1 30%;
		}

		.countdown-item--days {
		}

		.countdown-item--hours {
		}

		.countdown-item--minutes {
		}

		.countdown__value {
			font-size: 300%;
			line-height: 1.5;
			color: $green;
			font-weight: $weight--bold;

			@include bp('small') {
				font-size: 400%;
			}
		}

		.countdown__text {
			font-size: 12px;
		}
	}
}
