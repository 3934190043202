﻿// component.park-features

.c-park-features {
	@include control;
}

.c-park-features__container {
	max-width: 860px;
}

.c-park-features__features-heading {
	@include yellow-line-heading;
	color: $brand--alt;
}

.c-park-features__features-list {
	margin-bottom: $bsu;
	padding-bottom: $bsu;

	&:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
	}

	@include bp-below("medium") {
		border-bottom: none;
		margin-bottom: 0;
		padding-bottom: 0;
	}
}

.c-park-features__features-item-heading {
	@include font-base-16b;
	@include flex-extend;
	flex-direction: row;
	gap: ($ssu * 1.5);
	width: 50%;
	flex: 50%;
	margin-bottom: $bsu;

	@include bp("medium") {
		@include font-base-18b;
	}
}

.c-park-features__features-heading-icon {
	color: $primary-a;
	color: var(--primary-a);
	font-size: 25px;
	height: 25px;
	width: 25px;
}

.c-park-features__features-list-items {
	display: flex;
	flex-wrap: wrap;
}

.c-park-features__features-item {
	@include font-base-16;
	@include flex-extend;
	flex-direction: row;
	gap: $ssu;
	color: $secondary-a;
	color: var(--secondary-a);
	margin-bottom: $ssu;
	align-items: center;

	@include bp-below("medium") {
		flex: 0 0 100%;

		&:nth-child(n+7) {
			display: none;

			.c-park-features__features-list[aria-expanded="true"] & {
				display: flex;
			}
		}
	}

	@include bp("medium") {
		flex: 0 0 50%;

		&:nth-child(n+13) {
			display: none;

			.c-park-features__features-list[aria-expanded="true"] & {
				display: flex;
			}
		}
	}
}

.c-park-features__features-item-icon {
	@include flex-extend;
	@include circle(20px);
	@include ui-border("all");
	justify-content: center;
	align-items: center;
	border-color: $primary-a;
	border-color: var(--primary-a);
	color: $primary-a;
	color: var(--primary-a);
	margin-top: 3px;
}

.c-park-features__features-toggle-wrap {
	display: block;
	margin-top: $bsu;

	&.c-park-features__features-toggle__hidden {
		@include bp("medium") {
			display: none;
		}
	}
}

.c-park-features__features-toggle {
	@include button-reset;
	color: $primary-a;
	color: var(--primary-a);

	

	&[aria-expanded="true"] {
		.c-park-features__features-toggle-open {
			display: none;
		}

		.c-park-features__features-toggle-close {
			display: flex;
		}
	}
}

.c-park-features__features-toggle-open {
	display: flex;
	align-items: center;
	gap: $tsu;

	&::before {
		@include font-icon;
		content: $iconf-plus;
		font-size: 15px;
	}
}

.c-park-features__features-toggle-close {
	display: none;
	align-items: center;
	gap: $tsu;

	&::before {
		@include font-icon;
		content: $iconf-minus;
		margin-top: 3px;
	}
}
