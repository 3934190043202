﻿
.c-checkout__top-form {
	padding: 0px 0 40px;
}

.c-checkout__form-grid {

	@include bp("large") {
		display: grid;
		grid-template-columns: 1fr 390px;
		grid-template-areas: "fields summary" "actions summary";
		grid-column-gap: $hsu;
	}
}

.c-checkout__form-grid-fields {

	@include bp("large") {
		grid-area: fields;
	}
}

.c-checkout__form-grid-actions {

	@include bp("large") {
		grid-area: actions;
	}
}

.c-checkout__terms,
.c-checkout__form-grid-summary,
.c-checkout__form-grid-actions {
	padding: 40px 0 0;
	margin-top: $lsu;
	border-top: 1px solid $grey--light;
}

.c-checkout__form-grid-summary {

	@include bp("large") {
		grid-area: summary;
		padding: 0;
		border: none;
		margin: 0;
	}
}

.c-checkout__header {
	@include yellow-line-heading;
	@include font-heading-32;
	color: $dark-blue;
}

.c-checkout__terms-links {
	display: block;

	a {
		display: block;
		color: $blue;
		@include link-transition;
		margin-bottom: 10px;

		&:hover {
			color: $dark-blue;
		}
	}
}

.c-checkout__checkbox {
	margin-top: 40px;

	label {
		display: flex;
		gap: $ssu;
		align-items: center;
	}

	input[type=checkbox] {
		flex: 0 0 auto;
	}
}

.c-checkout__payment {
	h3 {
		margin-bottom: 20px;
	}

	.c-checkout__payment-section {
		margin-bottom: 40px;
	}
}

.c-checkout__voucher {
	max-width: 500px;
	display: flex;

	input {
		margin-right: 20px;
	}

	button {
		@include button-size("small");
		@include btn-primary;
	}
}

.c-checkout__voucher-redeem {
	display: flex;
	flex-direction: column;
	margin-top: 10px;

	@include bp(520px) {
		flex-direction: row;
	}

	> div {
		display: flex;
	}

	.c-checkout__voucher-value {
		font-weight: bold;
	}
}

.c-checkout__payment-links {
	display: inline-block;

	@include bp("medium") {
		display: flex;
		flex-wrap: wrap;
	}
}

.c-checkout__payment-button {
	width: 240px;
	height: 140px;
	padding: 10px;
	margin-right: 20px;
	margin-bottom: 20px;
	display: block;
	text-align: center;
	border: 1px solid $grey--light;

	@include radius;
	@include link-transition;

	&:hover {
		border: 1px solid $blue;
		cursor: pointer;
	}

	.image {
		height: 50px;
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		img {
			display: block;
			margin: 0 auto;
			vertical-align: central;
		}
	}

	span {
		display: block;
		text-align: center;

		@include font-base-14b;
	}
}

.c-checkout__payment-radio {
	visibility: hidden;
	display: none;

	&:checked {
		visibility: visible;
		z-index: 2;
		position: relative;

		& ~ .c-checkout__payment-button {
			border: 1px solid $blue;
		}
	}
}

.c-checkout__button {
	@include button-size("medium");
	@include btn-primary;
	display: block;

	&.loading {
		display: none;
	}
}

.c-checkout__backToCart {
	display: none;

	.c-checkout__backToCart-wrapper {
		display: flex;
		align-items: center;

		span {
			display: block;
			color: $red;
			font-size: 30px;
			margin-right: 10px;
		}

		a {
			color: $black;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.c-checkout__backToCart + .loading {
	display: none;
}

.c-form__netroomz {
	display: none;
	opacity: 0;
	visibility: hidden;
}
