﻿@mixin slider {
	overflow: hidden;
	width: auto;
	max-width: 100vw;
}

@mixin slider__container {
	overflow: visible;
	padding-bottom: 30px;
}

@mixin slider__item {
	width: fit-content;

	&:hover {
		cursor: grab;
	}

	&:active {
		cursor: grabbing;
	}
}