$layout-page-bp: "large";
$layout-aside-width--membership: 280px;

$layout-page-gutter: calc(max(0px, (var(--page-width) - #{$layout-wrapper-max-width}) / 2));
$layout-page-aside-column-width: calc(#{$layout-aside-gutter-width} + #{$layout-aside-width--membership} + #{$layout-page-gutter} + #{$bsu});

// Fallbacks first
// Be sure to override within @supports block if necessary

.l-page__sidebar {
	.l-member-page & {
		width: $layout-aside-width--membership;
	}
}

.l-page__footer-controls {
	margin-top: var(--control-gap);

	@include bp-below($layout-page-bp) {
		.l-page__sidebar + & {
			margin-top: 0;
		}
	}
}


@supports (display: grid) {

	.c-member-page-header {
		grid-area: header;
	}

	.l-page__sidebar {

		@include bp($layout-page-bp) {

			.l-member-page & {
				width: $layout-aside-width--membership;
			}
		}

		.l-member-page & {
			width: auto;

			@include bp-below("medium") {
				border-top: 1px solid $grey;
			}
		}
	}

	.l-member-page {
		&.l-member-page--has-sidebar {
			display: grid;
			grid-template-areas:
				"header" "main-controls" "footer-controls" "sidebar" "footer";

			@include bp($layout-page-bp) {
				grid-template-columns: 1fr;
				grid-template-columns: 1fr $layout-page-aside-column-width;
				grid-template-areas:
					"header header" "main-controls sidebar" "footer-controls footer-controls" "footer footer";
			}

			.l-page__main-controls {

				@include bp($layout-wrapper-max-width) {
					margin-left: $layout-page-gutter;
					--layout-gutter-right: 0;
				}
			}
		}
	}
}
