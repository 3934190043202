$modal-full-close-pos: $layout-wrapper-modal-width + 200px;

@use "sass:math";

.c-modal {
	display: none;

	&[aria-hidden="false"] {
		@include flex-extend;
		align-items: center;
		justify-content: center;
	}

	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($black, 0.6);
}

.c-modal--full {
	overflow: auto;

	.c-modal__body {
		max-width: $layout-wrapper-modal-width;
		max-height: none;
		width: 100%;
		top: 0;
		height: auto !important;
		// 45px is the close button height plus its 1px offset
		padding: #{45px + $ssu} 0 $bsu;
		box-shadow: none;
		overflow: visible;
	}

	.c-modal__close {
		right: 1px;

		@include bp($modal-full-close-pos) {
			right: 50%;
			transform: translateX(#{math.div($modal-full-close-pos, 2)});
		}
	}

	background: $white;
}

.c-modal__body-open {
	// To be placed on the <body> tag when a modal is open
	// Uncomment this rule to prevent background scrolling
	// while a modal window is open:
	// overflow: hidden;
}

.c-modal__body-open--full {
	overflow: hidden;

	.c-header {
		display: none;
	}
}

.c-modal__body {
	@include radius;
	position: relative;
	overflow: auto;
	padding: 20px;
	width: 100%;
	max-height: 100%;
	max-width: calc(100% - 40px);

	@include bp("medium") {
		width: auto;
		max-width: 80%;
		height: auto;
		max-height: 80%;
		padding: $bsu;
	}

	background: $white;
	box-shadow: 0 0 20px rgba($black, 0.4);

	.c-form {
		margin-bottom: 20px;
	}

	.c-button-wrap {
		flex-direction: row;
		gap: 10px;
	}
}

.c-modal--feature {
	.c-modal__body {
		background: $white;
		padding: 0;
		width: 100%;
		max-width: none;
		height: 100%;
		max-height: none;

		@include bp("extra-large") {
			width: auto;
			max-width: 80%;
			height: auto;
			max-height: 80%;
		}
	}
}

.c-modal__close {
	position: absolute;
	// Offset by 1px so focus style isn't cut off
	top: 1px;
	right: 1px;

	@include button-reset;

	@include flex-extend;
	padding: 2px;
	font-size: 30px;
	line-height: 1;
	z-index: 2;

	&::before {
		@include font-icon;
		content: $iconf-close;
	}
}

.c-modal__dummy {
	position: absolute;
	clip: rect(0 0 0 0);
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
}

.c-modal__image {
	// For use within .c-modal--full
	display: block;
	max-width: 100%;
	height: calc(100vh - 88px);

	@include bp("medium") {
		height: calc(100vh - #{45px + $ssu + $bsu});
	}

	padding: 0 !important;
	background: none !important;

	> img {
		object-fit: contain;
	}
}

.c-modal-trigger {
	cursor: pointer;
}
