// CONTROL.EXPLORE-PODS

@use "sass:math";

@mixin explore-pods-appear {
	@media screen and (prefers-reduced-motion: no-preference) {
		.c-explore-pods__pod,
		.c-explore-pods__cta {
			@content;
			opacity: 0;
			@include link-transition(all, 0.5s);
		}

		&[data-scroll-appear="true"] {
			.c-explore-pods__pod,
			.c-explore-pods__cta {
				opacity: 1;
				transform: none;
			}
		}
	}
}

.c-explore-pods {
	@include slider;
	@include control;

	&.c-explore--blue {
		margin: 0;
		padding: $lsu 0;
		background: $blue--lightest;

		@include bp('large') {
			padding: $lsu + $bsu 0;
		}
	}

	&.swiper-scrollbar {
		display: none;
	}
}

.c-explore-pods__layout {
	@include layout-wrapper;
}

.c-explore-pods__head {
	display: block;
	margin-bottom: $bsu;

	@include bp("large") {
		display: flex;
		margin-bottom: 0;
	}

	.c-explore-pods__heading {
		@include yellow-line-heading;
		color: $dark-blue;
	}

	.c-explore-pods__links {
		padding-top: 0;
		margin-left: 0;

		.c-explore-pods__link {
			@include font-base-14;
			margin-right: $lsu;
			color: $blue;
			display: block;

			@include link-transition;

			&:hover {
				color: $dark-blue;
			}

			@include bp("medium") {
				display: inline-block;
			}
		}

		@include bp("large") {
			margin-left: $lsu;
			margin-bottom: 0;
			padding-top: $bsu;
		}
	}
}

.c-explore-slider__container {
	@include slider__container;
	max-width: calc(100vw - $lsu);

	.l-page--has-sidebar .l-page__main-controls & {
		@include bp("medium") {
			max-width: ($layout-wrapper-narrow-width - $lsu);
		}
	}
}

.c-explore-slider__item {
	@include slider__item;
}

.c-explore-pods__introduction-text {
	@include body-text;
	max-width: 1000px;
	margin-bottom: $bsu;
}

.c-explore-pods__item {

	@include explore-pods-appear {
		transform: translateY(100px);
	}

	.c-explore-pods__pod {
		display: block;
		position: relative;
		width: 280px;
		height: auto;
		margin: 0 $ssu 0 0;
		overflow: hidden;

		@include bp("medium") {
			width: 320px;
		}

		&:hover,
		&:focus {
			.c-explore-pods__pod-image-wrapper {
				@include box-shadow;
			}

			.c-explore-pods__pod-image {
				transform: scale(1.1);
			}

			.c-explore-pods__pod-title-text::after {
				opacity: 1;
			}
		}

		.c-explore-pods__pod-image-wrapper {
			@include radius;
			height: 340px;

			@include bp("medium") {
				height: 400px;
			}
		}
	}

	&.c-explore--small {
		.c-explore-pods__pod {
			width: 210px;
			height: auto;

			.c-explore-pods__pod-image-wrapper {
				@include radius;
				height: 155px;
			}
		}
	}
}

.c-explore-pods__pod-image {
	display: block;
	object-fit: cover;
	height: -webkit-fill-available;
	height: -moz-available;
	position: relative;
	overflow: hidden;
	z-index: 1;

	@include link-transition(transform);
}

.c-explore-pods__pod-title {
	margin: $ssu;
	color: $grey--darkest;
	@include link-transition;

	@include font-base-16;

	@include bp("medium") {
		@include font-heading-20;
	}
}

.c-explore--small {
	.c-explore-pods__pod-title {
		@include font-base-16;
	}
}

.c-explore-pods__pod:hover {
	.c-explore-pods__pod-title {
		color: $dark-blue;
	}
}
