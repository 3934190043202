﻿
//Jumplink
.c-park-details__jumplink {
	margin-bottom: $lsu;

	@include bp('large') {
		margin-bottom: $lsu * 1.5;
	}
}

.c-park-details__jumplink-bar {
	position: static;
	height: 90px;
	padding: 15px;
	background: $dark-blue;

	&.stick {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 3;

		.is-sticky & {
			top: 72px;
		}
	}
}

.is-sticky ~ .l-page--park-details {
	.c-park-details__jumplink-bar.stick {
		top: 60px;
		transition: top 0.3s;

		@include bp("large") {
			top: 72px;
		}
	}
}

.is-sticky.is-up ~ .l-page--park-details {
	.c-park-details__jumplink-bar.stick {
		top: 60px;

		@include bp("large") {
			top: 0px;
		}
	}
}

.c-park-details__jumplink-bar-wrapper {
	display: flex;
	justify-content: left;
	overflow: auto;
	white-space: nowrap;
	width: calc(100vw - 20px);
	padding: 15px 0;

	@include thin-scrollbar("light");

	@include bp("large") {
		width: 100%;
		justify-content: center;
	}
}

.c-park-details__jumplink-bar-link {
	color: $white;
	padding: 0 $bsu;

	&:hover {
		@include link-transition;
		color: $blue;
	}
}

//Side bar
.c-park-details__sidebar-links {
	margin-bottom: 40px;
	padding-bottom: 20px;
	border-bottom: 1px solid $grey--light;

	a {
		display: block;
		@include font-base-18;
		@include link-transition;
		color: $blue;
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		span {
			padding-top: 1px;
			margin-right: 10px;
		}

		&:hover {
			color: $dark-blue;
		}
	}
}

.c-hero__main-text {
	@include font-base-18b;
	color: $grey--darkest;
	margin-bottom: 20px;
}

//Virtual Tour
.c-park-details__virtual-tour {
	height:100%;
	width: 100vw;
	max-width: 100%;

	@include bp("medium") {
		height: 600px;
	}

	iframe {
		height: 100%;
	}
}

//Sidebar Social

.c-park-details__sidebar-social {
	@include list-reset;
	@include flex-extend;
	@include flex-wrap;
	margin: -#{$ssu} 0 0 -#{$ssu};

	li {
		padding: $ssu 0 0 $ssu;

		a {
			@include icon-wrapper;
			@include link-transition;
			@include circle($share-icon-size);
			display: block;
			font-size: $share-icon-font-size;
			vertical-align: middle;

			@include btn-secondary;
		}
	}
}