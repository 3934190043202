﻿.c-park-locations {
	background: $dark-blue;
	position: relative;
	overflow: hidden;

	@include bp("large") {
		min-height: 750px;
		background: #9fd7f6;
	}

	.c-park-locations__map-wrapper {
		display: none;

		@include bp("large") {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			right: 0;
			width: calc(100% - 550px);
		}

		@include bp("extra-large") {
			width: 60%;
		}
	}
}

.c-park-locations__container {
	display: flex;
	height: 100%;

	.c-park-locations__circles {
		display: none;

		@include bp("large") {
			display: block;
			position: absolute;
			width: 700px;
			left: 0;
			top: 0;
			bottom: 0;

			&:before {
				overflow: hidden;
				position: absolute;
				content: '';
				background: rgba(255,255,255,0.2);
				top: 0;
				left: 0;
				height: 100%;
				width: 600px;
				border-radius: 0%;
			}

			&:after {
				overflow: hidden;
				position: absolute;
				content: '';
				background: $dark-blue;
				top: 0;
				left: 0;
				height: 100%;
				width: 550px;
				border-radius: 0%;
			}
		}

		@include bp("extra-large") {
			width: 50%;

			&:before {
				top: -50%;
				right: 0;
				left: auto;
				height: 2000px;
				width: 2000px;
				border-radius: 50%;
			}

			&:after {
				top: -45%;
				right: 50px;
				left: auto;
				height: 1900px;
				width: 1900px;
				border-radius: 50%;
			}
		}
	}
}

.c-park-locations__header-wrapper {
	width: 100%;
	height: 100%;
	padding: $lsu 0;
	position: relative;
	z-index: 1;

	@include bp("large") {
		padding: ($lsu * 3) 0 ($lsu * 2);
		max-width: 500px;
	}

	.c-park-locations__introduction {
		color: $white;
		margin-bottom: $bsu;

		@include bp("medium") {
			margin-bottom: $lsu + $bsu;
		}

		a {
			color: $yellow;

			&:hover {
				@include link-transition;
				color: $blue--light;
				cursor: pointer;
			}
		}
	}

	.c-park-locations__links {
		display: flex;
		flex-wrap: wrap;

		.c-park-locations__links-item {
			width: 50%;

			&:nth-child(odd) {
				padding-right: $bsu;
			}

			&:nth-child(even) {
				padding-left: $bsu;
			}

			.c-park-locations__links-link {
				@include font-base-14;
				@include link-transition;
				display: block;
				padding: $ssu 0;
				border-bottom: 1px solid $dark-blue--dark;
				color: $white;

				@include underline-animate;
				width: 100%;

				&:hover {
					cursor: pointer;
				}

				@include bp("medium") {
					border-bottom: 1px solid $dark-blue--light;
				}
			}
		}
	}
}

.c-park-locations__header {
	display: block;

	@include bp("large") {
		display: flex;
	}

	.c-park-locations__header-heading {
		@include yellow-line-heading;
		color: $white;

		@include bp("large") {
			max-width: 300px;
		}
	}

	.c-park-locations__header-links {
		height: $lsu;
		padding-top: 0;
		margin-left: 0;
		display: flex;

		.c-park-locations__header-link {
			@include font-base-14;
			margin-right: $bsu + $ssu;
			color: $yellow;
			white-space: nowrap;

			@include link-transition;
			@include underline-animate;

			&:hover {
				cursor: pointer;
			}
		}

		@include bp("large") {
			margin-left: $bsu + $ssu;
			padding-top: $bsu;
		}
	}
}