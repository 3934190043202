// COMPONENT.RELATED-ITEMS

$related-icon-size: 44px;

.c-related-items {
	@include control;
}

.c-related-items__layout {
	@include layout-wrapper;
}

.c-related-items__heading {
	@include control__heading;
}

.c-related-items__intro {
	@include body-text;
	margin-bottom: $bsu;

	p {
		@include font-body-text;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-related-items__list {
	@include list-reset;
	@include list-stacked;
}

.c-related-item {
	margin-bottom: $bsu;
}

.c-related-item__link {
	@include link-transition;
	display: grid;
	grid-template-areas: 'icon text';
	grid-template-columns: $related-icon-size 1fr;
	grid-column-gap: $bsu;
	position: relative;

	&.has-meta {
		grid-template-areas: 'icon text' 'icon meta';
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		.c-related-item__text {
			text-decoration: underline;
			text-decoration-color: $yellow;
			text-decoration-thickness: 2px;
			text-underline-offset: 3px;
		}
	}
}

.c-related-item__text {
	@include font-base-16b;
	grid-area: text;
	align-self: center;
	color: $brand;

	@include bp("medium") {
		@include font-base-18b;
	}
}

.c-related-item__icon {
	@include icon-wrapper;
	@include circle($related-icon-size);
	grid-area: icon;
	align-self: center;
	font-size: 22px;
	background-color: $yellow;
	color: $brand--alt;
}

.c-related-item__meta {
	@include font-base-14;
	grid-area: meta;
	display: block;
	color: $body-color;
}
