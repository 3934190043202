﻿// CONTROL.ALERT

.c-alert {
	.c-modal__editcart &{
		margin: $bsu auto;
		text-align: right;
	}
}

.c-alert__text {
	@include font-base-18;
	&.success {
		color: $success;
	}

	&.error {
		color: $red;
	}

	&.info,
	&.warning {
		color: $yellow;
	}
}