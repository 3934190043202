﻿// CONTROL.VIDEO-GROUP

@use "sass:math";

.c-videogroup {
    @include control;
}

.c-videogroup__layout {
    @include layout-wrapper;
}

.c-videogroup__heading {
    @include yellow-line-heading;
    color: $brand--alt;
}

.c-videogroup__list {
    display: grid;
    column-gap: $bsu;
    grid-template-columns: repeat(1, 1fr);

    @include bp("medium") {
        grid-template-columns: repeat(3, 1fr);
    }
}

.c-videogroup__cover {
    @include abs-center;
    width: 100%;
}

.c-video__icon {
    .c-videogroup & {
        @include playbutton("small");
    }
}

.c-videogroup__item-heading {
    @include font-base-16b;
    margin-top: 15px;
    color: $secondary-a;
    color: var(--secondary-a);
    transition: 0.3s ease-in all;
}

.c-videogroup__item {
    display: block;
    position: relative;
    overflow: hidden;

    &:hover,
    &:focus {

        .c-video-group__item-heading {
            color: $brand--alt;
        }
    }
}

.c-videogroup__thumbnail {
    padding-bottom: math.div(9, 16);

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}


.c-videogroup__item-icon {
    @include circle(50px);
    position: absolute;
    bottom: $bsu;
    right: $bsu;
    background: $brand;
    box-shadow: 0 0 5px 5px rgba($black, 0.15);
    color: $white;
    font-size: 30px;
    pointer-events: none;
}


.c-video__layout {

    .c-videogroup & {
        width: 100%;
        padding: 0;
    }
}

.c-video__heading {

    .c-videogroup & {
        @include font-base-16b;
        margin-top: $bsu;
        color: $secondary-a;
        color: var(--secondary-a);
    }
}


@include bp(large) {

    .c-videogroup {
        & + .c-videogroup {
            margin-top: 80px;
        }
    }
}
