﻿.c-accomodation-search__expander {
	width: 100%;

	&[aria-expanded="true"] {

		.c-expand-collapse__body {
			display: block;
		}

		.c-accomodation-search__expander-open {
			display: none;
		}

		.c-accomodation-search__expander-close {
			display: block;
		}
	}

	&[aria-expanded="false"] {

		.c-expand-collapse__body {
			display: none;
		}

		.c-accomodation-search__expander-open {
			display: block;
		}

		.c-accomodation-search__expander-close {
			display: none;
		}
	}
}

.c-accomodation-search__expander-trigger {
	@include font-base-14b;
	@include link-transition;
	color: $blue;
	text-align: center;
	padding: 10px;
	cursor: pointer;

	&:hover {
		color: $dark-blue;
	}

	span {
		color: $dark-blue;
		font-size: 18px;
		display: inline-block;
		vertical-align: bottom;
	}
}

.c-accomodation-search__submit {
	@include button("small");
	@include btn-primary;
	width: inherit;
	white-space: nowrap;

	@include bp("small") {
		width: auto;
	}
}
