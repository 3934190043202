﻿// MACRO.BLOCKQUOTE

.m-quote {
	@include body-text__figure;
}

.m-quote__text {
	@include font-base-16b;

	@include bp("medium") {
		@include font-heading-20;
	}

	@include bp("large") {
		@include font-heading-24;
	}

	&::before,
	&::after {
		content: '"';
	}
}

.m-quote__citation {
	@include font-base-16;
	color: $body-color;
	font-style: normal;
}
