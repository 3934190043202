@mixin compendium-yellow-line-heading {
	@include font-heading-20;
	position: relative;
	margin-bottom: 30px;

	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 100px;
		height: 2px;
		background: $yellow;
	}

	@include bp("medium") {
		@include font-heading-20;
		margin-bottom: 40px;
	}

	@include bp("large") {
		@include font-heading-20;
	}
}

.c-compendium__head {
	background: #f1f1f1;
}

.c-compendium {
	max-width: 600px;
	min-width: 340px;
	margin: 0 auto;
	position: relative;
	background: white;
}

.o-layout-compendium {
	max-width: 600px;
	margin: 0 auto;
	position: relative;
	padding-bottom: 65px;

	.l-page {
		padding-top: 60px !important;

		.c-rte {
			h2 {
				@include compendium-yellow-line-heading;
			}

			h3 {
				@include font-base-18b;
			}
		}
	}
}

/*====----  Header  ----====*/

.c-compendium__header {
	max-width: 600px;
	z-index: $z-header;
	height: 60px;
	width: 100%;
	background-color: $dark-blue;
	box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	transition: all 0.25s ease-in-out;

	&.is-sticky.is-up {
		transform: translateY(calc(-100% - 6px));
	}

	.c-header__wrapper {
		width: 100%;

		.c-header__brand {
			width: 100%;
			display: flex;

			.c-header__nav-toggle {
				height: 60px;
				width: 60px;
				flex: none;
			}

			.c-header__logo {
				height: 60px;
				padding: 15px;
				width: calc(100vw - 60px);
				display: flex;
				align-items: flex-end;

				.c-header__logo-text {
					color: $white;
					font-size: 14px;
					line-height: 18px;
					font-weight: 800;
					text-align: left;
					margin-left: 10px;
					max-width: 110px;

					@include bp-below(360px) {
						font-size: 12px;
						line-height: 14px;
					}
				}
			}
		}

		.c-hamnav__wrapper {
			top: 60px;
		}
	}
}

/*====----  Slider  ----====*/

.c-compendium__slider {
	max-width: 100vw;
	height: 240px;
	padding-left: 20px;
}

.c-compendium__container {
	max-width: 100vw;
	height: 240px;

	&::part(scrollbar) {
		.swiper-scrollbar-drag {
			background: $blue--lightest;
		}
	}
}

.c-compendium__slide {
	width: 310px;
	height: 220px;
	padding-right: 20px;
}

.c-compendium__item {
	display: block;
	position: relative;
	width: 310px;
	height: 220px;
	overflow: hidden;
}

.c-compendium__item-image-wrapper {
	height: 180px;
	overflow: hidden;
	border-radius: 4px;

	.c-compendium__item-image {
		padding: 0px;
		display: block;
		height: 100%;
		width: 100%;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
}

.c-compendium__item-title {
	font-size: 16px;
	line-height: 20px;
	color: black;
	font-weight: 800;
	margin-top: 10px;
}

/*====----  Hero  ----====*/

.c-compendium__back {
	color: $blue;
	display: flex;
	align-items: center;
	margin: 10px;
	@include link-transition;

	span {
		display: block;
		margin-right: 5px;
	}

	&:hover {
		color: $dark-blue;
		@include link-transition;
	}
}

.c-compendium__hero {
	position: relative;
	width: 100%;
	overflow: hidden;
	background: $blue--lightest;

	&.c-compendium__promotions {
		padding: 40px 0px;

		.c-compendium__hero-title {
			font-size: 20px;
			line-height: 26px;
			font-weight: 800;
			color: $dark-blue;
			text-align: center;
			margin-bottom: 20px;
			padding: 0 40px;
		}
	}
}

.c-compendium__hero-image {
	height: 200px;
	width: 100%;
	overflow: hidden;
	position: relative;
	padding: 0px !important;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.c-compendium__hero-content {
	padding: 40px 20px;
	text-align: center;

	h2 {
		color: $dark-blue;
		font-size: 20px;
		line-height: 26px;
		font-weight: 800;
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 0;
	}
}

/*====----  Footer FAQ  ----====*/

.c-compendium {
	.c-faq {
		margin: 40px 0;

		.c-faq__item:last-child {
			border-bottom: 0px none;
		}

		.c-faq__item-header {
			padding: 10px 0px 15px 40px;

			.c-faq__item-heading {
				font-size: 16px;
				line-height: 24px;
				font-weight: 800;
			}

			.c-faq__item-icon {
				left: 0px;
			}
		}

		.c-faq__item-body {
			padding: 0 0 20px 40px;

			.c-faq__item-text > p {
				font-size: 14px;
				line-height: 24px;
			}
		}
	}
}

/*====----  Footer Nav  ----====*/

.c-compendium__footer-backtotop {
	.c-footer__back-to-top {
		bottom: 150px;
		font-size: 22px;
		right: 20px;
	}
}

.c-compendium__footer-nav {
	position: fixed;
	bottom: 0;
	height: 65px;
	max-width: 600px;
	width: 100%;
	background: white;
	display: flex;
	box-shadow: 0px -2px 11px rgba(#888, 0.3);

	.c-compendium__footer-item {
		width: 20%;
		text-align: center;
		color: $blue;
		font-size: 10px;
		font-weight: 800;

		span {
			display: block;
			text-align: center;
			font-size: 20px;
		}

		.c-compendium__footer-spacer {
			width: 100%;
			height: 5px;
			margin-bottom: 10px;
		}

		&.active {
			color: $dark-blue;

			.c-compendium__footer-spacer {
				background: $yellow;
				border-radius: 0 0 5px 5px;
			}
		}

		&:hover {
			color: $dark-blue;
		}
	}
}

/*====----  Footer  ----====*/

.c-compendium__footer {
	background: $dark-blue;
	padding: 20px;

	.c-footer__social {
		width: 100%;
		justify-content: center;

		.c-footer__social-list {
			gap: 25px;

			.c-footer__social-link {
				font-size: 25px;
				color: $blue--accent;

				&:hover {
					color: $yellow;
				}
			}
		}
	}

	.c-footer__legal {
		width: 100%;
		justify-content: center;
		margin-top: 10px;
		flex-direction: row;
		align-items: baseline;
	}
}
