﻿// COMPONENT.HAMNAV
.c-hamnav__wrapper {
	position: absolute;
	z-index: 2;
	top: #{$header-height - 12};
	left: 0;
	overflow: hidden;

	@include bp-below("medium") {
		margin: 0;
		padding: 0;
		width: 100%;
		height: calc(100vh + 12px - #{$header-height});
	}

	@include bp("medium") {
		width: 380px;
		height: calc(100vh + 12px - #{$header-height});
		max-height: calc(100vh + 12px - #{$header-height});
	}

	@include bp("large") {
		width: 380px;
		top: #{$header-height};
	}

	visibility: hidden;

	&[aria-expanded="true"] {
		animation: 0.5s becomeVisible forwards;

		.c-hamnav {
			animation: 0.5s slideinLeft forwards, 0.5s fadein forwards, 0.5s becomeVisible forwards;
		}
	}

	&[aria-expanded="false"] {
		animation: 0.5s becomeHidden forwards;

		.c-hamnav {
			animation: 0.5s slideoutLeft forwards, 0.5s fadeout forwards, 0.5s becomeHidden forwards;
		}
	}
}

.c-hamnav {
	width: 100%;
	height: 100%;
	visibility: hidden;
	background: $dark-blue--dark;
	overflow: auto;
}

.c-hamnav__sections {
	@include list-reset;
	padding: $bsu;
}

.c-hamnav__section {
	border-bottom: 1px solid $dark-blue;
}

.c-hamnav__section--has-children {
	.c-hamnav__section-link {
		&::before {
			@include font-icon;
			@include link-transition(transform);
			content: $iconf-plus;
			position: absolute;
			left: 5px;
			top: 13px;
			font-size: 20px;
			color: $blue--light;
		}
	}
}

.c-hamnav__section-link {
	@include link-transition;
	display: inline-block;
	padding: $ssu $bsu $ssu 30px;
	position: relative;
	color: $white;
	font-weight: bold;
	width: 100%;

	&:hover {
		background: $dark-blue--darkest;

		&::before {
			@include link-transition;
			color: $yellow;
		}
	}
}

.c-hamnav__section-link-chinese {
	font-size: 22px;
}

.c-hamnav__children {
	@include list-reset;
	display: none;
	margin-bottom: 10px;
}

.c-hamnav__section[aria-expanded="true"] {
	.c-hamnav__section-link {
		color: $yellow;

		&::before {
			@include link-transition(transform);
			content: $iconf-minus;
			transform: rotate(-180deg);
		}
	}

	.c-hamnav__children {
		display: block;
	}
}

.c-hamnav__child-link {
	@include font-base-14;

	@include bp("medium") {
		@include font-base-16;
	}

	font-weight: 300;
	color: $white;
	padding: 8px 0;
	padding-left: $bsu + $ssu;
	display: block;
	width: 100%;

	&:hover {
		background: $dark-blue--darkest;
	}
}

.c-hamnav__footer {
	background: $grey--lightest;
	padding: $lsu;
}

.c-hamnav__footer-logo {
	display: block;
	width: 160px;
	margin-bottom: $bsu;
}
