// Cart Slideout got items

.c-cart-slideout {
	padding-top: $ssu;
}

.c-cart-slideout__items-wrapper {
	margin-bottom: 20px;
}

.c-cart-slideout__item {
	border-bottom: 1px solid $grey;
	padding: $bsu 5px $bsu $bsu;
	display: flex;
}

.c-cart-slideout__item--membership {
	align-items: center;
}

.c-cart-slideout__item-copy {
	width: calc(100% - 45px);
	padding-right: 10px;

	.c-booking-summary & {
		width: 100%;
		padding-right: 20px;
	}

	.c-cart-slideout__item--membership & {
		display: flex;
		align-items: flex-end;
	}
}

.c-cart-slideout__item-heading {
	@include font-base-18b;
	line-height: 22px;
	color: $dark-blue;
	margin-bottom: 10px;

	.c-cart-slideout__item--membership & {
		width: 60%;
		margin-bottom: 0;
	}
}

.c-cart-slideout__item-location {
	@include font-base-12;
}

.c-cart-slideout__strapline {
	@include font-base-14;
	color: $orange;
	margin-bottom: 15px;
}

.c-cart-slideout__details {
	display: flex;

	.c-cart-slideout__details-amounts,
	.c-cart-slideout__details-costs {
		width: 50%;
	}
}

.c-cart-slideout__details-persons,
.c-cart-slideout__details-dates {
	@include font-base-12b;
}

.c-cart-slideout__details-nights {
	@include font-base-12;
	margin-top: 5px;
	color: $grey--dark;
}

.c-cart-slideout__details-price {
	@include font-base-16b;
	text-align: right;
	margin-top: 15px;

	.c-cart-slideout__item--membership & {
		width: 40%;
		margin-top: 0;
	}
}

.c-cart-slideout__details-save {
	@include font-base-12;
	text-align: right;
	color: $blue;
}

.c-cart-slideout__item-unavailable {
	@include font-base-16b;
	color: $grey;
	margin-top: 20px;
	text-align: center;
}

.c-cart-slideout__item-actions {
	width: 45px;
}

.c-cart-slideout__item-actions-edit,
.c-cart-slideout__item-actions-delete {
	height: 80px;
	width: 45px;
	margin-bottom: 5px;
	text-align: center;
	font-size: 20px;
	padding: 30px 0;
	color: $blue--light ;
	background: $blue--lightest;
	@include link-transition;

	&:hover {
		color: $blue;
		cursor: pointer;
	}
}

.c-cart-slideout__totals {
	background: $grey--lightest;
	padding: 20px 70px;
	margin: 20px 10px;


	.c-booking-summary & {
		padding: 20px 50px;
	}

	.c-cart-slideout__totals-price {
		@include font-base-14;
		clear: both;

		span {
			display: inline-block;
			float: right;
		}
	}

	.c-cart-slideout__totals-total {
		@include font-base-14b;
		clear: both;

		span {
			display: inline-block;
			float: right;
		}

		.c-booking-summary & {
			margin-top: $bsu;
			@include font-base-18b;
		}
	}
}

.c-cart-slideout__checkout-button {
	@include button("small");
	@include btn-primary;
	display: block;
	margin: 25px 25px 15px;
}

.c-cart-slideout__continue-shopping {
	@include button("small");
	@include btn-secondary;
	display: block;
	margin: 15px 25px 40px;
}

.c-cart-clear-side {
	@include font-base-14b;
	@include link-transition;
	color: $dark-blue;
	text-align: center;
	display: block;

	&:hover {
		color: $blue;
		cursor: pointer;
	}
}

// Cart Empty
.c-cart-slideout__empty {
	padding: 40px;

	.c-cart-slideout__empty-icon {
		display: block;
		height: 150px;
		width: 100px;
		margin: 0 auto;
	}

	.c-cart-slideout__empty-text {
		text-align: center;
	}

	.c-cart-slideout__empty-book-button {
		@include button("small");
		@include btn-primary;
		display: block;
		margin: 25px auto;
		max-width: 250px;
	}
}