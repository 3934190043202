﻿.c-room-filter-listing {
	background: $white;
	border: 1px solid $blue;
	@include radius;
}

.c-room-filter-listing__wrapper {
	display: flex;
	flex-direction: row-reverse;

	@include bp("medium") {
		flex-direction: row;
	}
}

.c-room-filter-listing__image-container {
	width: 165px;

	.o-tile__figure {
		height: 110px;
		border-radius: 0px;
	}

	.c-room-filter-listing__link {
		@include button("small");
		@include btn-secondary;
		margin: 10px 15px;
	}
}

.c-room-filter-listing__detail-container {
	width: calc(100% - 165px);
	padding: 20px;
}

.c-room-filter-listing__categories-item {
	@include font-base-16;
	display: flex;
	color: $secondary-a;
	color: var(--secondary-a);
	align-items: center;
	margin-bottom: 5px;
}

.c-room-filter-listing__categories-item-icon {
	display: block;
	font-size: 20px;
	margin-right: 10px;
}
