﻿// CONTROL.COMPENDIUM-LIST

.c-compendium-list {
	margin: 40px 0;
}

.c-compendium-list__layout {
	@include layout-wrapper;
}

.c-compendium-list__heading {
	@include yellow-line-heading;
	color: $dark-blue;
	font-size: 20px !important;
}

.c-compendium-list__item-list {
}

.c-compendium-list__item {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid $grey--light;

	&:first-child {
		border: none;
		margin-top: 0;
		padding-top: 0;
	}

	.c-compendium-list--icons & {
		display: flex;
		gap: 10px;
	}
}

.c-compendium-list__item-icon {
	width: 30px;
	font-size: 24px;
	padding-top: 5px;
	color: $blue;

	+ .c-compendium-list__item-body {
		.c-compendium-list--icons & {
			flex: 0 1 auto;
			width: calc(100% - 40px);
		}
	}
}

.c-compendium-list__item-body {
	.c-compendium-list--icons & {
		flex: 0 1 auto;
		width: 100%;
	}
}

.c-compendium-list__item-heading {
	color: $dark-blue;
	margin-bottom: 10px;
}

.c-compendium-list__item-content {
	display: flex;
	gap: 20px;
	margin-bottom: 10px;
}

.c-compendium-list__item-text {
	flex: 1 1 calc(100% - 120px);

	@include bp(400px) {
		flex: 1 1 calc(100% - 160px);
	}

	@include bp(500px) {
		flex: 1 1 calc(100% - 200px);
	}

	p {
		margin-bottom: 0;
	}
}

.c-compendium-list__item-image {
	width: 120px;

	@include bp(400px) {
		width: 160px;
	}

	@include bp(500px) {
		width: 200px;
	}
}

.c-compendium-list__item-link {
	@include font-base-14;
	margin-right: $lsu;
	color: $blue;
	display: block;

	@include link-transition;

	&:hover {
		color: $dark-blue;
	}
}

.c-compendium-list__item-btn {
	@include button;
	@include btn-primary;
}
