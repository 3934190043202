﻿// COMPONENT.HAMNAV
.c-slideout__wrapper {
	position: absolute;
	z-index: 5;
	top: #{$header-height - 12};
	right: 0;
	overflow: hidden;

	@include bp-below("medium") {
		margin: 0;
		padding: 0;
		width: 100%;
		height: calc(100vh + 12px - #{$header-height});
	}

	@include bp("medium") {
		width: 380px;
		height: calc(100vh + 12px - #{$header-height});
		max-height: calc(100vh + 12px - #{$header-height});
	}

	@include bp("large") {
		width: 380px;
		top: #{$header-height};
	}

	visibility: hidden;

	&[aria-expanded="true"] {
		animation: 0.5s becomeVisible forwards;

		.c-slideout {
			animation: 0.5s slideinRight forwards, 0.5s fadein forwards, 0.5s becomeVisible forwards;
		}
	}

	&[aria-expanded="false"] {
		animation: 0.5s becomeHidden forwards;

		.c-slideout {
			animation: 0.5s slideoutRight forwards, 0.5s fadeout forwards, 0.5s becomeHidden forwards;
		}
	}

	&#header-booknow[aria-expanded="true"] {
		overflow: visible;

		.c-slideout {
			overflow: visible;
		}
	}
}

.c-slideout {
	width: 100%;
	height: 100%;
	visibility: hidden;
	background: $white;
	overflow: auto;
	margin-left: 0px;

	@include bp("medium") {
		margin-left: 5px;
		width: calc(100% - 5px);
	}

	@include box-shadow;
}

.c-slideout__close {
	position: absolute;
	height: 30px;
	width: 30px;
	padding: 5px;
	font-size: 20px;
	font-weight: bold;
	right: 0px;
	top: 0px;
	color: $dark-blue;

	&:hover {
		cursor: pointer;
	}

	&.c-slideout__close-white {
		color: $white;
	}
}

.js-accommodation-search__sidebar .vc-popover-content-wrapper {
	@include bp("medium") {
		right: 0px !important;
		left: auto !important;
	}

	.vc-popover-caret {
		@include bp("medium") {
			right: 30px !important;
			left: auto !important;
		}
	}
}