$layout-page-bp: "large";

$layout-page-gutter: calc(max(0px, (var(--page-width) - #{$layout-wrapper-max-width}) / 2));
$layout-page-aside-column-width: calc(#{$layout-aside-gutter-width} + #{$layout-aside-width} + #{$layout-page-gutter} + #{$bsu});
$layout-page-aside-column-width-large: calc(#{$layout-aside-gutter-width} + #{$layout-aside-width-large} + #{$layout-page-gutter} + #{$bsu});

// Fallbacks first
// Be sure to override within @supports block if necessary


.l-page__header {

	&.l-page__header--authenticate,
	.c-room-details & {
		margin-bottom: $bsu;

		@include bp('medium') {
			margin-bottom: $lsu;
		}

		@include bp('large') {
			margin-bottom: $lsu * 1.5;
		}
	}

	&.l-page__header--authenticate p:last-child {
		margin-bottom: 0;
	}
}

.l-page__main-controls {

	.c-room-details & {

		*:first-child {
			margin-top: 0;
		}
	}
}

.l-page__sidebar {
	@include control;
	margin-left: $bsu;
	margin-right: $bsu;
	width: $layout-aside-width;
	--layout-gutter-left: 0;
	--layout-gutter-right: 0;
}

.l-page__footer-controls {
	margin-top: var(--control-gap);

	@include bp-below($layout-page-bp) {
		.l-page__sidebar + & {
			margin-top: 0;
		}
	}
}

@supports (display: grid) {
	.l-page {
		display: grid;
		grid-template-areas:
			"header" "main-controls" "full-body" "footer-controls" "sidebar" "footer";

		@include bp($layout-page-bp) {
			grid-template-columns: 1fr;

			&.l-page--has-sidebar {
				grid-template-columns: 1fr $layout-page-aside-column-width;
				grid-template-areas:
					"header header" "main-controls sidebar" "full-body full-body" "footer-controls footer-controls" "footer footer";
			}

			&.l-page--has-large-sidebar {
				grid-template-columns: 1fr $layout-page-aside-column-width-large;
				grid-template-areas:
					"header header" "main-controls sidebar" "full-body full-body" "footer-controls footer-controls" "footer footer";
			}
		}

		@include bp($layout-wrapper-max-width) {
			max-width: $viewport-max-width;
		}
	}

	.l-page__header {
		grid-area: header;
	}

	.l-page__main-controls {
		grid-area: main-controls;
		overflow-y: auto;

		.l-page--has-sidebar & {

			@include bp("large") {
				max-width: calc(100vw - $layout-aside-width - $layout-aside-gutter-width - $bsu);
			}

			@include bp($layout-wrapper-max-width) {
				margin-left: $layout-page-gutter;
				--layout-gutter-right: 0;
				max-width: calc($layout-wrapper-max-width - $layout-aside-width - $layout-aside-gutter-width - $bsu);
			}
		}

		.l-page--has-large-sidebar & {

			@include bp("large") {
				max-width: calc(100vw - $layout-aside-width-large - $layout-aside-gutter-width - $bsu);
			}

			@include bp($layout-wrapper-max-width) {
				margin-left: $layout-page-gutter;
				--layout-gutter-right: 0;
				max-width: calc($layout-wrapper-max-width - $layout-aside-width-large - $layout-aside-gutter-width - $bsu);
			}
		}
	}

	.l-page__body-controls {
		grid-area: full-body;
	}

	.l-page__footer-controls {
		grid-area: footer-controls;

		.l-page__main-controls + & {
			// Simulate control gap margin collapsing
			// like with display: block;
			margin-top: calc(-1 * var(--control-gap));
		}
	}

	.l-page__sidebar {
		grid-area: sidebar;

		@include bp($layout-page-bp) {
			margin-top: 60px;
			width: $layout-aside-width;

			&.l-page__sidebar--large {
				width: $layout-aside-width-large;
			}
		}

		width: auto;
		// If the sidebar is taller than the main controls,
		// ensure footer controls don't butt right up against it
		margin-bottom: var(--control-gap);

		@include bp($layout-page-bp) {
			margin-left: $layout-aside-gutter-width;
		}

		@include bp($layout-wrapper-max-width) {
			margin-right: calc(#{$layout-page-gutter} + #{$bsu});
		}
	}

	.l-page__footer {
		grid-area: footer;
	}
}

.l-page--flush {

	> .l-page__main-controls,
	> .l-page__sidebar {
		padding-top: 0;
		padding-bottom: 0;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.l-page--checkout {
	grid-template-areas: "header" "checkout" "footer";
}