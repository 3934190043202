﻿.c-two-column {
	@include control;
}

.c-two-column__layout-wrapper {
	@include layout-wrapper;
}

.c-two-column__layout {
	@include flex-extend;
	flex-wrap: wrap;
	margin-left: -$lsu;
}

.c-two-column__heading {
	@include yellow-line-heading;
}

.c-two-column__column {
	padding-left: $lsu;
	width: 100%;

	// remove top margin from all controls in column
	// add smaller bottom margin to all controls in column
	> * {
		margin: 0 0 20px;
	}

	// remove bottom margin from last columns last control
	&:last-child {
		> *:last-child {
			margin-bottom: 0;
		}
	}

	@include bp("large") {
		width: 50%;

		// remove bottom margin from each columns last control
		> *:last-child {
			margin-bottom: 0;
		}
	}
}
