// OBJECT.BTN

// Shared button styles

@mixin button-size($size: "medium", $icon: false) {
	@if ($icon == true) {
		@include button-icon-size($size);
	}
	@else {
		@if ($size == "large") {
			@include font-base-16b;
			padding: ($ssu * 1.1) ($ssu * 2.5);

			@include bp("medium") {
				padding: ($ssu * 1.3) ($ssu * 3.5);
			}

			@include bp("large") {
				padding: ($ssu * 1.5) ($ssu * 4.5);
			}
		}
		@else if ($size == "medium") {
			@include font-base-16b;
			padding: ($ssu * 1.1) ($ssu * 2.5);

			@include bp("medium") {
				padding: ($ssu * 1.3) ($ssu * 3.5);
			}

			@include bp("large") {
				padding: ($ssu * 1.5) ($ssu * 4.5);
			}
		}
		@else if ($size == "small") {
			@include font-base-14b;
			padding: 11px 25px;
		}
		@else if ($size == "smallest") {
			@include font-base-14;
			padding: 6px 20px;
		}
		@else {
			@warn "Unknown button size #{$size}.";
		}
	}
}

@mixin button-icon-size($size: "medium") {
	@if ($size == "large") {
		height: 74px;
		width: 74px;
		font-size: 28px;
		line-height: #{74px - (23px * 2)};
		padding: 23px;
	}
	@else if ($size == "medium") {
		height: 54px;
		width: 54px;
		font-size: 24px;
		line-height: #{54px - (15px * 2)};
		padding: 15px;
	}
	@else if ($size == "small") {
		height: 40px;
		width: 40px;
		font-size: 16px;
		line-height: #{40px - (11px * 2)};
		padding: 11px;
	}
	@else if ($size == "smallest") {
		height: 30px;
		width: 30px;
		font-size: 14px;
		line-height: #{30px - (6px * 2)};
		padding: 6px;
	}
}

@mixin button($size: "small", $icon: false) {
	@include link-transition;
	display: inline-block;

	@if ($icon == true) {
		@include button-icon-size($size);
	}
	@else {
		@include button-size($size);
	}

	border: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	border-radius: 50px;

	&:disabled {
		cursor: not-allowed;
		background: $white !important;
		color: $grey !important;
		border: none !important;
		box-shadow: inset 0 0 0 1px $grey--light !important;
	}
}

@mixin button--text {
	padding: 0;
	border: none;
	background-color: transparent;
}

@mixin btn-primary {
	background: $yellow;
	border: 1px solid $yellow;
	border-radius: 50px;
	color: $dark-blue;
	display: inline-block;
	text-align: center;
	@include link-transition;

	&:hover {
		background: $yellow--dark;
		border: 1px solid $yellow--dark;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}

	&:disabled, [disabled] {
		background: $grey--lightest;
		border: 1px solid $grey--lightest;
		color: $grey;
	}
}

@mixin btn-primary-outline {
	background: none;
	border: 1px solid $yellow;
	border-radius: 50px;
	color: $yellow;
	display: inline-block;
	text-align: center;
	@include link-transition;

	&:hover {
		background: $yellow;
		color: $dark-blue;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}

	&:disabled, [disabled] {
		background: $grey--lightest;
		border: 1px solid $grey--lightest;
		color: $grey;
	}
}

@mixin btn-secondary {
	background: $white;
	border: 1px solid $dark-blue;
	border-radius: 50px;
	color: $dark-blue;
	display: inline-block;
	text-align: center;
	@include link-transition;

	&:hover {
		background: $blue--accent;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}

	&:focus,
	&:active{
		background: $dark-blue;
		color: $yellow;
	}

	&:disabled, [disabled] {
		background: $grey--lightest;
		border: 1px solid $grey--lightest;
		color: $grey;
	}
}

@mixin btn-secondary--selected {
	background: $dark-blue;
	border: 1px solid $dark-blue;
	border-radius: 50px;
	color: $yellow;
	@include link-transition;
}

@mixin btn-social($size: 45px) {
	@include circle($size);
	background-color: none;
	border: 1px solid transparent;
	color: $blue--accent;

	&:hover,
	&:focus {
		color: $yellow;
		border-color: $yellow;
	}
}