﻿.c-park-slider {
	@include slider;
	@include control;
}

.c-park-slider__heading {
	@include yellow-line-heading;
	color: $brand--alt;
}

.c-park-slider__container {
	@include slider__container;
}

.c-park-slider__item {
	@include slider__item;
}
