// CONTROL.CASE-STUDY

@use "sass:math";

$case-study-bp: "large";

.c-case-study {
	@include control;
	@include radius;
	@include bp($case-study-bp) {
		@include flex-extend;
		align-items: stretch;
	}
}

.c-case-study__layout {
	@include layout-wrapper;
}

.c-case-study__figure {
	@include figure;

	@include bp($case-study-bp) {
		flex: 1 0 50%;
	}
}

.c-case-study__image {
	height: 100%;
}

.c-case-study__body {
	@include font-body-text;

	padding: $bsu;
	background-color: $grey--lightest;

	@include bp($case-study-bp) {
		flex: 1 0 50%;
		padding: $lsu;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.c-case-study__heading {
	margin-bottom: $bsu;

	@include font-base-18b;
	@include bp($case-study-bp) {
		@include font-base-18b;
	}
}

.c-case-study__text {
	@include body-text;

	margin-bottom: $bsu;
}

.c-case-study__video {
	@include embed;

	display: block;
	cursor: pointer;

	padding-bottom: percentage(math.div(9, 16));
}

.c-case-study__video-icon {
	@include playbutton;
}

.c-case-study__link {
	@include button;
}
