
.c-cart__loader {
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.c-cart__loader-message {
	display: block;
	text-align: center;
}


.c-cart__item {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	padding: 15px 15px 30px;
	position: relative;
	margin: 0 -20px 40px;

	&[aria-expanded="true"] {
		border: 1px solid $blue--light;
	}

	@include bp("medium") {
		margin: 0 0 60px;
		padding: 15px;
	}
}

.c-cart__item--membership {
	padding: 15px;
}

.c-cart__item-warning {
	padding: $ssu;
	border: 1px solid $error;
	margin-bottom: $bsu;
	background-color: $error--light;
}

.c-cart-top {
	display: flex;

	.c-cart__item--membership & {
		align-items: center;
	}
}

.c-cart__item-image {
	display: none;

	img {
		width: 100%;
	}

	@include bp("medium") {
		display: block;
		height: 120px;
		width: 200px;
		overflow: hidden;
	}

	@include bp("large") {
		display: block;
		height: 170px;
		width: 300px;
		overflow: hidden;
	}
}

.c-cart__item-copy {
	width: 100%;
	padding: 10px;

	@include bp("medium") {
		width: calc(100% - 260px);
		padding: 10px 30px;
	}

	@include bp("large") {
		width: calc(100% - 360px);
		padding: 10px 60px 10px 30px;
	}

	.c-cart__item--membership & {
		display: flex;
		width: 100%;
		gap: $bsu;
	}
}

.c-cart__item-actions {
	width: 45px;

	@include bp("medium") {
		width: 60px
	}

	.c-cart__item-actions-edit,
	.c-cart__item-actions-delete {
		height: 80px;
		width: 45px;
		margin-bottom: 5px;
		text-align: center;
		font-size: 20px;
		padding: 30px 0;
		color: $blue--light;
		background: $blue--lightest;
		@include link-transition;

		@include bp("medium") {
			width: 60px
		}

		&:hover {
			color: $blue;
			cursor: pointer;
		}
	}
}

.c-cart__item-copy-wrapper {
	display: block;

	@include bp("large") {
		display: flex;
	}
}

.c-cart__item-titlebar {
	width: 100%;
	margin-right: 0px;

	@include bp("medium") {
		width: 100%;
		margin-right: 10px;
	}

	@include bp("extra-large") {
		width: 40%;
	}
}

.c-cart__item-heading {
	@include font-base-18b;
	color: $dark-blue;
	margin-bottom: 10px;

	@include bp("medium") {
		@include font-heading-24;
		margin-bottom: 10px;
	}

	.c-cart__item--membership & {
		width: 60%;
		margin-bottom: 0;
	}
}

.c-cart__item-location {
	@include font-base-12;
	margin-bottom: 10px;

	@include bp("medium") {
		@include font-base-14;
		margin-bottom: 10px;
	}
}

.c-cart__strapline {
	@include font-base-14;
	color: $orange;
	margin-bottom: 15px;

	@include bp("medium") {
		@include font-base-16b;
	}

	&.mobile {
		display: block;

		@include bp("medium") {
			display: none;
		}
	}

	&.desktop {
		display: none;

		@include bp("medium") {
			display: block;
		}
	}
}

.c-cart__item-unavailable {
	@include font-base-18b;
	color: $grey;
	margin-top: 20px;
	text-align: center;

	@include bp("medium") {
		@include font-heading-20;
		margin-bottom: 10px;
	}
}

.c-cart__details {
	width: 100%;
	display: flex;
	padding-left: 0px;

	@include bp("medium") {
		width: 100%;
		padding-left: 0px;
	}

	@include bp("extra-large") {
		width: 60%;
		padding-left: 30px;
	}
}

.c-cart__details-amounts {
	width: 50%;
}

.c-cart__details-persons,
.c-cart__details-dates {
	@include font-base-12b;

	@include bp("medium") {
		@include font-base-16b;
	}
}

.c-cart__details-nights {
	@include font-base-12;
	margin-top: 5px;
	color: $grey--dark;

	@include bp("medium") {
		@include font-base-16;
		margin-top: 0px;
	}
}

.c-cart__details-costs {
	width: 50%;
}

.c-cart__details-price {
	@include font-base-16b;
	text-align: right;
	margin-top: 15px;

	@include bp("medium") {
		@include font-heading-24;
		margin-top: 0px;
	}

	.c-cart__item--membership & {
		width: 40%;
		margin-top: 0;
	}
}

.c-cart__details-save {
	@include font-base-12;
	text-align: right;
	color: $blue;

	@include bp("medium") {
		@include font-base-16b;
	}
}

.c-cart-expander-button {
	position: absolute;
	bottom: -20px;
	right: 15px;
	height: 44px;
	width: 44px;
	padding: 14px;
	border-radius: 50%;
	background: $yellow;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);

	@include bp("large") {
		right: 100px;
	}

	&:hover {
		cursor: pointer;
	}

	span {
		font-size: 18px;
		color: $dark-blue;
	}

	&[aria-expanded="true"] {

		.c-cart-expander-open {
			display: none;
		}

		.c-cart-expander-close {
			display: block;
		}
	}

	&[aria-expanded="false"] {

		.c-cart-expander-open {
			display: block;
		}

		.c-cart-expander-close {
			display: none;
		}
	}
}

.c-cart__item {

	&[aria-expanded="true"] {

		.c-expand-collapse__body {
			display: block;
		}
	}

	&[aria-expanded="false"] {

		.c-expand-collapse__body {
			display: none;
		}
	}
}

.c-cart-expander-container {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column-reverse;
	padding: 10px;
	margin: 0;

	@include bp("large") {
		display: flex;
		flex-direction: row;
		padding: 20px 40px;
	}
}

.c-cart__additional-information {
	width: 100%;
	padding-right: 0px;

	@include bp("large") {
		width: 66%;
		padding-right: 40px;
	}
}

.c-cart__price-breakdown {
	width: 100%;
	padding-bottom: 20px;

	@include bp("large") {
		width: 33%;
		padding-bottom: 0px;
	}
}

.c-cart__line-header {
	position: relative;
	z-index: 1;
	overflow: hidden;
	@include font-base-14b;
	color: $blue--light;
	margin-bottom: 20px;

	&:after {
		position: absolute;
		top: 54%;
		overflow: hidden;
		width: 100%;
		height: 1px;
		margin-left: 10px;
		content: '\a0';
		background: $blue--light;
	}

	@include bp("large") {
		@include font-base-16b;
		margin-bottom: 40px;
	}
}

.c-cart__additional-items {
	padding-bottom: 20px;
}

.c-cart__campsite {
	display: flex;
	flex-wrap: wrap;
}

.c-cart-totals {
	width: 100%;
	height: auto;

	@include bp("medium") {
		max-width: 550px;
		float: right;
	}

	.error {
		color: $red;
		text-align: center;
		margin: 10px 0;
	}
}

/* Extras */

.c-cart__extra-row {
	display: flex;
	margin-bottom: 20px;
}

.c-cart__extra-copy {
	width: calc(100% - 150px);
	padding-right: 20px;
}

.c-cart__extra-value {
	width: 150px;

	.c-form__field {
		display: flex;
		align-items: center;

		.c-form__input {
			display: block;
			height: 20px;
			width: 20px;
			margin-right: 10px;
			text-align: center;
		}
	}

	.c-form__number-wrapper {
		align-items: baseline;
	}

	input[type=number] {
		text-align: center;
	}
}

.c-form__label--price {
	text-align: center;
}

/* Breakdown */
.c-cart__price-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 100px);
}

.c-cart-price__listing {
	width: 100%;

	.c-cart-price__listing-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		gap: $bsu;

		&.c-cart-price__listing-total {
			font-weight: bold;
			margin-top: $bsu;

			&:first-child {
				margin-top: 0;
			}
		}

		&.c-cart-price__listing-discount {
			font-weight: normal;
			margin-top: 0;
		}
	}
}

.c-cart__action {
	padding: 0 30px;
	display: flex;
	margin-top: 0;

	.c-cart__save {
		@include button("small");
		@include btn-secondary;
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 0px;

		@include bp("large") {
			width: 33%;
			margin-left: auto;
			margin-bottom: 20px;
		}
	}

	@include bp("large") {
		margin-top: -80px;
	}
}

.c-cart-clear {
	@include button("small");
	@include btn-secondary;
	display: inline-block;
	width: 100%;
	margin-top: 40px;

	@include bp("small") {
		width: auto;
		margin-top: 20px;
		display: flex;
		align-items: center;
	}

	@include bp("large") {
		width: auto;
		margin-top: 0px;
	}

	span {
		margin-right: 5px;
		font-size: 18px;
	}
}

.c-cart__save-icon {
	@include link-transition;
	position: absolute;
	top: calc(50% + 1px);
	transform: translate(10px, -50%);
	opacity: 0;

	.c-cart__save.is-saved & {
		opacity: 1;
	}
}


/*====----  Totals Block  ----====*/
.c-cart-totals__listing {
	background: $grey--lightest;
	padding: 20px 60px;

	@include bp("medium") {
		padding: 30px 80px;
	}
}

.c-cart-totals__listing-item {
	display: flex;
	justify-content: space-between;
	@include font-base-14;

	@include bp("medium") {
		@include font-base-16;
	}
}

.c-cart-totals__listing-total {
	@include font-base-16b;
	margin-top: 10px;

	@include bp("medium") {
		@include font-heading-24;
		margin-top: 20px;
	}
}

.c-cart-totals__actions {
	width: 100%;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;

	.c-cart-totals__action-button {
		display: inline-block;
		width: 100%;
		margin-top: 20px;

		@include bp("small") {
			width: 49%;
			margin-top: 30px;
		}
	}

	@include bp("small") {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}

.c-cart-totals__actions-anotherpark {
	@include button("small");
	@include btn-secondary;
}

.c-cart-totals__actions-checkout {
	@include button("small");
	@include btn-primary;
}

/*====----  Tooltip  ----====*/

.c-cart__extra-tooltip-wrapper {
	margin-left: 5px;
	margin-bottom: 15px;

	.c-cart__tooltip {
		height: 12px;
		width: 12px;
		border-radius: 50%;
		background: #005077;
		color: #fff;

		span {
			display: block;
			font-size: 10px;
			line-height: 10px;
			margin-left: 5px;
		}
	}
}

/*====----  Sidebar listing  ----====*/
.c-checkout-sidebar {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	padding-bottom: 20px;

	.c-checkout-sidebar__header {
		background: $yellow;
		padding: 20px 20px 20px 35px;
		color: $dark-blue;
		@include font-base-14b;
		display: flex;
		justify-content: space-between;

		a {
			display: inline-block;
			font-size: 20px;
		}
	}
}

/*====----  Added to cart modal  ----====*/

.c-cart-added {
	max-width: 500px;
	width: 100%;

	@include bp("medium") {
		width: 500px;
	}
}

.c-cart-added__error-message {
	padding: 20px;
	text-align: center;
	@include font-base-18b;
	color: $red;
	margin-bottom: 10px;

	@include bp("medium") {
		@include font-heading-24;
	}
}

.c-cart-added__confirm {
	@include font-heading-24;
	color: $green--dark;
	margin-bottom: 20px;
	text-align: center;

	span {
		vertical-align: top;
	}

	.c-cart-added__success {
		color: $white;
		background: $green--dark;
		border-radius: 50%;
		font-size: 16px;
		padding: 2px;
		display: inline-block;
		margin-top: 6px;
		margin-right: 5px;
	}
}

.c-cart-added__item {
	border-top: 1px solid $grey;
	border-bottom: 1px solid $grey;
	padding: 40px 30px;
	margin: 0 -20px;
	display: flex;
}

.c-cart-added__item-image {
	width: 220px;
	margin-right: 20px;
	display: none;
	padding-top: 8px;

	img {
		width: 100%;
	}

	@include bp("medium") {
		display: block;
		@include font-heading-24;
	}
}

.c-cart-added__item-copy-wrapper {
	text-align: left;
}

.c-cart-added__item-copy {
	width: 100%;
	padding-right: 10px;
}

.c-cart-added__item-heading {
	@include font-base-18b;
	color: $dark-blue;
	margin-bottom: 10px;

	@include bp("medium") {
		@include font-heading-24;
	}
}

.c-cart-added__item-location {
	@include font-base-12;
	margin-bottom: 10px;

	@include bp("medium") {
		@include font-base-14;
	}
}

.c-cart-added__strapline {
	@include font-base-14;
	color: $orange;
	margin-bottom: 15px;

	@include bp("medium") {
		@include font-base-16;
	}
}

.c-cart-added__details {
	display: flex;

	.c-cart-added__details-amounts,
	.c-cart-added__details-costs {
		width: 50%;
	}
}

.c-cart-added__details-persons,
.c-cart-added__details-dates {
	@include font-base-12b;

	@include bp("medium") {
		@include font-base-16b;
	}
}

.c-cart-added__details-nights {
	@include font-base-12;
	margin-top: 5px;
	color: $grey--dark;

	@include bp("medium") {
		@include font-base-16;
	}
}

.c-cart-added__details-price {
	@include font-base-16b;
	text-align: right;
	margin-top: 15px;

	@include bp("medium") {
		@include font-heading-24;
	}
}

.c-cart-added__details-save {
	@include font-base-12;
	text-align: right;
	color: $blue;

	@include bp("medium") {
		@include font-base-16;
	}
}

.c-cart-added__actions {
	margin-top: 20px;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;

	@include bp("medium") {
		flex-direction: row;
		justify-content: space-around;
	}
}

.c-cart-added__continue-button {
	@include font-base-16b;
	color: $dark-blue;
	@include link-transition;
	margin-top: 20px;

	@include bp("medium") {
		margin-top: 0;
	}

	&:hover {
		color: $blue;
	}
}

.c-cart-added__checkout-button {
	@include button("small");
	@include btn-primary;
}

.c-button-wrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: $bsu;

	@include bp("medium") {
		flex-direction: row;
	}
}

.c-button__close {
	@include button("small");
	@include btn-secondary;
}

.c-button__update-cart {
	@include button("small");
	@include btn-primary;
	min-width: 150px;

	@include bp(360px) {
		min-width: 180px;
	}
}
