﻿@use "sass:math";

.c-contact-details {
    margin-bottom: $lsu;

    .c-contact-details__title {
        @include yellow-line-heading;
        color: $dark-blue;
        margin-bottom: $bsu;
    }

    .c-contact-details__wrapper {
        display: block;

        @include bp("medium") {
            display: block;
        }

        .c-contact-details__list {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(3,1fr);
        }
    }
}

.c-contact-details {
    &.c-contact-details--with-form {
        .c-contact-details__wrapper {
            display: block;

            @include bp("medium") {
                display: flex;
                gap: 40px;
                justify-content: flex-start;
            }

            .c-contact-details__list {
                width: 100%;
                vertical-align: top;

                @include bp("medium") {
                    width: 50%;
                }
            }

            .c-contact-details__form {
                width: 100%;
                vertical-align: top;

                @include bp("medium") {
                    width: 50%;
                }
            }

            .c-contact-details__list {
                display: block;
            }
        }
    }
}

.c-contact-details__list-item {
    margin-bottom: $lsu;

    .c-contact-details__list-heading {
        @include yellow-line-heading;
        color: $dark-blue;
        margin-bottom: $bsu;
    }

    .c-contact-details__list-name {
        @include font-base-16b;
    }

    .c-contact-details__list-position {
        @include font-base-16;
        margin-bottom: $bsu;
    }

    .c-contact-details__numbers {
        @include font-base-16b;

        .c-contact-details__numbers-item {
            @include flex-extend;
            align-items: center;
            margin-bottom: $ssu;

            .c-contact-details__numbers-icon {
                flex-shrink: 0;
                font-size: 18px;
                height: 20px;
                width: 25px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: $ssu;
            }

            .c-contact-details__numbers-value {
                flex-grow: 1;

                a {
                    display: inline-block;
                    padding-bottom: math.div($ssu, 2);

                    &:hover {
                        color: $link-hover;
                    }
                }

                > :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.c-contact-details__form {

    .umbraco-forms-form {
        margin: 0;
        padding: 0;
    }

    form {
        .umbraco-forms-caption {
            @include yellow-line-heading;
            color: $dark-blue;
            font-size: 20px !important;
            line-height: 26px !important;

            @include bp("medium") {
                font-size: 32px !important;
                line-height: 38px !important;
            }

            @include bp("large") {
                font-size: 40px !important;
                line-height: 48px !important;
            }
        }
    }
}
