﻿// CONTROL.PACKAGE-ROTATOR

.c-package-rotator {
	@include control;

	.flickity-prev-next-button {
		top: 100%;

		&.previous {
			@include bp("large") {
				left: inherit;
				right: $hsu + $ssu;
			}
		}

		&.next {
			@include bp("large") {
				right: $bsu;
			}
		}
	}

	.flickity-page-dots {
		@include bp("large") {
			display: none;
		}
	}
}

.c-package-rotator__layout {
	@include layout-wrapper;
}

.c-package-rotator__heading {
	@include control__heading;
}

.c-package-rotator__items {

	&:focus-visible {
		outline: none;
	}
}

.c-package-rotator__slide-item {
	width: 100%;
}

$slide-content-column-width: 460px;

@supports (display: grid) {
	.c-package-rotator__slide-item {
		display: grid;
		grid-template-areas:
			"slide-header"
			"slide-img"
			"slide-info";

		@include bp("large"){
			grid-template-columns: 1fr;
			grid-template-columns: $slide-content-column-width 1fr;
			grid-template-areas:
			"slide-header slide-img"
			"slide-info slide-img";
			column-gap: $bsu;
		}
	}

	.c-package-rotator__slide-header {
		grid-area: slide-header;
	}

	.c-package-rotator__slide-info {
		grid-area: slide-info;
		z-index: 1;
	}

	.c-package-rotator__slide-img {
		grid-area: slide-img;
	}
}


.c-package-rotator__slide-header {
	@include bp-below("large") {
		text-align: center;
		padding: $bsu;
	}
}

.c-package-rotator__slide-info {
	@include bp-below("large") {
		text-align: center;
	}
}

.c-package-rotator__slide-heading {
	@include font-heading-32;
	margin-bottom: $bsu;
}

.c-package-rotator__slide-body {
	@include font-body-text;
	padding: ( $bsu * 1.5 );

	@include bp("large") {
		margin-right: -$lsu;
		padding: ( $bsu * 2.5 );
	}
}

.c-package-rotator__package-eyebrow {
	@include font-base-18b;
	display: inline-block;
	margin-bottom: $ssu;
}

.c-package-rotator__package-heading {
	@include font-heading-24;
	margin-bottom: $bsu;
}

.c-package-rotator__package-link {
	@include button("medium");
	margin-top: $bsu;

	@include bp("large") {
		margin-right: $bsu;

		&:last-child {
			margin-right: 0;
		}
	}
}