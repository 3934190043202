﻿.c-locations-list {
    margin-bottom: $lsu;

    .c-locations-list__heading {
        margin-bottom: $bsu;
    }

    .c-locations-list__list {
        margin-bottom: $lsu;
    }
}

.c-locations-list__list-item {
    display: block;
    margin-bottom: $lsu;
    @include radius;

    @include bp("large") {
        display: flex;
    }

    .c-locations-list__body,
    .c-locations-list__map-container {
        width: 100%;
        vertical-align: top;

        @include bp("large") {
            width: 50%;
        }
    }
}

.c-locations-list__body {
    background: #f9f9f9;
    padding: $lsu;

    .c-locations-list__location-heading {
        @include font-heading-20;
        margin-bottom: $bsu;
    }

    .c-locations-list__detail-list {
        .c-locations-list__detail-list-location {
            @include flex-extend;
            margin-bottom: $bsu;

            .c-locations-list__detail-list-icon {
                flex-shrink: 0;
                font-size: 18px;
                height: 20px;
                width: 25px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: $ssu;
                background: none;
            }

            .c-locations-list__detail-list-content {
                flex-grow: 1;

                a {
                    display: inline-block;
                    padding-bottom: $ssu;
                }

                > :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.c-locations-list__map-container {
    height: auto;
    min-height: 350px;

    > div {
        height: 100%;
        max-height: 100%;
        min-height: 350px !important;
    }
}