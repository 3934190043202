@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?5us4kl') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?5us4kl') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?5us4kl') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?5us4kl##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconf-"], [class*=" iconf-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconf-tiktok {
  &:before {
    content: $iconf-tiktok; 
  }
}
.iconf-enlarge {
  &:before {
    content: $iconf-enlarge; 
  }
}
.iconf-home {
  &:before {
    content: $iconf-home; 
  }
}
.iconf-search {
  &:before {
    content: $iconf-search; 
  }
}
.iconf-arrow-up {
  &:before {
    content: $iconf-arrow-up; 
  }
}
.iconf-arrow-down {
  &:before {
    content: $iconf-arrow-down; 
  }
}
.iconf-arrow-left {
  &:before {
    content: $iconf-arrow-left; 
  }
}
.iconf-arrow-right {
  &:before {
    content: $iconf-arrow-right; 
  }
}
.iconf-close {
  &:before {
    content: $iconf-close; 
  }
}
.iconf-minus {
  &:before {
    content: $iconf-minus; 
  }
}
.iconf-plus {
  &:before {
    content: $iconf-plus; 
  }
}
.iconf-tick {
  &:before {
    content: $iconf-tick; 
  }
}
.iconf-link {
  &:before {
    content: $iconf-link; 
  }
}
.iconf-external {
  &:before {
    content: $iconf-external; 
  }
}
.iconf-download {
  &:before {
    content: $iconf-download; 
  }
}
.iconf-photo {
  &:before {
    content: $iconf-photo; 
  }
}
.iconf-camera {
  &:before {
    content: $iconf-camera; 
  }
}
.iconf-video {
  &:before {
    content: $iconf-video; 
  }
}
.iconf-play {
  &:before {
    content: $iconf-play; 
  }
}
.iconf-email {
  &:before {
    content: $iconf-email; 
  }
}
.iconf-print {
  &:before {
    content: $iconf-print; 
  }
}
.iconf-phone {
  &:before {
    content: $iconf-phone; 
  }
}
.iconf-fax {
  &:before {
    content: $iconf-fax; 
  }
}
.iconf-address {
  &:before {
    content: $iconf-address; 
  }
}
.iconf-google-maps {
  &:before {
    content: $iconf-google-maps; 
  }
}
.iconf-calendar {
  &:before {
    content: $iconf-calendar; 
  }
}
.iconf-share {
  &:before {
    content: $iconf-share; 
  }
}
.iconf-facebook {
  &:before {
    content: $iconf-facebook; 
  }
}
.iconf-twitter {
  &:before {
    content: $iconf-twitter; 
  }
}
.iconf-youtube {
  &:before {
    content: $iconf-youtube; 
  }
}
.iconf-flickr {
  &:before {
    content: $iconf-flickr; 
  }
}
.iconf-pinterest {
  &:before {
    content: $iconf-pinterest; 
  }
}
.iconf-instagram {
  &:before {
    content: $iconf-instagram; 
  }
}
.iconf-linkedin {
  &:before {
    content: $iconf-linkedin; 
  }
}
.iconf-weibo {
  &:before {
    content: $iconf-weibo; 
  }
}
.iconf-wechat {
  &:before {
    content: $iconf-wechat; 
  }
}
.iconf-youku {
  &:before {
    content: $iconf-youku; 
  }
}
.iconf-snapchat {
  &:before {
    content: $iconf-snapchat; 
  }
}
.iconf-blogger {
  &:before {
    content: $iconf-blogger; 
  }
}
.iconf-tumblr {
  &:before {
    content: $iconf-tumblr; 
  }
}
.iconf-tripadvisor {
  &:before {
    content: $iconf-tripadvisor; 
  }
}
.iconf-web {
  &:before {
    content: $iconf-web; 
  }
}
.iconf-chat {
  &:before {
    content: $iconf-chat; 
  }
}
.iconf-filter {
  &:before {
    content: $iconf-filter; 
  }
}
.iconf-reload {
  &:before {
    content: $iconf-reload; 
  }
}
.iconf-sort {
  &:before {
    content: $iconf-sort; 
  }
}
.iconf-small-arrow-up {
  &:before {
    content: $iconf-small-arrow-up; 
  }
}
.iconf-small-arrow-down {
  &:before {
    content: $iconf-small-arrow-down; 
  }
}
.iconf-small-arrow-left {
  &:before {
    content: $iconf-small-arrow-left; 
  }
}
.iconf-small-arrow-right {
  &:before {
    content: $iconf-small-arrow-right; 
  }
}
.iconf-upload {
  &:before {
    content: $iconf-upload; 
  }
}
.iconf-cart {
  &:before {
    content: $iconf-cart; 
  }
}
.iconf-p-dashboard {
  &:before {
    content: $iconf-p-dashboard; 
  }
}
.iconf-p-Booking {
  &:before {
    content: $iconf-p-Booking; 
  }
}
.iconf-p-profile {
  &:before {
    content: $iconf-p-profile; 
  }
}
.iconf-p-log-in {
  &:before {
    content: $iconf-p-log-in; 
  }
}
.iconf-p-log-out {
  &:before {
    content: $iconf-p-log-out; 
  }
}
.iconf-edit {
  &:before {
    content: $iconf-edit; 
  }
}
.iconf-trash {
  &:before {
    content: $iconf-trash; 
  }
}
.iconf-benefits {
  &:before {
    content: $iconf-benefits; 
  }
}
.iconf-Generic_tick {
  &:before {
    content: $iconf-Generic_tick; 
  }
}
.iconf-question-mark {
  &:before {
    content: $iconf-question-mark; 
  }
}
.iconf-warning {
  &:before {
    content: $iconf-warning; 
  }
}
.iconf-document {
  &:before {
    content: $iconf-document; 
  }
}
.iconf-clipboard {
  &:before {
    content: $iconf-clipboard; 
  }
}
.iconf-more {
  &:before {
    content: $iconf-more; 
  }
}
.iconf-virtual {
  &:before {
    content: $iconf-virtual; 
  }
}
.iconf-one-finger-swip {
  &:before {
    content: $iconf-one-finger-swip; 
  }
}
.iconf-guest {
  &:before {
    content: $iconf-guest; 
  }
}
.iconf-wheelchair {
  &:before {
    content: $iconf-wheelchair; 
  }
}
.iconf-bed {
  &:before {
    content: $iconf-bed; 
  }
}
.iconf-single_bed {
  &:before {
    content: $iconf-single_bed; 
  }
}
.iconf-bunk {
  &:before {
    content: $iconf-bunk; 
  }
}
.iconf-dump {
  &:before {
    content: $iconf-dump; 
  }
}
.iconf-sauna {
  &:before {
    content: $iconf-sauna; 
  }
}
.iconf-spa {
  &:before {
    content: $iconf-spa; 
  }
}
.iconf-library {
  &:before {
    content: $iconf-library; 
  }
}
.iconf-Living {
  &:before {
    content: $iconf-Living; 
  }
}
.iconf-dining {
  &:before {
    content: $iconf-dining; 
  }
}
.iconf-Wine {
  &:before {
    content: $iconf-Wine; 
  }
}
.iconf-recreationRoom {
  &:before {
    content: $iconf-recreationRoom; 
  }
}
.iconf-laundry {
  &:before {
    content: $iconf-laundry; 
  }
}
.iconf-BBQ {
  &:before {
    content: $iconf-BBQ; 
  }
}
.iconf-feature {
  &:before {
    content: $iconf-feature; 
  }
}
.iconf-Pets {
  &:before {
    content: $iconf-Pets; 
  }
}
.iconf-noPet {
  &:before {
    content: $iconf-noPet; 
  }
}
.iconf-service {
  &:before {
    content: $iconf-service; 
  }
}
.iconf-no_service {
  &:before {
    content: $iconf-no_service; 
  }
}
.iconf-Conference {
  &:before {
    content: $iconf-Conference; 
  }
}
.iconf-newspaper {
  &:before {
    content: $iconf-newspaper; 
  }
}
.iconf-Kitchen {
  &:before {
    content: $iconf-Kitchen; 
  }
}
.iconf-oven {
  &:before {
    content: $iconf-oven; 
  }
}
.iconf-microwave {
  &:before {
    content: $iconf-microwave; 
  }
}
.iconf-fridge {
  &:before {
    content: $iconf-fridge; 
  }
}
.iconf-Cutlery {
  &:before {
    content: $iconf-Cutlery; 
  }
}
.iconf-pots_pan {
  &:before {
    content: $iconf-pots_pan; 
  }
}
.iconf-utensil {
  &:before {
    content: $iconf-utensil; 
  }
}
.iconf-crockery {
  &:before {
    content: $iconf-crockery; 
  }
}
.iconf-rice_cooker {
  &:before {
    content: $iconf-rice_cooker; 
  }
}
.iconf-toast {
  &:before {
    content: $iconf-toast; 
  }
}
.iconf-plunger {
  &:before {
    content: $iconf-plunger; 
  }
}
.iconf-Espresso {
  &:before {
    content: $iconf-Espresso; 
  }
}
.iconf-cafe {
  &:before {
    content: $iconf-cafe; 
  }
}
.iconf-teaCoffee {
  &:before {
    content: $iconf-teaCoffee; 
  }
}
.iconf-filter_water {
  &:before {
    content: $iconf-filter_water; 
  }
}
.iconf-Ensuite_shower {
  &:before {
    content: $iconf-Ensuite_shower; 
  }
}
.iconf-toilet {
  &:before {
    content: $iconf-toilet; 
  }
}
.iconf-Bathroom {
  &:before {
    content: $iconf-Bathroom; 
  }
}
.iconf-dishwasher {
  &:before {
    content: $iconf-dishwasher; 
  }
}
.iconf-amenities {
  &:before {
    content: $iconf-amenities; 
  }
}
.iconf-Towel {
  &:before {
    content: $iconf-Towel; 
  }
}
.iconf-blanket {
  &:before {
    content: $iconf-blanket; 
  }
}
.iconf-kids_club {
  &:before {
    content: $iconf-kids_club; 
  }
}
.iconf-Pram {
  &:before {
    content: $iconf-Pram; 
  }
}
.iconf-playground {
  &:before {
    content: $iconf-playground; 
  }
}
.iconf-cot {
  &:before {
    content: $iconf-cot; 
  }
}
.iconf-Wifi {
  &:before {
    content: $iconf-Wifi; 
  }
}
.iconf-Bluetooth {
  &:before {
    content: $iconf-Bluetooth; 
  }
}
.iconf-TV {
  &:before {
    content: $iconf-TV; 
  }
}
.iconf-speaker {
  &:before {
    content: $iconf-speaker; 
  }
}
.iconf-Internet {
  &:before {
    content: $iconf-Internet; 
  }
}
.iconf-dvd {
  &:before {
    content: $iconf-dvd; 
  }
}
.iconf-mobile {
  &:before {
    content: $iconf-mobile; 
  }
}
.iconf-heater {
  &:before {
    content: $iconf-heater; 
  }
}
.iconf-air-con {
  &:before {
    content: $iconf-air-con; 
  }
}
.iconf-water_heater {
  &:before {
    content: $iconf-water_heater; 
  }
}
.iconf-Heated_floor {
  &:before {
    content: $iconf-Heated_floor; 
  }
}
.iconf-storage {
  &:before {
    content: $iconf-storage; 
  }
}
.iconf-car_wash {
  &:before {
    content: $iconf-car_wash; 
  }
}
.iconf-sky {
  &:before {
    content: $iconf-sky; 
  }
}
.iconf-netflix {
  &:before {
    content: $iconf-netflix; 
  }
}
.iconf-recycling {
  &:before {
    content: $iconf-recycling; 
  }
}
.iconf-Nature {
  &:before {
    content: $iconf-Nature; 
  }
}
.iconf-sport_gear {
  &:before {
    content: $iconf-sport_gear; 
  }
}
.iconf-table_tennis {
  &:before {
    content: $iconf-table_tennis; 
  }
}
.iconf-Petanque {
  &:before {
    content: $iconf-Petanque; 
  }
}
.iconf-mini_golf {
  &:before {
    content: $iconf-mini_golf; 
  }
}
.iconf-golf {
  &:before {
    content: $iconf-golf; 
  }
}
.iconf-basket-ball {
  &:before {
    content: $iconf-basket-ball; 
  }
}
.iconf-boogie_board {
  &:before {
    content: $iconf-boogie_board; 
  }
}
.iconf-tube {
  &:before {
    content: $iconf-tube; 
  }
}
.iconf-water_activities {
  &:before {
    content: $iconf-water_activities; 
  }
}
.iconf-gym {
  &:before {
    content: $iconf-gym; 
  }
}
.iconf-hiking {
  &:before {
    content: $iconf-hiking; 
  }
}
.iconf-biking {
  &:before {
    content: $iconf-biking; 
  }
}
.iconf-trampoline {
  &:before {
    content: $iconf-trampoline; 
  }
}
.iconf-skateboarding {
  &:before {
    content: $iconf-skateboarding; 
  }
}
.iconf-skiing {
  &:before {
    content: $iconf-skiing; 
  }
}
.iconf-SwimmingPool {
  &:before {
    content: $iconf-SwimmingPool; 
  }
}
.iconf-kayaking {
  &:before {
    content: $iconf-kayaking; 
  }
}
.iconf-Fish_clean {
  &:before {
    content: $iconf-Fish_clean; 
  }
}
.iconf-Fishing {
  &:before {
    content: $iconf-Fishing; 
  }
}
.iconf-go-kart {
  &:before {
    content: $iconf-go-kart; 
  }
}
.iconf-tour {
  &:before {
    content: $iconf-tour; 
  }
}
.iconf-maori {
  &:before {
    content: $iconf-maori; 
  }
}
.iconf-horse_riding {
  &:before {
    content: $iconf-horse_riding; 
  }
}
.iconf-chinese {
  &:before {
    content: $iconf-chinese; 
  }
}
.iconf-Card {
  &:before {
    content: $iconf-Card; 
  }
}
.iconf-parkmap {
  &:before {
    content: $iconf-parkmap; 
  }
}
.iconf-question-mark-circle {
  &:before {
    content: $iconf-question-mark-circle; 
  }
}
.iconf-signpost {
  &:before {
    content: $iconf-signpost; 
  }
}
.iconf-Train {
  &:before {
    content: $iconf-Train; 
  }
}
.iconf-clock {
  &:before {
    content: $iconf-clock; 
  }
}
.iconf-emergency {
  &:before {
    content: $iconf-emergency; 
  }
}

