﻿// CONTROL.DEALS-POD

.c-listing-pods {
	&.c-deals-listing-pods {
		width: 100%;
	}
}

.c-deals-listing-grid {
	grid-template-columns: repeat(1, 1fr);

	@include bp("medium") {
		grid-template-columns: repeat(2, 1fr);
	}

	@include bp("large") {
		grid-template-columns: repeat(3, 1fr);
	}
}

.c-deals-pod {
	background: $white;
	@include radius;
	overflow: visible;
	height: 100%;
	border: 1px solid $grey--light;
}


.c-deals-pod__image {
	width: 100%;
	height: 200px;
	position: relative;
	overflow: hidden;
	border-radius: 4px 4px 0 0;

	img {
		width: 100%;
	}

	.c-deals-pod__region {
		position: absolute;
		left: 10px;
		bottom: 5px;
		width: 35px;
		height: 50px;
		z-index: 1;

		&:before {
			position: absolute;
			left: -30px;
			bottom: -30px;
			content: "";
			height: 90px;
			width: 90px;
			border-radius: 50%;
			background: $yellow;
			z-index: -1;
		}

		&:after {
			position: absolute;
			left: -50px;
			bottom: -50px;
			content: "";
			height: 130px;
			width: 130px;
			border-radius: 50%;
			z-index: -2;
		}

		&.Premium:after {
			background: $premium-opacity;
		}

		&.Classic:after {
			background: $classic-opacity;
		}

		&.Superior:after {
			background: $superior-opacity;
		}
	}
}

.c-deals-pod__details {
	padding: 20px;
	position: relative;

	.c-deals-pod__details-tag {
		@include font-base-12b;
		height: 24px;
		border-radius: 12px;
		width: fit-content;
		line-height: 20px;
		color: $white;
		padding: 0 10px 0 5px;
		position: absolute;
		z-index: 1;
		top: -12px;
		left: 20px;

		&.Premium {
			background: $premium;
		}

		&.Superior {
			background: $superior;
		}

		&.Classic {
			background: $classic;
		}

		& span {
			display: inline-block;
			vertical-align: sub;
			height: 15px;
			width: 15px;
			margin-top: 4px;
			margin-right: 4px;
		}
	}
}

.c-deals-pod__details-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;

	.c-deals-pod__details-property-name {
		margin-bottom: $bsu;
		position: relative;
	}

	.c-deals-pod__details-name,
	.c-deals-pod__details-name-link {
		@include font-base-18b;
		color: $dark-blue;

		@include bp("medium") {
			@include font-heading-20;
		}
	}

	.c-deals-pod__details-location {
		margin-top: $ssu;
		@include font-base-12;
	}
}

.c-park-pod__details-info {
	margin: $ssu 0 $ssu;
	@include font-base-18b;
	color: $blue;
}

.c-park-pod__details-offer {
	margin-bottom: $ssu;

	p {
		margin-bottom: 0;

		&:last-child {
			margin-bottom: $ssu;
		}
	}
}

.c-park-pod__details-condition-heading {
	@include body-text;
}


.c-deals-pod__details-book-button {
	@include button("small");
	@include btn-secondary;
}

.c-deals-pod__button-items {
	display: flex;
	justify-content: flex-start;
	margin: 0 -5px;
	flex-direction: row;
	flex-wrap: wrap;
	gap: $ssu;
	align-items: center;


	.c-deals-pod__details-book-button {
		@include button("small");
		@include btn-primary;
		margin: 0 5px;
	}
}

