﻿#AvailabilityCalender.vc-container {
	width: 100%;
	max-width: 100%;
	border: 0px none;

	.vc-header {
		height: 46px;

		.vc-arrow {
			height: 45px;
			width: 45px;
			border: 1px solid $dark-blue;
			border-radius: 50%;
			background: $white;
		}
	}

	.vc-weeks {
		.vc-week {
			.vc-day {
				min-height: 40px;
				border-right: 1px solid #fff;
				border-bottom: 1px solid #fff;

				.vc-highlight {
					height: 40px;
					width: 100%;
					border-radius: 0px;

					&.vc-highlight-base-middle {
						width: 100%;
					}
				}

				.vc-day-content {
					height: 40px;
					width: 100%;
					border-radius: 0px;
				}

				.vc-available-checkout-only {
					background: linear-gradient(135deg, $green--light 50%, $grey--lightest 50%);
				}

				.vc-disabled {
					background: linear-gradient(to left top, $grey--lightest 47.75%, #efefef 49.5%, #efefef 50.5%, $grey--lightest 52.25%);
					color: $grey;
				}

				.vc-unavailable-checkin {
					background: $green--lightest;
				}

				.vc-unavailable-mlos {
					background: $green--lightest;
				}

				.vc-highlights {
					.vc-available, .vc-available-mlos {
						background: $green--light;

						.vc-highlight-bg-solid {
							background: $green--light;
						}
					}
				}

				.vc-day-box-center-center {
					+ .vc-available, + .vc-available-mlos {
						background: $blue--accent;

						.vc-highlight-bg-solid {
							background: $blue--accent;
						}
					}
				}

				.vc-day-box-right-center {
					+ .vc-day-box-center-center {
						+ .vc-available, + .vc-available-mlos {
							background: $blue;

							.vc-highlight-bg-solid {
								background: $blue;
							}
						}
					}
				}

				.vc-day-box-left-center {
					+ .vc-day-box-center-center {
						+ .vc-available, + .vc-available-mlos {
							background: $blue;

							.vc-highlight-bg-solid {
								background: $blue;
							}
						}
					}
				}

				.vc-highlight-content-solid {
					color: $dark-blue;

					&.vc-available-mlos, &.vc-unavailable-mlos {
						border: 2px solid #2BBB6E;
					}
				}

				.vc-day-layer:has(.vc-blue) + .vc-highlight-content-solid {
					color: $grey--darkest;
				}

				.vc-day-layer:has(.vc-day-box-left-center) + .vc-highlight-content-solid,
				.vc-day-layer:has(.vc-day-box-right-center) + .vc-highlight-content-solid {
					color: $yellow;
					background: $dark-blue;
				}

				.vc-day-layer:has(.vc-day-box-center-center) + .vc-highlight-content-outline {
					color: $yellow;
					background: $dark-blue;
				}

				.vc-day-layer:has(.vc-blue) + .vc-unavailable-checkin,
				.vc-day-layer:has(.vc-blue) + .vc-available-checkout-only,
				.vc-day-layer:has(.vc-blue) + .vc-unavailable-mlos {
					background: $blue--accent;
				}

				.vc-day-layer:has(.vc-day-box-left-center) + .vc-unavailable-checkin, .vc-day-layer:has(.vc-day-box-right-center) + .vc-unavailable-checkin,
				.vc-day-layer:has(.vc-day-box-left-center) + .vc-available-checkout-only, .vc-day-layer:has(.vc-day-box-right-center) + .vc-available-checkout-only,
				.vc-day-layer:has(.vc-day-box-left-center) + .vc-unavailable-mlos, .vc-day-layer:has(.vc-day-box-right-center) + .vc-unavailable-mlos {
					background: $blue;
				}
			}
		}
	}
}
