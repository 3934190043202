.c-page-header {
	margin: $lsu 0 0;

	@include bp("medium") {
		margin-top: $lsu * 1.5;
	}

	@include bp("large") {
		margin-top: $hsu;
	}
}

.c-member-page-header {
	background: $blue--lightest;
	padding: $lsu 0 $lsu;

	@include bp("medium") {
		padding: $lsu 0 $lsu;
	}

	@include bp("large") {
		padding: ($lsu + $bsu) 0 $lsu;
	}
}

.c-page-header__layout {
	@include layout-wrapper;
}

.c-page-header__lede,
.o-layout-header__lede {
	@include font-base-16;
	margin: 0 auto;
	text-align: center;

	> *:last-child {
		margin-bottom: 0;
	}

	@include bp("medium") {
		@include font-base-18;
		text-align: left;
	}
}

.c-page-header__date {
	@include font-base-12;
	margin-bottom: 5px;
	display: inline-block;
}

.c-page-header__author {
	@include font-base-12;
	margin-bottom: 2em;
	display: inline-block;
	text-transform: capitalize;

	span {
		padding: 0 10px;
	}
}
