﻿/* OBJECT.TILE */

.o-tile {
	//@include ui-border('all');
	//@include radius;

	display: block;
	min-height: 100%;
	transition: border-color 0.25s ease-in-out;
	// Create new stacking context
	transform: scaleZ(1);

	@include bp('small') {
		@include flex-extend;
		width: 100%;

		&.o-tile--column {
			display: block;
		}
	}

	&:hover {
		.o-tile__image {
			transform: scale(1.1);
		}
	}
}

// Figure sits alongside body, is full height
.o-tile__figure {
	position: relative;
	width: 100%;
	overflow: hidden;

	@include bp("small") {
		flex: 0 0 40%;
		align-self: stretch;
		max-width: 220px;

		.o-tile--column > & {
			max-width: none;
		}

		.o-tile--even > & {
			flex: 0 0 50%;
			max-width: none;
		}
	}
}

.o-tile__figure--short {
	@include bp("small") {
		max-width: 130px;
	}
}

.o-tile__image {
	@include link-transition;
	height: 100%;
	display: block;
	width: 100%;
	max-width: 100%;
}

// Body sits alongside media, has internal padding
.o-tile__body {
	flex-grow: 1;
	padding: $bsu;

	.o-tile__figure + & {
		padding: $ssu $bsu;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.o-tile__content {
	align-self: center;
	flex-grow: 1;

	.o-tile__content ~ &:last-child,
	&.o-tile__content--shrink {
		// If there are multiple consecutive contents,
		// the last one should shrink and have padding
		// as it will contain a button
		flex-grow: 0;
		flex-shrink: 0;
		padding-left: $bsu;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

.o-tile__category {
	@include font-base-12;
	padding-bottom: $ssu;
	color: $brand;
}

.o-tile__heading {
	@include font-base-18b;
	display: block;
	margin-bottom: $ssu;
	color: $body-color;
}

.o-tile__meta {
	@include font-base-12b;
	display: block;
	margin: $ssu 0;
	color: $body-color;
}

.o-tile__meta-item {
	display: inline-block;
	margin-right: $tsu;
}

.o-tile__meta-key {
	@include font-base-12b;
}

.o-tile__meta-value {
	&::after {
		content: ", ";
	}
	&:last-child {
		&::after {
			display: none;
		}
	}
}

.o-tile__text {
	@include font-body-text;
	display: block;
	color: $body-color;

	&[draggable="false"] {
		display: inline;
	}
}
