// COMPONENT.MEMBER-AUTH

.c-member-auth__page {
	.o-layout-header__heading,
	.o-layout-header__lede {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}

.c-member-auth__layout {
	@include layout-wrapper;
}

.c-member-auth__heading {
	@include control__heading;
}

.c-member-auth__form {
	max-width: 400px;
	margin: 0 auto;

	.c-login-selector__item-body &,
	.c-tabbed-booking__login-form & {
		@include flex-extend;
		align-items: center;
		max-width: 100%;
		flex-direction: column;
		gap: 0;

		@include bp("medium") {
			flex-direction: row;
			align-items: flex-end;
			gap: $bsu;
		}
	}
}

.c-member-auth__profile {
	max-width: 400px;
	margin: 0 auto $lsu;

	.c-tabbed-booking__login-form & {
		margin: 0;
	}
}

.c-member-auth__link {
	&:hover {
		text-decoration: underline;
	}
}

.c-member-auth__remember-link {
	display: flex;
	align-items: center;

	.c-member-auth__reset-checkbox {
		width: 24px;
		height: 24px;
		border: 1px solid #E6E7E8;
		margin-right: 10px;
	}

	.c-member-auth__reset-label {
		@include font-base-14;
	}
}

.c-member-login__submit {
	@include button("small");
	@include btn-primary;
	width: 100%;
	max-width: 400px;
	min-width: 150px;
}

.c-member-auth__reset-link {
	max-width: 400px;
	margin: -20px auto $lsu;
	text-align: center;
	display: block;
}

.c-member-auth__modal-box {
	padding: $lsu $bsu;
}

.c-member-auth__error {
	padding: 10px;
	font-size: 13px;
	color: $error !important;
	width: 100%;
	text-align: center;

	a {
		color: $blue;
		@include link-transition;

		&:hover {
			color: $dark-blue;
		}
	}
}