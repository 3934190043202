﻿@use "sass:math";

.c-hero--park {

	@include bp("medium") {
		padding: 60px 0 40px;
	}

	@include bp("large") {
		padding: 60px 0;
	}
}

.c-hero--park__layout {
}

.c-hero--park__container {
	display: flex;
	flex-direction: column-reverse;

	@include bp("medium") {
		flex-direction: column;
	}
}

.c-hero--park__gallery-wrapper {
	width: 100%;
	min-height: 70px;

	@include bp("medium") {
		@include layout-wrapper;
		min-height: 0px;
	}
}

$hero-ranking-height: 24px;

.c-hero--park__header {
	padding: 0 $bsu;

	@include bp('medium') {
		@include layout-wrapper;
		width: 100%;
	}
}

.c-hero--park__header-details {
	position: relative;
	padding-top: $bsu + math.div($hero-ranking-height, 2);
	padding-bottom: $bsu;

	@include bp('medium') {
		display: grid;
		grid-template-areas: 'ranking' 'heading' 'location';
		grid-template-columns: 1fr;
		padding: 0 0 $lsu;

		&.has-reviewpro {
			grid-template-areas: 'ranking reviewpro' 'heading reviewpro' 'location reviewpro';
			grid-template-columns: 1fr 226px;
			grid-template-rows: auto auto 1fr;
			grid-column-gap: $lsu;
		}
	}
}

.c-hero--park__ranking {
	@include font-base-12b;
	position: absolute;
	top: 0;
	transform: translateY(-50%);
	height: 24px;
	border-radius: 12px;
	width: fit-content;
	line-height: 20px;
	color: $white;
	padding: 0 10px 0 5px;
	z-index: 5;

	&.Premium {
		background: $premium;
	}

	&.Superior {
		background: $superior;
	}

	&.Classic {
		background: $classic;
	}

	& span {
		display: inline-block;
		vertical-align: sub;
		height: 15px;
		width: 15px;
		margin-top: 4px;
		margin-right: 4px;
	}

	@include bp("medium") {
		position: static;
		grid-area: ranking;
		transform: none;
		height: 36px;
		padding: 5px 30px 5px 25px;
		border-radius: 18px;
		margin-bottom: $bsu;
	}
}

.c-hero--park__heading {
	@include font-heading-24;
	margin-bottom: $bsu;
	color: $brand--alt;

	@include bp('medium') {
		@include font-heading-32;
		grid-area: heading;
		margin-bottom: $ssu * 1.5;
	}

	@include bp('large') {
		@include font-heading-48;
	}
}

.c-hero--park__locations {

	@include bp('medium') {
		grid-area: location;
	}

	a {
		@include font-base-18;
		@include link-transition;
		display: block;
		margin: 0 0 $ssu * 1.5;
		color: $blue;

		@include bp("medium") {
			display: inline-block;
			margin: 0 30px 0 0;
		}

		&:last-child {
			margin: 0;
		}

		span {
			@include font-base-16;
			margin-right: 5px;
		}

		&:hover {
			color: $dark-blue;
		}
	}
}

.c-park-hero__reviewpro {
	width: 226px;
	min-height: 146px;
	margin-top: $bsu;
	position: relative;

	@include bp('medium') {
		grid-area: reviewpro;
		align-self: end;
		margin-top: 0;
	}

	#rp-widget-dialog {
		left: -10px !important;
		top: 50px !important;
		width: 300px;

		@include bp("medium") {
			left: -120px !important;
			top: -20px !important;
		}

		.tooltip-bubrt {
			background: #fff !important;
			border-radius: 5px;
			filter: drop-shadow(0px 16px 30px rgba(0, 0, 0, 0.2));
			margin: 0px !important;
			width: 300px !important;
			z-index: 5 !important;

			&:before {
				content: '';
				width: 0;
				height: 0;
				position: absolute;
				border-left: 15px solid transparent;
				border-right: 15px solid transparent;
				border-bottom: 15px solid white;
				left: 205px;
				top: -15px;

				@include bp("medium") {
					border-top: 15px solid transparent;
					border-bottom: 15px solid transparent;
					border-left: 15px solid white;
					left: 300px;
					top: 20px;
				}
			}

			> div {
				background: none !important;

				.tooltip-content {
					padding: 15px !important;

					h5 {
						font-family: "sofia-pro" !important;
						font-size: 14px !important;
						font-weight: 700 !important;
						line-height: 20px !important;
						color: #2695D3 !important;
					}

					p {
						font-family: "sofia-pro" !important;
						font-size: 12px !important;
						line-height: 18px !important;
						font-weight: 400 !important;
					}
				}
			}
		}
	}
}
