﻿.l-member-page ~ .c-footer {
	padding-bottom: 65px;

	@include bp('medium') {
		padding-bottom: 0px;
	}
}

.c-member-page-header {
	overflow: hidden;

	.c-member-page-header__wrapper {
		display: block;

		@include bp("large") {
			display: flex
		}

		.c-member-page__divider {
			width: 100%;

			@include bp("large") {
				width: 50%;
			}
		}
	}

	&.c-member-page__card {
		position: relative;

		&::before,
		&::after {
			position: absolute;
			content: "";
			bottom: 0;
		}

		&::before {
			width: 260px;
			height: 130px;
			background-color: $yellow;
			border-radius: 260px 260px 0 0;
			z-index: 2;
			left: -40px;

			@include bp('medium') {
				width: 280px;
				height: 140px;
				left: auto;
				right: 40px;
			}
		}

		&::after {
			width: 400px;
			height: 200px;
			background-color: $blue--light;
			border-radius: 400px 400px 0 0;
			z-index: 1;
			left: -100px;

			@include bp('medium') {
				width: 552px;
				height: 276px;
				left: auto;
				right: -96px;
			}
		}
	}
}

.c-page-header__layout {
	.c-member-page__card &{
		position: relative;
		z-index: 3;
	}
}

.c-member-page-header-wrap {
	@include flex-extend;
	flex-direction: column;
	gap: $bsu;


	@include bp("medium") {
		flex-direction: row;
	}

	.c-member-page-header-content {
		@include bp("medium") {
			width: 70%;
		}
	}

	.c-member-page-barcode {
		padding: ($bsu * 1.5) $bsu;
		background-color: $white;
		border-radius: 5px;
		text-align: center;

		@include bp("medium") {
			padding: $bsu $lsu;
			max-width: 340px;
			margin: 0 auto;
		}

		@include bp("large") {
			max-width: 100%;
		}

		svg {
			width: 100%;
		}
	}
}

.c-member-page-header__img-wrap {
	@include flex-extend;
	flex-direction: row;

	@include bp("medium"){
		display: none;
	}
}

.c-member-page-header__img {
	max-width: 150px;
	margin: $bsu;
	position: relative;
	left: 150px;
}

.c-member-page__greeting {
	color: $grey--darkest;
	margin-bottom: $bsu;
	@include font-base-16;

	@include bp("medium") {
		@include font-base-18;
	}
}

.c-member-page__status {
	display: block;
	align-items: center;
	margin: 0 auto $bsu;
	width: fit-content;

	@include bp("medium") {
		display: flex;
	}

	@include bp("large") {
		margin: 0;
		margin-bottom: 40px;
	}

	form {
		display: block;

		@include bp("medium") {
			display: flex;
		}
	}

	.c-member-page__red-warning {
		display: flex;
		align-items: center;

		span {
			display: block;
			height: 22px;
			width: 22px;
			text-align: center;
			background: $red;
			border-radius: 50%;
			color: $white;
			padding: 3px 3.5px;
		}

		p {
			@include font-base-18;
			display: block;
			margin: 0 $bsu;
			color: $red;
		}
	}

	.c-member-page__status-button {
		@include button("small");
		@include btn-primary;
		width: 100%;
		margin-top: 20px;

		@include bp("medium") {
			display: flex;
			width: fit-content;
			margin-top: 0px;
		}
	}
}

.c-member-page__intro-text {
	text-align: center;

	@include bp("large") {
		text-align: left;
	}
}

.c-member-page__radial {
	height: 420px;
	width: 420px;
	padding: 60px;
	border-radius: 50%;
	margin: 60px auto 0;
	position: relative;
	left: 50%;
	transform: translateX(-50%);

	@include bp("small") {
		transform: none;
		left: 0;
	}

	@include bp("medium") {
		height: 570px;
		width: 570px;
		padding: 82px;
	}

	&.Bronze {
		background: $bronze-gradient;

		.c-member-page__radial-score,
		.c-member-page__radial-status {
			color: $bronze;
		}
	}

	&.Silver {
		background: $silver-gradient;

		.c-member-page__radial-score,
		.c-member-page__radial-status {
			color: $silver;
		}
	}

	&.Gold {
		background: $gold-gradient;

		.c-member-page__radial-score,
		.c-member-page__radial-status {
			color: $gold;
		}
	}

	.c-member-page__radial-inner {
		height: 300px;
		width: 300px;
		border-radius: 50%;
		background: $dark-blue;
		text-align: center;
		position: relative;
		z-index: 1;

		@include bp("medium") {
			height: 405px;
			width: 405px;
			position: relative;
			z-index: 2;
		}

		@include bp("large") {
			position: relative;
			z-index: 2;
		}
	}
}

.c-member-page__radial-score {
	display: flex;
	justify-content: center;
	align-content: baseline;
	padding-top: 40px;
	margin-bottom: $ssu;

	@include bp("medium") {
		padding-top: 100px;
	}

	.c-member-page__radial-score-low {
		@include font-heading-32;

		@include bp("medium") {
			@include font-heading-48;
		}
	}

	.c-member-page__radial-score-high, span {
		@include font-heading-20;
		margin-top: 10px;

		@include bp("medium") {
			@include font-heading-32;
			margin-top: 20px;
		}
	}
}

.c-member-page__radial-strap {
	color: $white;
	@include font-base-14b;

	@include bp("medium") {
		@include font-base-16b;
	}
}

.c-member-page__radial-status {
	margin-top: 20px;
	@include font-heading-24;

	@include bp("medium") {
		margin-top: 40px;
	}
}

.c-member-page__radial-status-message {
	color: $white;
	max-width: 250px;
	margin: 0 auto;

	@include font-base-14b;

	@include bp("medium") {
		max-width: 350px;
		@include font-base-16b;
	}
}

//member bart
.c-member-bar {
	padding: ($bsu + $ssu) 0;
	background: $dark-blue;
	margin: -120px 0 -40px;
	position: relative;
	z-index: 2;

	@include bp("medium") {
		padding: ($bsu + $lsu) 0;
		margin: -180px 0 -40px;
		z-index: 2;
	}

	@include bp("large") {
		margin: -290px 0 -40px;
		z-index: 1;
	}

	.c-member-bar__wrapper {
		width: 100%;
		display: flex;

		@include bp("large") {
			width: 50%;
		}

		.c-member-bar__divider {
			width: 50%;

			.c-member-bar__header {
				@include font-base-16b;
				color: $blue--light;
				margin-bottom: $ssu;

				@include bp("medium") {
					@include font-heading-20;
				}
			}

			.c-member-bar__value {
				@include font-heading-24;
				color: $white;

				@include bp("medium") {
					@include font-heading-40;
				}
			}
		}
	}
}

.c-member-body {
	display: flex;
	max-width: 1000px;
	width: fit-content;
	margin: 60px auto;

	.c-member-pill {
		height: 80px;
		width: 80px;
		padding: 10px;
		border-radius: 50%;

		@include bp("medium") {
			height: 160px;
			width: 160px;
			padding: 22px;
		}

		.c-member-pill__inner {
			height: 60px;
			width: 60px;
			border-radius: 50%;
			text-align: center;
			color: $grey--darkest;
			text-transform: uppercase;
			padding-top: 20px;
			@include font-base-14b;

			@include bp("medium") {
				height: 116px;
				width: 116px;
				padding-top: 45px;
				@include font-base-16b;
			}
		}

		&.Bronze {
			background: $bronze-gradient;

			.c-member-pill__inner {
				background: $bronze;
			}
		}

		&.Silver {
			background: $silver-gradient;

			.c-member-pill__inner {
				background: $silver;
			}
		}

		&.Gold {
			background: $gold-gradient;

			.c-member-pill__inner {
				background: $gold;
			}
		}
	}

	.c-member-body__copy {
		margin-left: 20px;
		margin-top: 0px;

		@include bp("medium") {
			margin-left: 30px;
			margin-top: 15px;
		}

		.c-member-body__heading {
			@include font-heading-20;
			color: $grey--darkest;

			@include bp("medium") {
				@include font-heading-40;
			}
		}

		.c-member-body__text {
			margin-top: 0px;
			@include font-base-14;

			@include bp("medium") {
				margin-top: 20px;
				@include font-base-16;
			}
		}
	}
}
