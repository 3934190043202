﻿// CONTROL.FORM

@use "sass:math";

.c-form {
	@include control;

	.c-form__input,
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="date"],
	input[type="number"],
	select,
	textarea {
		@include form-input;
		position: relative;

		&:disabled,
		&:read-only {
			border-color: $grey--light !important;
			background-color: $white !important;
			color: $body-color !important;
		}
	}

	input[type="date"] {
		display: block;
		min-width: 96%;
		text-align: left;
		text-align: -webkit-left;
	}

	@include bp-below("medium") {

		input[type="date"] {
			-webkit-appearance: none !important;
			text-align: left !important;

			&::-webkit-date-and-time-value {
				text-align: left !important;
			}
		}
	}

	.c-park-finder__filters-dropdown-wrapper &,
	.c-login-selector__item-body &,
	.c-slideout &,
	.c-tabbed-booking-control & {
		margin: 0;
	}

	select ~ .field-validation-error {
		border-color: $error;
	}

	textarea {
		height: 150px !important;
	}

	.c-form__input-btn {
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		i {
			font-size: 18px;
			margin-right: -10px;
		}
	}

	&.align-center {
		text-align: center;
		justify-content: center;
	}
}

input[type="checkbox"].c-form__input,
input[type="radio"].c-form__input {
	display: inline-block;
	vertical-align: top;
	height: 25px;
	width: 25px;
}

input[type="checkbox"].c-form__input + .c-form__label,
input[type="radio"].c-form__input + .c-form__label {
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
}

.c-form-horizontal {
	display: block;
	padding: 0;
	gap: 20px;

	@include bp("medium") {
		display: flex;
		align-items: end;
		padding: 0;
		flex-wrap: wrap;
	}

	.c-form__field {
		flex: 1;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.c-form__submit-btn {
		min-width: 150px;
	}
}

.c-form-shortform {
	max-width: 400px;
	margin: 0 auto;
}

.c-form__layout {
	@include layout-wrapper;
}

.c-form__heading {
	@include yellow-line-heading;
	color: $brand--alt;
}

.c-form__number-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	height: auto;
	max-width: 200px;
	justify-content: space-between;
	margin: 0 auto;

	.c-form-number {
		display: flex;
	}

	.number-input {
		border-color: $grey !important;
		background: $grey--lightest !important;
		color: $grey--dark !important;
	}

	.decrementer, .incrementer {
		@include button-size("small");
		@include btn-secondary;
		height: 36px !important;
		width: 36px !important;
		line-height: 1;
		//margin: 5px;
		padding: 0;
		font-size: 20px;
		text-align: center;
		color: $dark-blue;
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1 0 auto;

		.c-room-details__availability__body-content & {
			flex: 0 auto;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.c-form__field {
	width: 100%;
	margin-bottom: $tsu;

	@include bp("medium") {
		margin-bottom: $ssu;
	}

	&.c-form__field__guest {
		position: relative;
	}

	.c-tabbed-booking__login-form & {

		@include bp("medium") {
			margin-bottom: 0;
		}
	}

	.c-park-finder__filters-dropdown-wrapper & {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-form__autocomplete {
	position: relative;

	.c-form__input {
		padding-right: ($bsu * 1.5) !important;
	}
}

.c-form__autocomplete__icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: $ssu;
	color: $brand;
}

.c-form__autocomplete-panel {
	position: absolute;
	width: 100%;
	list-style: none;
	background-color: $white;
	padding: $ssu $bsu;
	max-height: 150px;
	overflow: auto;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
	margin-top: 0;
	z-index: 5;
}

.c-form__autocomplete__heading {
	@include font-base-16b;
	margin-top: $ssu;

	&:first-child {
		margin-top: 0;
	}
}

.c-form__autocomplete__item {
	@include font-base-14;
	padding: $tsu 0 0;
	cursor: pointer;

	&:hover {
		color: $brand;
	}
}

.c-form__field-guest-wrap {
	padding: $bsu;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.c-form__field label {
	@include font-base-14b;

	@include bp("medium") {
		@include font-base-16b;
	}

	line-height: 30px;
	display: block;
}

.c-form__radio-set {
	margin-bottom: 20px;

	.c-form__field {
		display: grid;
		grid-template-areas: 'input label' 'input note';
		grid-template-columns: 20px 1fr;
		grid-column-gap: $ssu;

		@include bp('large') {
			grid-template-areas: 'input label note';
			grid-template-columns: 20px auto 1fr;
		}

		.c-form__input {
			grid-area: input;
			display: block;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			position: relative;
			top: 6px;

			@include bp('medium') {
				top: 3px;
			}
		}

		.c-form__label {
			grid-area: label;
		}

		.c-form__note {
			grid-area: note;
		}
	}
}

.c-form__radio-set-label {
	margin-bottom: $ssu;
}

.c-form__required-indicator {
	color: $red;
}

.c-form__controls {
	margin-top: $bsu;

	.c-tabbed-booking__panel-body & {
		position: absolute;
		top: calc(100% + #{$bsu});
		transform: translateY(-50%);
		width: 100%;
		margin-top: 0;
		text-align: center;

		@include bp(769px) {
			position: static;
			transform: none;
			width: auto;
		}
	}

	.c-accomodation-search & {

		@include bp(769px) {
			margin-top: 24px;
		}
	}
}

.c-form__control {
	@include button;
}

.c-form__fields-set {
	display: flex;
	flex-direction: column;
	gap: 0;

	@include bp("medium") {
		flex-direction: row;
		gap: $bsu;
	}

	@include bp("large") {
		gap: $lsu;
	}
}

.c-form__field-group {
	flex: 0 0 100%;

	@include bp("medium") {
		flex: 0 0 calc(50% - #{$ssu});
	}

	@include bp("large") {
		flex: 0 0 calc(50% - #{$bsu});
	}
}

.c-form__doublebox {
	display: flex;

	.c-form__label {
		display: block;
	}

	span {
		display: block;
		width: 20px;
		margin: 7px 5px;
	}
}

.c-form__field-group-two-col {
	display: flex;
	flex-direction: column;

	@include bp("medium") {
		flex-direction: row;
		gap: $ssu;
	}

	.c-form__field {
		flex: 1 auto;
	}
}

.c-form__field-compound {
	display: flex;
	flex-direction: row;
	gap: 10px;

	.c-form__input {
		&.Third {
			width: 33.3%;
		}

		&.Twothird {
			width: 66.6%
		}
	}
}

.c-form__submit-btn {
	@include button-size("small");
	@include btn-primary;
	margin-top: $bsu;

	&.c-form__submit-btn__guest {
		@include btn-secondary;
		margin-top: $bsu;
	}

	.c-change-booking-form & {
		margin-top: 0;
		width: 100%;
		max-width: 400px;
	}

	&.loading {
		display: none;
	}
}

.validation-summary-valid {
	display: none;
}

.c-form__input {
	&.error {
		box-shadow: 0 0 5px $error !important;
	}
}

.field-validation-error,
.c-form__error-message,
.validation-summary-errors {
	font-size: 13px;
	color: $error !important;
	width: 100%;
}

.c-form__error-message {
	display: none;
}

.has-error .c-form__error-message {
	display: block;
}

.umbraco-forms-navigation {
	//text-align: right;
}

.titleanddescription {
	h2 {
		@include font-heading-40;
		margin-bottom: 0.5em;
	}

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

// Fake inputs
.c-fake-checkbox {
	@include fake-input;

	&:checked ~ .c-fake-checkbox__display {
		&::before {
			border-color: $blue;
		}
	}

	&:focus ~ .c-fake-checkbox__display {
		@include focus-outline;
	}
}

.c-fake-checkbox__display {
	@include fake-input__display;

	@include link-transition;

	&::before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 4px;
		top: 5px;
		display: block;
		width: 16px;
		height: 8px;
		border: 2px solid transparent;
		border-width: 0 0 2px 2px;
		background: transparent;
		transform: rotate(-45deg);
	}
}

// Fake Radio
.c-fake-radio {
	@include fake-input;

	&:checked ~ .c-fake-radio__display {
		&::before {
			background-color: $blue;
		}
	}

	&:focus ~ .c-fake-radio__display {
		@include focus-outline;
	}
}

.c-fake-radio__display {
	@include fake-input__display;
	border-radius: 25px;

	@include link-transition;

	&::before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 3px;
		top: 3px;
		display: block;
		width: 17px;
		height: 17px;
		border-radius: 17px;
		background-color: transparent;
	}
}

// Fake file
.c-fake-file {
	@include fake-input;
}

.c-fake-file__display {
	@include fake-input__display;

	@include flex-extend;

	.c-fake-file:focus + & {
		@include focus-outline;
	}

	&.has-error {
		.c-fake-file__display-value {
			border-color: $error;
		}

		.c-fake-file__display-button {
			background: $error;
		}
	}

	cursor: pointer;

	.c-fake-file:disabled + & {
		cursor: not-allowed;

		.c-fake-file__display-value {
			background: $grey--light;
		}

		.c-fake-file__display-button {
			cursor: not-allowed;
			color: $white;

			&,
			&:hover,
			&:focus {
				background-color: $grey;
			}
		}
	}
}

.c-fake-file__display-value {
	@include form-input;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&:not(.has-value) {
		color: $grey;
	}
}

.c-fake-file__display-button {
	margin-left: $ssu;

	@include flex-extend;
	align-items: center;
	justify-content: center;
}

/*====-=---------------------------------------  Umbraco Forms  ---------------------------------------=-====*/

.umbraco-forms-form {
	@include control;
	max-width: 600px;
	margin: 0;

	.umbraco-forms-field {
		margin-bottom: $bsu;

		.umbraco-forms-field-wrapper {
			clear: both;

			input[type="text"],
			input[type="password"],
			input[type="email"],
			input[type="tel"],
			select,
			textarea {
				@include form-input;
				max-width: 100% !important;
				margin: 0;
			}

			select ~ .field-validation-error {
				border-color: $error;
				margin-bottom: $tsu;
			}

			textarea {
				height: 150px;
			}

			.date & {
				position: relative;

				@include bp("small") {
					max-width: 250px;
				}

				&:after {
					position: absolute;
					display: block;
					right: $ssu;
					top: 15px;
					font-family: #{$icomoon-font-family};
					content: $iconf-calendar;
					pointer-events: none;
				}
			}

			.checkbox & {
				margin-top: 0;
			}

			.singlecheckbox & {
				order: -1;
				margin-right: $tsu;
			}

			.titleanddescription & {
				margin-top: 0;
			}

			.checkboxlist,
			.radiobuttonlist {
				label {
					display: inline-block;
					margin-bottom: math.div($ssu, 2);
					margin-left: 3px;
				}

				label:last-of-type {
					margin-bottom: 0;
				}
			}

			.checkboxlist {
				padding: $ssu 0 !important;
				float: none !important;

				label {
					float: none !important;
				}
			}

			span.contourError,
			span.field-validation-error {
				color: $error !important;
				margin: 0;
			}
		}

		&.checkbox {
			position: relative;
			padding-left: $bsu;

			input[type="checkbox"] {
				position: absolute;
				left: 0;
				top: $tsu;
			}
		}
	}

	.umbraco-forms-caption {
		@include font-base-18b;
		margin-bottom: 1.5em;
	}

	.umbraco-forms-fieldset {
		@include ui-border('bottom');
		padding: 0;
		margin: 0;

		legend {
			padding-bottom: $bsu;
		}
	}

	.umbraco-forms-tooltip {
		font-size: 12px;
		font-style: italic;
		line-height: 1.5;
		color: #666;
		margin-top: -5px;
		display: block;
	}

	&:last-child {
		margin-bottom: 0;
	}



	.umbraco-forms-label-wrapper {
		order: 2;
	}

	.umbraco-forms-label, .c-form__label {
		@include font-base-16b;
		line-height: 30px;
		display: block;
	}

	.btn {
		@include button;
		@include btn-primary;

		@include bp("large") {
			@include button-size("medium");
		}
	}
}


.c-skip__button {
	color: $blue--light;
	margin: 10px 30px;

	@include link-transition;

	&:hover {
		color: $dark-blue;
	}
}

.c-form__submit-btn-loader {
	display: none;
	border: 4px solid #f3f3f3;
	border-radius: 50%;
	border-top: 4px solid #3498db;
	width: 44px;
	height: 44px;
	-webkit-animation: spin 1s linear infinite; /* Safari */
	animation: spin 1s linear infinite;
	margin-top: 23px;

	&.loading {
		display: block;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.c-form__edit-cart {
	margin-top: 0px;
}