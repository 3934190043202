﻿.c-tabbed-booking-control {
	position: relative;
	max-width: 1200px;
	width: 100%;
	margin: -50px auto 0;
	z-index: 1;

	@include bp("medium") {
		margin: -150px auto 0;
	}
}

.c-tabbed-booking__wrapper {
	margin: 0 15px;
	background: $white;
	@include radius;
	@include box-shadow;
	overflow: inherit;
}

.c-tabbed-booking__tablist {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	height: 50px;
	width: 100%;

	@include bp("medium") {
		height: 60px;
	}
}

.c-tabbed-booking__tab {
	width: 50%;
	height: 50px;
	padding: ($ssu + 5) $bsu;
	@include font-heading-20;
	line-height: 16px;
	background: $blue--light;
	color: $white;
	text-align: center;
	box-shadow: inset 0px -4px 15px rgba(0, 0, 0, 0.1);
	@include link-transition;

	&:hover {
		cursor: pointer;
		color: $dark-blue;
	}

	&[aria-expanded=true] {
		background: $white;
		color: $grey--darkest;
		box-shadow: none;

		&:hover {
			cursor: default;
		}
	}

	& span {
		display: none;
		margin-left: 5px;
	}

	@include bp("medium") {
		height: 60px;
		text-align: left;
		padding: $bsu;

		& span {
			display: inline-block;
		}
	}

	@include bp('extra-large') {
		padding-left: $lsu;
		padding-right: $lsu;
	}
}

.c-tabbed-booking__panel-body {
	padding: $bsu;
	display: none;
	min-height: 140px;

	@include bp("large") {
		padding: $bsu $lsu;
	}
}

.c-tabbed-booking__panel[aria-expanded=true] .c-tabbed-booking__panel-body {
	display: block;
}

.c-tabbed-booking__panel {
	.c-accomodation-search {
		.c-accomodation-search__input-container {
			position: relative;
			display: block;
			padding-bottom: $bsu;

			@include bp("medium") {
				display: flex;
			}

			@include bp(769px) {
				padding-bottom: 0;
			}

			.c-expand-collapse__body-wrapper {
				display: block;

				@include bp("small") {
					display: flex;
				}
			}

			.c-accomodation-search__input-wrapper {
				width: 100%;
				display: block;

				@include bp("small") {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
				}

				@include bp("medium") {
					width: auto;
					flex: 1 1 auto;
				}

				.c-form__field {
					width: 100%;
					padding-right: 10px;

					@include bp("small") {
						width: 33%;
					}

					@include bp("large") {
						width: 33%;
					}
				}

				.c-form__field-wrap {
					width: 100%;
					padding-right: 10px;

					@include bp("small") {
						width: 50%;
					}
				}

				.c-form__calender-wrap {
					width: 100%;
					display: flex;
					flex-direction: row;
					position: relative;


					.c-form__field {

						@include bp("large") {
							width: 50%;
						}

						&:last-child {
							padding-right: 0;
						}
					}
				}

				.c-form__field-guest-wrap {
					z-index: 2;
					background-color: $white;
					width: 100%;

					@include bp("small") {
						width: 100%;
						position: absolute;
					}

					.c-form__field {

						@include bp("small") {
							width: 100%;
						}

						@include bp("large") {
							width: 100%;
						}
					}
				}
			}

			.c-accomodation-search__expander-trigger {
				position: relative;
				padding-bottom: $lsu;

				@include bp(769px) {
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					padding-bottom: 0;
				}
			}

			.xc-form__controls {
				position: absolute;
				top: calc(100% + #{$bsu});
				transform: translateY(-50%);
				width: 100%;
				margin-top: 0;
				text-align: center;

				@include bp(769px) {
					position: static;
					transform: none;
					width: auto;
					margin-top: 24px;
				}
			}

			.c-form__input-date {
				min-width: 95%;
				-webkit-appearance: none;
				-moz-appearance: none;
				position: relative;
			}
		}
	}
}

.c-tabbed-booking__panel-body-wrapper {

	&.two-col {
		display: grid;
		grid-template-columns: 1fr;

		@include bp("medium") {
			grid-template-columns: 1fr 1fr;
		}
	}
}

.c-tabbed-booking__panel-inner {

	&[aria-expanded=true] {
		display: block;

		@include bp("medium") {
			display: block;
		}
	}

	&[aria-expanded=false] {
		display: none;

		@include bp("medium") {
			display: block;
		}
	}

	&:first-child {
		@include bp("medium") {
			@include ui-border("right");
			padding-right: $bsu;
		}

		@include bp("extra-large") {
			padding-right: $lsu;
		}
	}

	&:last-child {
		@include bp("medium") {
			padding-left: $bsu;
		}

		@include bp("extra-large") {
			padding-left: $lsu;
		}
	}
}

.c-tabbed-booking__login-form {
	position: relative;
	padding-bottom: $lsu;

	@include bp('medium') {
		padding-bottom: 0;
	}

	.c-form {
		margin: 0;
	}

	.c-member-auth__layout {
		padding: 0;
	}
}

.c-tabbed-booking__login-form-heading {
	@include yellow-line-heading;
	margin-bottom: 20px;
	display: none;

	&::after {
		width: 60px;
		bottom: -5px;
	}

	@include bp("medium") {
		@include font-base-16b;
		display: block;
	}
}

a.c-tabbed-booking__login-form-heading {
	color: $brand;

	&::after {
		display: none;
	}
}

.c-tabbed-booking__mini-tablist {
	display: flex;

	@include bp("medium") {
		display: none;
	}

	.c-tabbed-booking__mini-tab {
		@include font-base-16b;
		color: $blue--light;
		width: 50%;
		cursor: pointer;

		&.active {
			@include yellow-line-heading;
			@include font-base-16b;
			color: $grey--darkest;
			cursor: default;

			&:after {
				width: 50px;
				bottom: -5px;
			}
		}
	}
}

.c-accomodation-search {
	.c-slideout & {
		padding: $bsu;
	}
}
