// CONTROL.INSPIRATION-PODS

.c-inspiration-pods {
	@include control;
}

.c-inspiration-pods__layout {
	@include layout-wrapper;
}

.c-inspiration-pods__head {
	display: block;
	margin-bottom: $bsu;

	@include bp("large") {
		display: flex;
		margin-bottom: 0;
	}

	.c-inspiration-pods__heading {
		@include yellow-line-heading;
		color: $dark-blue;
	}

	.c-inspiration-pods__links {
		padding-top: 0;
		margin-left: 0;

		.c-inspiration-pods__link {
			@include font-base-14;
			margin-right: $lsu;
			color: $blue;

			@include link-transition;

			&:hover {
				color: $dark-blue;
			}
		}

		@include bp("large") {
			margin-left: $lsu;
			padding-top: $bsu;
		}
	}
}

.c-inspiration-pods-items {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-template-areas: 'pod-big' 'pod-small-one' 'pod-small-two';
	grid-gap: $bsu;

	@include bp("medium") {}

	@include bp("large") {
		grid-template-columns: repeat(2, 1fr);
		grid-template-areas: 'pod-big pod-small-one' 'pod-big pod-small-two';
		grid-gap: $lsu;
	}
}

.c-inspiration-pods-item {
}

.c-inspiration-pods-item__big {
	grid-area: pod-big;
}

.c-inspiration-pods-item__small {
	&:nth-child(1) {
		grid-area: pod-small-one;
	}
	&:nth-child(2) {
		grid-area: pod-small-two;
	}
}

.c-inspiration-pods-item__heading,
.c-inspiration-pods-item__heading-link {
	color: $secondary-a;
	color: var(--secondary-a);
	@include font-base-18b;

	@include bp("medium") {
		@include font-heading-24;
	}

	@include bp("large") {
		@include font-heading-32;
	}

	.c-inspiration-pods-item__small & {
		@include bp("medium") {
			@include font-heading-24;
		}

		@include bp("large") {
			@include font-heading-24;
		}
	}
}

.c-inspiration-pods-item__figure {
	align-self: flex-start;
}

.c-inspiration-pods-item__image-desktop {
	display: none;

	@include bp('small') {
		display: block;
	}
}

.c-inspiration-pods-item__image-mobile {

	@include bp('small') {
		display: none;
	}
}