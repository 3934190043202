﻿// CONTROL.IMAGE-FEATURE

@mixin image-feature--wide {
	.c-image-feature__layout {
		@include flex-extend;
		padding: 0;
	}

	.c-image-feature__layout--right {
		flex-flow: row-reverse;
	}

	.c-image-feature__figure {
		flex: 0 0 50%;
		align-self: stretch;
		margin-bottom: 0;
	}

	.c-image-feature__image {
		height: 100%;
	}

	.c-image-feature__body {
		align-self: center;
		padding: ($bsu * 3) ($bsu * 2);
	}
}

.l-page:not(.l-page--has-sidebar) {
	@include bp("medium") {
		@include image-feature--wide;
	}
}
.l-page--has-sidebar {
	@include bp("medium") {
		@include bp-below("large") {
			@include image-feature--wide;
		}
	}
	@include bp("extra-large") {
		@include image-feature--wide;
	}
}

.c-image-feature {
	@include control;
}

.c-image-feature__layout-wrapper {
	@include layout-wrapper;
}

.c-image-feature__layout {
	background-color: $white;
	overflow: hidden;
}

.c-image-feature__layout--right {}

.c-image-feature__figure {
	@include figure;
}

.c-image-feature__image {}

.c-image-feature__body {
	padding: $bsu;
	text-align: left;
	width: 100%;

	@include bp("large") {
		padding: $bsu * 4;

		.l-page--has-sidebar & {
			padding: ($bsu * 3) ($bsu * 2);
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__heading {
	@include font-base-18b;
	position: relative;
	margin-bottom: 0.5em;
	color: $secondary-a;

	&.t-before-bg--rev {
		color: $yellow;
	}

	@include bp("medium") {
		@include font-heading-24;
		margin-bottom: 1.35em;
	}

	@include bp("large") {
		@include font-heading-32;
		margin-bottom: 1.35em;
	}
}

.c-image-feature__text {
	@include body-text;

	margin-bottom: $bsu;

	@include bp("large") {
		margin-bottom: $bsu * 1.5;
	}
}

.c-image-feature__link {
	@include button("small");
	@include btn-primary;
}
