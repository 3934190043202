﻿.c-bookings {
}

.c-bookings__booking {
	padding: $bsu;
	background: $dark-blue;
	margin-bottom: 40px;
	position: relative;

	@include font-base-14;
	@include radius;
	overflow: visible;

	@include bp("medium") {
		@include font-base-16;
	}
}

.c-bookings__booking-status {
	position: absolute;
	right: 0;
	top: 0;
	width: 110px;
	height: 75px;
	padding: 10px 20px;
	text-align: center;
	white-space: nowrap;
	border-radius: 0 3px 0 0;
	clip-path: circle(75px at 75px -15px);

	&.upcoming {
		background: $yellow;
		color: $dark-blue;
	}

	&.cancelled {
		background: $red;
		color: $white;
	}

	&.pastbooking {
		background: $white;
		color: $dark-blue;
	}

	@include bp("medium") {
		width: 130px;
		height: 75px;
		clip-path: circle(75px at 75px 0px);
	}
}

.c-bookings__booking-top,
.c-bookings__booking-expander-container {
	display: block;

	@include bp("medium") {
		display: flex;
		flex-wrap: wrap;
	}

	.c-bookings__booking-divider {
		width: 100%;

		@include bp("medium") {
			width: 50%
		}
	}
}

.c-bookings__booking-region,
.c-bookings__booking-times-descriptor {
	@include font-base-12b;
	color: $blue--light;
	margin-bottom: $tsu;

	@include bp("medium") {
		@include font-base-16b;
		margin-bottom: $bsu;
		margin-right: $bsu;
	}
}

.c-bookings__booking-header,
.c-bookings__booking-times-times {
	@include font-base-16b;
	color: $white;
	margin-bottom: $ssu;
	margin-right: $hsu;

	@include bp("medium") {
		@include font-heading-24;
		margin-bottom: $bsu;
		margin-right: $bsu;
	}
}

.c-bookings__booking-reservation {
	display: flex;
	color: #fff;

	> div {
		padding-right: 10px;
	}
}

.c-bookings__booking-times {
	display: flex;

	.c-bookings__booking-times-checkinout {
		width: 50%;

		@include bp("medium") {
			width: 40%;	
		}
	}
}

.c-bookings__booking-expander {
	width: 100%;
	margin-top: $ssu;

	&[aria-expanded="true"] {

		.c-expand-collapse__body {
			display: block;
		}
	}

	&[aria-expanded="false"] {

		.c-expand-collapse__body {
			display: none;
		}
	}
}

.c-bookings__booking-expander-wrapper {
	display: flex;
	flex-wrap: wrap;

	.c-bookings__booking-sub-section {
		width: 100%;
		display: flex;

		@include bp("medium") {
			width: 50%;
		}

		> div {
			width: 100%;
			color: $white;

			@include bp("medium") {
				width: 50%;
			}
		}
	}
}

.c-bookings__booking-expander-button {
	position: absolute;
	bottom: -20px;
	right: 30px;
	height: 44px;
	width: 44px;
	padding: 14px;
	border-radius: 50%;
	background: $yellow;
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);

	&:hover {
		cursor: pointer;
	}

	span {
		font-size: 18px;
		color: $dark-blue;
	}

	&[aria-expanded="true"] {

		.c-bookings__booking-expander-open {
			display: none;
		}

		.c-bookings__booking-expander-close {
			display: block;
		}
	}

	&[aria-expanded="false"] {

		.c-bookings__booking-expander-open {
			display: block;
		}

		.c-bookings__booking-expander-close {
			display: none;
		}
	}
}

.c-bookings__change-booking {
	margin-top: $lsu;
	@include button("small");
	@include btn-primary;
}
