// CONTROL.TESTIMONIALS-ROTATOR

$testimonials-rotator-bp: "large";

.c-testimonials-rotator {
	@include control;

	.flickity-prev-next-button {
		top: calc(50% - 29px);
		@include circle(44px);
		@include button("small");
		@include btn-primary;
		padding: 0;

		&.previous {
			left: $ssu;

			@include bp("medium") {
				left: $bsu;
			}
		}

		&.next {
			right: $ssu;

			@include bp("medium") {
				right: $bsu;
			}
		}
	}

	.flickity-button-icon {
		fill: $secondary-a;
	}
}

.c-testimonials-rotator__layout {
	@include layout-wrapper;
}

.c-testimonials-rotator__heading {
	@include control__heading;
}

.c-testimonials-rotator__viewport {
	min-height: 560px;
	overflow: hidden;
	@include radius;
}

.c-testimonials-rotator__item {
	width: 100%;
	position: relative;
}

.c-testimonials-rotator__figure {
	@include figure;

	min-height: 560px;
}

.c-testimonials-rotator__image {
	min-height: 560px;
}

.c-testimonials-rotator__image--mobile {
	@include bp($testimonials-rotator-bp) {
		display: none;
	}
}

.c-testimonials-rotator__image--desktop {
	@include bp-below($testimonials-rotator-bp) {
		display: none;
	}
}

.c-testimonials-rotator__spacer {
	min-height: 560px;
	background: $grey--dark;
}

.c-testimonials-rotator__body {
	@include flex-extend;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($black, 0.5);
	color: $white;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.c-testimonials-rotator__body-content {
	@include flex-extend;
	//position: absolute;
	//top: 50%;
	//left: 50%;
	//transform: translate(-50%, -50%);
	text-align: center;
	width: calc(100% - 100px);
	max-width: 900px;
	padding: 0 $bsu;

	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
}

.c-testimonials-rotator__abstract {
	display: block;
	//margin-top: $lsu;
	@include font-base-16b;

	@include bp("medium") {
		@include font-base-18b;
	}

	@include bp("large") {
		@include font-heading-20;
	}

	> p:last-child {
		margin-bottom: 0;
	}
}

.c-testimonials-rotator__author {
	display: block;
	margin-top: $lsu;
	@include font-base-14;
}

.c-testimonials-rotator__btn {
	@include font-base-14;

	margin-top: $bsu;
	color: $yellow;
	position: absolute;
	bottom: 0;
	margin-bottom: $bsu;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}
