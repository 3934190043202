// CONTROL.IMAGE-GRID

@use "sass:math";

.c-image-grid {
	@include control;
}

.c-image-grid__layout {
	@include layout-wrapper;
}

.c-image-grid__heading {
	@include yellow-line-heading;
	color: $brand--alt;
}

.c-image-grid__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	margin-left: -$bsu;
	margin-top: -$bsu;

	@supports (display: grid) {
		display: grid;
		margin: 0;
		grid-gap: $bsu;

		.c-image-grid--large & {
			//@include grid-ram(400px);
			grid-template-columns: repeat(1, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(2, 1fr);
			}

			@include bp("large") {
				grid-template-columns: repeat(4, 1fr);
			}
		}
		// Medium is the default
		&, .c-image-grid--medium & {
			//@include grid-ram(300px);
			grid-template-columns: repeat(2, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(3, 1fr);
			}

			@include bp("large") {
				grid-template-columns: repeat(6, 1fr);
			}
		}

		.c-image-grid--small & {
			//@include grid-ram(230px);
			grid-template-columns: repeat(2, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(4, 1fr);
			}

			@include bp("large") {
				grid-template-columns: repeat(8, 1fr);
			}
		}
	}
}

.c-image-grid__item {
	display: flex;
	flex-direction: column;

	padding-left: $bsu;
	padding-top: $bsu;

	.c-image-grid--large & {
		width: percentage(math.div(1, 1));
		@include bp("medium") {
			width: percentage(math.div(1, 2));
		}
		@include bp("large") {
			width: percentage(math.div(1, 4));
		}
	}
	// Medium is the default
	&, .c-image-grid--medium & {
		width: percentage(math.div(1, 2));
		@include bp("medium") {
			width: percentage(math.div(1, 3));
		}
		@include bp("large") {
			width: percentage(math.div(1, 6));
		}
	}
	.c-image-grid--small & {
		width: percentage(math.div(1, 3));
		@include bp("medium") {
			width: percentage(math.div(1, 4));
		}
		@include bp("large") {
			width: percentage(math.div(1, 8));
		}
	}

	@supports (display: grid) {
		padding: 0;
		&,
		.c-image-grid--large &,
		.c-image-grid--medium &,
		.c-image-grid--small & {
			width: 100%;
		}
	}

	position: relative;
}

.c-image-grid__item-figure {
	flex-grow: 1;

	@include figure;
	overflow: hidden;
}
a.c-image-grid__item-figure {
	&:hover,
	&:focus-visible {
		.c-image-grid__item-image {
			.c-image-grid--cover & {
				transform: scale(1.1);
			}
			.c-image-grid--contain & {
				// Scale up less because these may
				// be logos that shouldn't get cut off
				transform: scale(1.05);
			}
		}
	}
}

.c-image-grid__item-image {
	width: 100%;
	height: 100%;
	background-color: transparent !important;

	.c-image-grid--cover & {
		&, .o-fit__image {
			object-fit: cover;
		}
	}
	.c-image-grid--contain & {
		&, .o-fit__image {
			object-fit: contain;
		}
	}

	@include link-transition(transform);
}

.c-image-grid__item-link {}
