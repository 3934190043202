// COMPONENT.FOOTER

@use "sass:math";

$footer-logo-width: 300px;

:root {
	--footer-logo-width: 300px;

	@include bp(1025px) {
		--footer-logo-width: 220px;
	}

	@include bp("extra-large") {
		--footer-logo-width: 300px;
	}
}

.c-footer {
	position: relative;
	background-color: $secondary-a;
	background-color: var(--secondary-a);
}

.c-footer__layout {
	@include layout-wrapper;

	.c-footer__layout-top & {
		@include flex-extend;
		flex-direction: column;
		justify-content: space-between;
		gap: $bsu;

		@include bp("medium") {
			flex-direction: row;
		}
	}
}

.c-footer__layout-top {
	padding: $lsu 0;
	background-color: $dark-blue--dark;
}

.c-footer__primary {
	padding: $bsu 0;
	@include flex-extend;
	flex-direction: column;

	@include bp(1025px) {
		flex-direction: row;
		padding: ($lsu * 1.5) 0 $bsu;
	}
}

.c-footer__secondary {
	padding: $ssu 0 $hsu;
	background-color: $secondary-a;
	background-color: var(--secondary-a);

	@include bp("medium") {
		padding: $bsu 0;
	}

	@include bp("extra-large") {
		position: relative;
		margin-top: $hsu;

		&::before,
		&::after {
			position: absolute;
			border-radius: 0 100% 0 0;
			content: "";
			bottom: 0;
			left: 0;
		}

		&::before {
			width: 122px;
			height: 120px;
			background-color: $yellow;
			z-index: 2;
		}

		&::after {
			width: 235px;
			height: 230px;
			background-color: $blue--light;
			z-index: 1;
		}
	}
}

.c-footer__secondary-layout {
	@include flex-extend;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include bp("medium") {
		border-top: 1px solid $dark-blue--light;
		padding-top: $bsu;
		flex-direction: row;
	}
}

.c-footer__logo {
	display: none;
	width: $footer-logo-width;
	width: var(--footer-logo-width);
	//padding: $lsu 0;
	margin: 0 auto;
	text-align: center;

	img {
		width: 150px;
	}

	@include bp("medium") {
		display: block;
		align-self: flex-start;
		margin: 0;
	}
}

.c-footer__signup {
	padding: $bsu 0;
}

.c-footer__logo-list {

	@include bp(1025px) {
		width: $footer-logo-width;
		width: var(--footer-logo-width);
		display: flex;
		flex-direction: column;
		align-self: flex-start;
		margin: 0;
		gap: $bsu;
	}
}

.c-footer__logo-tag {
	display: block;
	text-align: center;
	margin-bottom: ($bsu * 1.5);

	@include bp("medium") {
		text-align: left;
	}

	@include bp(1025px) {
		margin-bottom: 0;
	}
}

.c-footer__badges {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: $bsu;

	@include bp-below(1025px) {
		display: none;
	}
}

.c-footer__badges-item {
	width: 100%;
}

.c-footer__badges-item-link {
	width: 100%;
	display: block;
}

.c-footer__badges-img {
	width: 100%;
	display: block;

	img {
		width: 100%;
	}
}

.c-footer__newslatter-wrap {
	@include bp("medium") {
		width: 50%;
	}
}

.c-footer__newslatter {
	@include flex-extend;
	flex-direction: column;
	gap: $bsu;
	align-items: center;
	justify-content: center;

	@include bp("medium") {
		flex-direction: row;
		justify-content: flex-start;
	}
}

.c-footer__newslatter-heading {
	@include font-heading-20;
	margin-bottom: 0;
	text-align: center;

	@include bp("medium") {
		@include font-heading-24;
		text-align: left;
	}
}

.c-footer__newslatter-btn {
	@include button("small");
	@include btn-primary-outline;
	flex: 0 0 auto;
}


.c-footer__navigation-wrap {
	@include flex-extend;
	flex: 0 0 auto;
	width: 100%;
	margin-bottom: 0;
	justify-content: space-around;
	flex-direction: column;

	@include bp("medium") {
		flex-direction: row;
		gap: $bsu;
	}

	@include bp("large") {
		width: calc(100% - #{$footer-logo-width});
		width: calc(100% - var(--footer-logo-width));
		padding-left: $hsu;
	}
}

.c-footer__navigation {
	@include list-reset;
	border-bottom: 1px solid $dark-blue--light;

	@include bp("medium") {
		padding-left: $bsu;
		border-bottom: 0;
		border-left: 1px solid $dark-blue--light;
		flex: 1 0 auto;
	}
}


.c-footer__navigation-items {
	@include list-reset;
	@include flex-extend;
	gap: $ssu;
	flex-direction: column;
	margin-bottom: $bsu;


	@include bp-below("medium") {
		margin-bottom: 0;
		padding-bottom: $bsu;

		.c-footer__navigation[aria-expanded="true"] & {
			display: block;
		}

		.c-footer__navigation[aria-expanded="false"] & {
			display: none;
		}
	}

	&.c-footer__navigation-item--two-col {

		@include bp("medium") {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: $ssu;
		}
	}
}


.c-footer__navigation-section-heading {
	padding: $ssu;
	margin-bottom: 0;

	@include bp("medium") {
		padding: 0;
		margin-bottom: ($lsu + $ssu);
	}
}

.c-footer__navigation-mobile-heading {

	@include bp("medium"){
		display: none;
	}
}

.c-footer__navigation-section-heading,
.c-footer__navigation-section-link {
	@include font-base-18b;
	color: $white;

	&:focus,
	&:hover {
		text-decoration: none;
	}

	@include bp("medium") {
		@include font-base-16b;
		@include underline-animate;
	}
}

.c-footer__navigation-section-link {
	display: block;
	padding: $ssu $bsu $ssu ($bsu * 1.5);

	@include bp("medium") {
		@include font-base-16b;
		display: inline-block;
		padding: 0;
	}

	.c-footer-section--has-nav & {
		position: relative;


		&::before {
			@include font-icon;
			@include link-transition(transform);
			content: $iconf-plus;
			position: absolute;
			left: 5px;
			top: 13px;
			font-size: 20px;
			color: $blue--light;

			@include bp("medium") {
				display: none;
			}
		}
	}

	.c-footer__navigation[aria-expanded="true"] .c-footer-section--has-nav & {
		color: $yellow;

		&::before {
			@include link-transition(transform);
			content: $iconf-minus;
			transform: rotate(-180deg);
			color: $yellow;
		}
	}
}

.c-footer__navigation-link {
	color: $white;
	padding: $tsu $tsu $tsu $lsu;
	display: block;
	width: 100%;

	@include underline-animate;

	@include bp("medium") {
		@include font-base-16;
		display: inline-block;
		padding: 0;
	}
}

.c-footer__link-wrap {
	display: none;
	margin-bottom: $bsu;

	@include bp("medium") {
		display: block;
	}

	&:first-of-type {
		margin-top: $lsu;
	}
}

.c-footer__booking-link {
	display: inline-block;
	align-items: center;
	position: relative;
	padding-right: 30px;

	&::before {
		@include font-icon;
		content: $iconf-external;
		display: inline-block;
		margin-top: $tsu;
		margin-left: $bsu;
		position: absolute;
		right: 0;
	}
}

.c-footer__portal-login-link {
	@include button("small");
	@include btn-primary-outline;
	position: relative;
	padding-left: 50px;
}

.c-footer__portal-login-icon {
	position: absolute;
	left: 25px;
	top: 50%;
	font-size: 20px;
	margin-right: $tsu;
	transform: translateY(-50%);
}

.c-footer__social {

	@include bp("medium") {
		width: 50%;
		@include flex-extend;
		justify-content: center;
	}
}

.c-footer__social-heading {
	display: none;
	margin-bottom: $ssu;
	color: $white;
}

.c-footer__social-list {
	@include list-reset;
	@include flex-extend;
	flex-direction: row;
	flex-wrap: wrap;
	gap: $ssu;
	justify-content: center;

	@include bp("medium") {
		gap: $bsu;
	}
}

.c-footer__social-item {
	display: inline-block;
	//margin-right: $tsu;
	//margin-bottom: $tsu;
}

.c-footer__social-link {
	@include icon-wrapper;

	@include btn-social;
	@include link-transition;

	font-size: 20px;
}

.c-footer__legal {
	@include flex-extend;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: $bsu;

	@include bp("medium") {
		flex-direction: row;
		align-items: center;
		margin-left: auto;
	}
}

.c-footer__legal-copyright {
	@include font-base-12;
	line-height: 1;
	display: block;
	color: $white;

	@include bp("small") {
		display: block;
		margin: 0;
	}
}

.c-footer__legal-links {
	@include list-reset;
	margin-top: -#{$tsu};
}

.c-footer__legal-links-item {
	display: inline-block;
	padding: 0 $ssu;
	border-left: 1px solid $dark-blue--light;

	&:first-of-type {
		border-left: 0;
	}
}

.c-footer__legal-link {
	@include font-base-12;
	display: block;
	line-height: 1;
	color: $white;
	
	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__tb {
	@include font-base-12;
	display: block;
	text-align: center;
	color: $white;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__back-to-top-wrapper {
	@include layout-wrapper;
	text-align: right;
}

.c-footer__back-to-top {
	@include circle(45px);
	@include btn-primary;
	display: block;
	position: fixed;
	bottom: $hsu;
	right: $tsu;
	padding: $ssu 0;
	z-index: $z-back-to-top;
	font-size: 32px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear,visibility 0s linear 0.5s;
	border-radius: 50%;


	&:hover {
		background: $yellow;
		border: 1px solid $yellow;
	}

	&::before {
		@include font-icon;
		content: $iconf-arrow-up;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
		z-index: 1;
	}

	@include bp("large") {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		border-bottom: 0;
		height: 30px;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				@include circle(50px);
				position: fixed;
				bottom: $hsu;
				transform: translateY(-50%);
				border: none;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}