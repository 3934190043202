﻿// CONTROL.FAQ

.c-faq {
	@include control;
}

.c-faq__layout {
	@include layout-wrapper;
}

.c-faq__heading {
	@include yellow-line-heading;
	color: $brand--alt;
}

.c-faq__list {
	@include list-reset;
	@include list-stacked; 
}

.c-faq__item {
	border-bottom: 1px solid $base-ui-color;

	//margin-bottom: $tsu;
	

	&[aria-expanded="false"] {
		&:hover,
		&:focus {}
	}
}

.c-faq__item-header {
	@include link-transition;
	position: relative;
	padding: ($bsu + $tsu) $bsu;
	padding-left: $bsu * 1.5;
	cursor: pointer;
	color: $secondary-a;

	@include bp("medium") {
		padding-left: $bsu * 3;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:hover,
	&:focus {
		color: $primary-a;
	}
}

.c-faq__item-heading {
	@include font-base-16b;

	@include bp("medium") {
		@include font-heading-20;
	}

	.c-faq__item.is-expanded &,
	.c-faq__item[aria-expanded="true"] & {
		color: $secondary-a;

		&:hover {
			color: $primary-a;
		}
	}
}

.c-faq__item-icon {
	position: absolute;
	top: 50%;
	left: 0;
	font-size: 24px;
	transform: translateY(-50%);
	transition: transform 0.3s linear;
	color: $primary-a;

	@include bp("medium") {
		left: $bsu;
	}

	&::before {
		@include font-icon;
		content: $iconf-plus;
	}

	.c-faq__item.is-expanded &,
	.c-faq__item[aria-expanded="true"] & {
		&::before {
			transform: translateY(-50%) rotate(180deg);
			content: $iconf-minus;
		}
	}
}

.c-faq__item-body {
	padding: 0 $bsu $bsu;
	padding-left: $bsu * 1.5;

	@include bp("medium") {
		padding-left: $bsu * 3;
	}

	.js & {
		display: none;
	}

	.c-faq__item.is-expanded &,
	.c-faq__item[aria-expanded="true"] & {
		display: block;
		//margin-top: -#{$ssu};
	}
}

.c-faq__item-text {
	@include body-text;
}
