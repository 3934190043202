﻿// ROOM-DETAILS

.c-room-details__heading {
	@include font-heading-24;
	padding-top: $tsu;
	color: $brand--alt;

	@include bp('medium') {
		@include font-heading-32;
		padding-top: 0;
		margin-bottom: $ssu * 1.5;
	}

	@include bp('large') {
		@include font-heading-48;
		margin-bottom: 0;
	}
}

.c-room-details-header-wrap {
	display: flex;
	flex-direction: column;


	@include bp("medium") {
		padding-top: $bsu;
		flex-direction: column-reverse;
	}

	.c-page-header {
		margin: 0;
		padding-top: $bsu;

		@include bp("medium") {
			padding-top: 0;
			margin: 0;
		}

		@include bp("large") {
			margin: ($lsu * 1.5) 0 $lsu;
		}
	}
}

.c-page-header__back-link {
	@include font-base-14;

	@include bp('medium') {
		@include font-base-18;
	}
}

.c-room-details__info {
	@include control;
}

.c-room-details__layout {
	@include layout-wrapper;

	@include bp-below("medium") {
		border-bottom: 1px solid $grey;
		padding-bottom: $lsu;
	}
}

.c-room-details__info-top {
	@include flex-extend;
	flex-direction: column;
	gap: $bsu;
	align-items: flex-start;
	margin-bottom: $bsu + $tsu;

	@include bp("medium") {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: $lsu;
	}

	@include bp("large") {
		margin-bottom: ($lsu * 1.5);
	}
}

.c-room-details__categories-items {
	@include list-reset;
	@include flex-extend;
	flex-direction: row;
	flex-wrap: wrap;
	gap: $bsu;
}

.c-room-details__categories-item {
	@include font-base-16;
	@include flex-extend;
	flex-direction: column;
	color: $secondary-a;
	color: var(--secondary-a);
	align-items: center;
	justify-content: center;
}

.c-room-details__categories-item-icon {
	display: block;
	font-size: 20px;

	@include bp('medium') {
		font-size: 24px;
	}
}

.c-room-details__availability-btn {
	@include button("medium");
	@include btn-primary;
}

.c-room-details__info-bottom {
	@include body-text;
}

.c-room-details__availability {
	margin: 0 -20px;
	width: 100%;

	@include bp(410px) {
		width: auto;
	}

	@include bp("medium") {
		margin: 0;
	}
}

.c-room-details__availability-wrap {
	background: $white;

	@include bp("medium") {
		border: 1px solid #eee;
		box-shadow: 0px 0px 4px rgba($black, 0.08);
	}
}

.c-room-details__availability-steps {
	padding: $bsu $bsu;
	border-bottom: 1px solid $grey--light;

	@include bp("medium") {
		padding: ($bsu + $tsu) $lsu;
	}
}

.c-room-details__availability-filters {
	@include thin-scrollbar("dark");
	width: 100%;
	overflow: auto;
	white-space: nowrap;
}

.c-room-details__availability__body {
	padding: $bsu;
	text-align: center;

	@include bp("medium") {
		padding: $bsu 60px;
	}

	.c-form__fields-set {
		margin-bottom: 0;

		@include bp("medium") {
			margin-bottom: $bsu;
		}

		.c-form__field-group.c-form__field-group-two-col {
			margin-bottom: 20px;

			@include bp("medium") {
				margin-bottom: 0;
			}
		}

		.c-form__field {
			margin-bottom: 0;
		}
	}

	.c-form__field-group-two-col {
		flex-direction: row;
		align-items: center;
	}

	.c-form__label {
		text-align: left;

		small {
			@include font-base-14;
			display: block;
		}
	}

	.c-form input[type=number] {
		max-width: 60px;
		text-align: center;
	}
}

.c-room-details__availability--guest {

	.c-form__label {
		line-height: 1;
	}
}

.c-room-details__availability--promo {

	.c-form__field {
		margin-bottom: 0;
	}
}

.c-room-details__availability-legend {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 20px 0;
	/*	container-type: inline-size;*/

	> div {
		display: flex;
		align-items: center;
		flex: 50%;
		font-size: 12px;
		margin-bottom: 10px;

		@include bp("medium") {
			flex: auto;
			font-size: 14px;
			padding-right: 10px;
		}
		/*		@container (min-width: 650px) {
			flex: auto;
		}*/

		span {
			width: 20px;
			height: 20px;
			display: inline-block;
			margin-right: 10px;
		}

		&.c-room-details__availability-legend-available {
			span {
				background: $green--light;
			}
		}

		&.c-room-details__availability-legend-unavailable-checkin {
			span {
				background: $green--lightest;
			}
		}

		&.c-room-details__availability-legend-available-mlos {
			span {
				border: 1px solid $green--dark;
			}
		}

		&.c-room-details__availability-legend-unavailable {
			span {
				background: linear-gradient(to left top, $grey--lightest 47.75%, #efefef 49.5%, #efefef 50.5%, $grey--lightest 52.25%);
			}
		}

		&.c-room-details__availability-legend-available-checkout {
			span {
				background: linear-gradient(135deg, $green--light 50%, $grey--lightest 50%);
			}
		}
	}
}

.c-room-details__availability__body-content {
}

.c-room-details__features {
	@include control;
}

.c-room-details__features-heading {
	@include yellow-line-heading;
	color: $secondary-a;
	color: var(--secondary-a);
	margin-bottom: $lsu;

	@include bp("medium") {
		margin-bottom: ($lsu * 1.5);
	}
}

.c-room-details__features-list {
	@include ui-border("bottom");
	margin-bottom: $bsu;
	padding-bottom: $bsu;

	&:last-child {
		@include bp-below("medium") {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}

.c-room-details__features-item-heading {
	@include font-base-16b;
	@include flex-extend;
	flex-direction: row;
	gap: ($ssu * 1.5);
	margin-bottom: $bsu;

	@include bp("medium") {
		@include font-base-18b;
	}
}

.c-room-details__features-heading-icon {
	color: $primary-a;
	color: var(--primary-a);
	font-size: 25px;
}

.c-room-details__features-items {
	@include list-reset;
	@include flex-extend;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
}

.c-room-details__features-item {
	@include font-base-16;
	@include flex-extend;
	flex: 0 0 50%;
	flex-direction: row;
	gap: $ssu;
	color: $secondary-a;
	color: var(--secondary-a);
	margin-bottom: $ssu;
	align-items: flex-start;

	&:nth-child(n+5) {
		display: none;

		.c-room-details__features-list[aria-expanded ="true"] & {
			display: flex;
		}
	}
}

.c-room-details__features-item-icon {
	@include flex-extend;
	@include circle(20px);
	@include ui-border("all");
	justify-content: center;
	align-items: center;
	border-color: $primary-a;
	border-color: var(--primary-a);
	color: $primary-a;
	color: var(--primary-a);
	margin-top: 3px;
}

.c-room-details__features-toggle-wrap {
	display: block;
	margin-top: $bsu;
}

.c-room-details__features-toggle {
	@include button-reset;
	color: $primary-a;
	color: var(--primary-a);



	&[aria-expanded="true"] {
		.c-room-details__features-toggle-open {
			display: none;
		}

		.c-room-details__features-toggle-close {
			display: flex;
		}
	}
}

.c-room-details__features-toggle-open {
	display: flex;
	align-items: center;
	gap: $tsu;

	&::before {
		@include font-icon;
		content: $iconf-plus;
		font-size: 15px;
	}
}

.c-room-details__features-toggle-close {
	display: none;
	align-items: center;
	gap: $tsu;

	&::before {
		@include font-icon;
		content: $iconf-minus;
		margin-top: 3px;
	}
}

.sticky-sidebar {
	position: relative;
}

.loader {
	padding: 20px;
}

.loader span {
	display: block;
	width: 130px;
	height: 4px;
	border-radius: 30px;
	background-color: rgba(0,0,0,0.2);
	position: relative;
	margin: 0 auto;
}

.loader span::before {
	content: "";
	position: absolute;
	background: $blue;
	top: 0;
	left: 0;
	width: 0%;
	height: 100%;
	border-radius: 30px;
	animation: moving 1s ease-in-out infinite;
}

@keyframes moving {
	50% {
		width: 100%;
	}

	100% {
		width: 0;
		right: 0;
		left: unset;
	}
}
