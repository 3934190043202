﻿.c-park-details-gallery {
	position: relative;
	overflow: hidden;

	@include bp("medium") {
		margin: 0;
	}
}

.c-park-details-gallery__layout {
	//@include layout-wrapper;
}

.c-park-pod__region {
	position: absolute;
	left: 10px;
	bottom: 5px;
	width: 35px;
	height: 50px;
	z-index: 1;

	&:before {
		position: absolute;
		left: -30px;
		bottom: -30px;
		content: "";
		height: 90px;
		width: 90px;
		border-radius: 50%;
		background: $yellow;
		z-index: -1;
	}

	&:after {
		position: absolute;
		left: -50px;
		bottom: -50px;
		content: "";
		height: 130px;
		width: 130px;
		border-radius: 50%;
		z-index: -2;
	}

	&.Premium:after {
		background: $premium-opacity;
	}

	&.Classic:after {
		background: $classic-opacity;
	}

	&.Superior:after {
		background: $superior-opacity;
	}

	img {
		width: 100%;
	}
}

.c-park-details-gallery__wrapper {
	height: 250px;
	display: block;

	@include bp("medium") {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-template-rows: 1fr 1fr;
		column-gap: 10px;
		row-gap: 10px;
		height: 500px;
	}

	@include bp("large") {
		grid-template-columns: 2fr 1fr 1fr;
	}
}

.c-park-details-gallery__all {
	@include button("small");
	@include btn-primary;
	position: absolute;
	right: 10px;
	bottom: 10px;
	z-index: 2;
}

.c-park-details-gallery__item {
	display: none;
	overflow: hidden;
	align-content: center;

	&:first-child {
		display: block;
		//display: grid;
		//grid-column: 1 / 1;
		//grid-row: 1 / 3;
	}

	&:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
		display: none;
	}

	@include bp("medium") {
		&:first-child {
			display: grid;
			grid-column: 1 / 1;
			grid-row: 1 / 3;
		}

		&:nth-child(2) {
			display: grid;
			grid-column: 2 / 2;
			grid-row: 1 / 1;
		}

		&:nth-child(3) {
			display: grid;
			grid-column: 2 / 2;
			grid-row: 2 / 2;
		}

		&:nth-child(4) {
			display: none;
		}

		&:nth-child(5) {
			display: none;
		}
	}

	@include bp("large") {

		&:nth-child(2) {
			display: grid;
			grid-column: 2 / 2;
			grid-row: 1 / 1;
		}

		&:nth-child(3) {
			display: grid;
			grid-column: 3 / 3;
			grid-row: 1 / 1;
		}

		&:nth-child(4) {
			display: grid;
			grid-column: 2 / 2;
			grid-row: 2 / 2;
		}

		&:nth-child(5) {
			display: grid;
			grid-column: 3 / 3;
			grid-row: 2 / 2;
			position: relative;

			.c-park-details-gallery__link {
				&:before {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					background: rgba(0,0,0,0.4);
					z-index: 1;

					@include radius;
				}
			}
		}
	}
}

.c-park-details-gallery__link {
	display: block;
	width: 100%;
	height: 100%;

	&:hover {
		.c-park-details-gallery__image {
			transform: scale(1.1);
		}
	}
}

.c-park-details-gallery__image {
	display: block;
	width: 100%;
	position: relative;
	transition: transform 0.25s ease-in-out;

	@include bp('medium') {
		@include radius;
		height: 100%;
	}
}
