﻿.c-food-menu {
}

.c-food-menu__layout {
    @include layout-wrapper;
}

.c-food-menu__tab-list {
    @include list-reset;
    @include flex-extend;
    flex-wrap: wrap;
    justify-content: center;
}

.c-food-menu__tab-button {
    @include button-reset;
    padding: $bsu;

    @include bp("large") {
        padding: $bsu $lsu;
    }

    @include font-base-16b;
    color: $brand;
    position: relative;

    &::after {
        content: "";
        @include abs-center-h;
        bottom: 0;
        width: 0;
        height: 4px;
        opacity: 0;
        background: $black;

        @include link-transition;
    }

    &[aria-expanded="true"],
    &:hover,
    &:focus {
        color: $black;

        &:not(:focus-visible) {
            outline: none;
        }

        &::after {
            width: 40px;
            opacity: 1;
        }
    }
}

.c-food-menu__tab-body {
    &[aria-expanded="false"] {
        display: none;
    }

    &[aria-expanded="true"] {
        display: block;
    }
}

.c-food-menu__section {
    margin-top: $lsu;

    &:first-child {
        margin-top: 0;
    }
}

.c-food-menu__section-title {
    @include font-heading-24;
    color: $brand;
    margin-bottom: $bsu;
}

.c-food-menu__items {
    @include list-reset;
}

.c-food-menu__item {
    border-bottom: 2px dotted $grey--dark;
    @include flex-extend;
    justify-content: space-between;
    padding: $bsu 0;

    &:first-child {
        padding-top: 0;
    }
}

.c-food-menu__item-details {
    padding-right: $bsu;
}

.c-food-menu__item-name {
    display: block;
    @include font-base-16b;
    color: $brand;
}

.c-food-menu__item-description {
    display: block;
    @include font-base-16;
    color: $grey--dark;
}

.c-food-menu__item-prices {
    @include list-reset;
}

.c-food-menu__item-price {
    @include flex-extend;
    justify-content: space-between;
}

.c-food-menu__item-price-name {
    @include font-base-16;
    color: $grey--dark;
    padding-right: $bsu;
}

.c-food-menu__item-price-amount {
    @include font-base-16b;
}

.c-food-menu__notes {
    @include body-text;
    margin: $bsu 0;
}

.c-food-menu__heading {
    @include font-heading-48;
    color: $brand;
    margin-bottom: $bsu;
}

.c-food-menu__intro {
    @include font-base-18;
    margin-bottom: $ssu;
    color: $grey--dark;

    p {
        margin-bottom: 0;
    }

    & + .c-food-menu__section {
        margin-top: $bsu;
    }
}
